import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/ui';

import { InstalmentsModal } from '../../../../shared/components/ui/InstalmentsModal/InstalmentsModal';

import styles from './InstalmentsInfo.module.scss';

export const InstalmentsInfo = () => {
    const [openModal, setOpenModal] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <section className={styles.instalments}>
                <div dangerouslySetInnerHTML={{ __html: t('checkoutInstalmentsNotification') }} />
                <Button type="text" className={styles.link} onClick={() => setOpenModal(!openModal)}>
                    {t('checkoutInstalmentsLearnMore')}
                </Button>
            </section>
            <InstalmentsModal open={openModal} onClose={() => setOpenModal(false)} />
            {/*<Portal>*/}
            {/*    <Modal isOpen={openModal} onClose={() => setOpenModal(false)} maxWidth={444} className={styles.modal}>*/}
            {/*        <h3>{t('instalmentModalTitle')}</h3>*/}
            {/*        <p dangerouslySetInnerHTML={{ __html: t('instalmentModalText') }} />*/}
            {/*        <Button className={styles.modalButton} onClick={() => setOpenModal(false)}>*/}
            {/*            {t('instalmentsModalButton')}*/}
            {/*        </Button>*/}
            {/*    </Modal>*/}
            {/*</Portal>*/}
        </>
    );
};
