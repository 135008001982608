import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button } from 'shared/components/ui';
import { CheckoutType } from 'shared/types/checkout';

import styles from './FinanceSuccess.module.scss';

type FinanceSuccessProps = {
    data: CheckoutType;
    order: any;
};
export const FinanceSuccess: React.FC<FinanceSuccessProps> = ({ data, order }) => {
    const { configurationImage, email } = data;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goHome = () => {
        navigate(AppRoutes.home, { replace: true });
    };

    return (
        <div className={styles.financeSuccess}>
            {configurationImage && <img src={`${configurationImage}?h=200&trim=color&trim-tol=50`} alt="Bike" />}
            <h1>{t('thanksForTheOrder')}</h1>
            <p>
                {t('orderStatusGreatChoice')} <br />
                {t('youWillGetEmailConfirmation')} {email || 'your email address'}.
            </p>
            {order && order.number && (
                <div className={styles.reference}>
                    {t('yourOrderNumber')}
                    <div className={styles.orderNumber}>{order.number}</div>
                </div>
            )}
            <Button className={styles.button} onClick={() => goHome()}>
                {t('orderStatusGoToHomeButton')}
            </Button>
        </div>
    );
};
