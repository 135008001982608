import { setContext } from '@apollo/client/link/context';
import { LocalStorageKey } from 'shared/helpers/localStorage';

export const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(LocalStorageKey.ACCESS_TOKEN);

    return {
        headers: {
            ...headers,
            Authorization: token ? `JWT ${token}` : '',
        },
    };
});
