import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { useNavigationChangeInterceptor } from 'shared/hooks/useNavigationChangeInterceptor';
import CheckoutStore from 'stores/Checkout.store';

import { Portal, Sidebar } from '../../shared/components/ui';
import { areArraysDifferent } from '../../shared/providers/utils';
import { gtmEvents } from '../../shared/types/gtmEvents';
import ConfiguratorStore from '../../stores/Configurator.store';
import { EmailPopup } from '../BikeModel/_components/EmailPopup/EmailPopup';

import { Bike3D } from './_components/Bike3D/Bike3D';
import { BikeCarousel } from './_components/BikeCarousel/BikeCarousel';
import { ExitIntentModal } from './_components/ExitIntentModal/ExitIntentModal';
import { PreconfigurationModal } from './_components/PreconfigurationModal/PreconfigurationModal';
import styles from './Configurator.module.scss';

type ConfiguratorProps = {};

export const Configurator: FC<ConfiguratorProps> = observer(() => {
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const { listenForNavigationChange } = configuratorStore;
    const { sid } = useParams();
    const [searchParams] = useSearchParams();
    const bikeId = searchParams.get('bike');
    const configContinue = window.location.search.includes('continue');
    const emailFromlocal = localStorage.getItem(LocalStorageKey.EMAIL);

    const { t } = useTranslation();
    const [navigationChanged, confirmNavigation, cancelNavigation] =
        useNavigationChangeInterceptor(listenForNavigationChange);

    const [preconfigurationPopup, setPreconfigurationPopup] = useState(false);
    const [exitIntentPopup, setExitIntentPopup] = useState(false);
    const [emailPopup, setEmailPopup] = useState(false);

    useEffect(() => {
        if (!listenForNavigationChange) {
            configuratorStore.listenForNavigationChange = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Show warning prompt on tab close
        const warningPromptOnUnload = (e: Event) => {
            e.preventDefault();
            window.dataLayer.push({
                event: gtmEvents.BIKE_LEAVE_CONFIGURATOR_POPUP,
            });
            return '';
        };

        window.addEventListener('beforeunload', warningPromptOnUnload);

        return () => {
            window.removeEventListener('beforeunload', warningPromptOnUnload);
        };
    }, []);

    useEffect(() => {
        const mouseLeaveEvent = (event: any) => {
            if (!configuratorStore.exitIntent) return;
            if (
                event.clientY <= 0 ||
                event.clientX <= 0 ||
                event.clientX >= window.innerWidth ||
                event.clientY >= window.innerHeight
            ) {
                setExitIntentPopup(true);
                configuratorStore.exitIntent = false;

                window.dataLayer.push({
                    event: gtmEvents.EXIT_INTENT_POPUP,
                });

                document.removeEventListener('mouseleave', () => {});
            }
        };

        if (
            configuratorStore.exitIntent &&
            configuratorStore.loadingEnded &&
            !areArraysDifferent(configuratorStore.initialParts, configuratorStore.selectedParts)
        ) {
            document.addEventListener('mouseleave', e => mouseLeaveEvent(e));
        }

        return () => {
            document.removeEventListener('mouseleave', mouseLeaveEvent);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [configuratorStore.loadingEnded, JSON.stringify(configuratorStore.selectedParts)]);

    useEffect(() => {
        if (navigationChanged) {
            window.dataLayer.push({
                event: gtmEvents.BIKE_LEAVE_CONFIGURATOR_POPUP,
            });
            if (window.confirm(t('changesLost'))) {
                confirmNavigation();
                window.dataLayer.push({
                    event: gtmEvents.BIKE_LEAVE_CONFIGURATOR_CONFIRM,
                });
            } else {
                cancelNavigation();
                window.dataLayer.push({
                    event: gtmEvents.BIKE_LEAVE_CONFIGURATOR_CANCEL,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigationChanged]);

    useEffect(() => {
        configuratorStore.selectedProductTypeId = null;
        const shareableId = sid;

        if (shareableId) {
            configuratorStore.getProductBySid(shareableId).then(configuration => {
                // create local selected parts list and remove all upsell items by default
                // later add them if they exist in checkout
                let selectedPartsList = configuration.parts.filter((parts: any) => parts.group !== 'OTHER');

                // set upsell items if they exist in checkout
                const upsell = checkoutStore.data?.linesByVariantGroup?.find((item: any) => item.group === 'OTHER');
                if (upsell?.lines?.length !== 0) {
                    upsell?.lines.forEach(item => {
                        const exists = selectedPartsList.find((part: any) => part.variantId === item.variant.id);
                        if (!exists) {
                            selectedPartsList.push({
                                variantId: item.variant.id,
                                price: item.totalPrice.gross.amount,
                                group: 'OTHER',
                            });
                        }
                    });
                }
                // set upsell items in selected parts array
                return (configuratorStore.selectedParts = selectedPartsList);
            });
            checkoutStore.clearTokens();
        } else {
            configuratorStore.fetchProduct(bikeId as string);
            configuratorStore.getBikeConfigurationParts(bikeId as string);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        configuratorStore.getBikePreconfigurations(true, bikeId as string);

        if (configuratorStore.preconfigurationChanged) {
            setPreconfigurationPopup(true);
        }
    }, [configuratorStore, configuratorStore.preconfigurationChanged, bikeId]);

    useEffect(() => {
        if (!configuratorStore.isFirstLoad) {
            configuratorStore.getLoadingData();
        }
    }, [configuratorStore, configuratorStore.triggerSelect]);

    // Check if the bike is a twinner bike
    useEffect(() => {
        if (configuratorStore.selectedBike) {
            configuratorStore.isTwinner = configuratorStore.selectedBike.name.toLowerCase().includes('twinner');
        }
        return () => {
            configuratorStore.isTwinner = false;
        };
    }, [configuratorStore.selectedBike, configuratorStore]);

    // if (configuratorStore.configuratorPageLoading) {
    //     return (
    //         <div className={styles.loader}>
    //             <Loader />
    //             <h6>{t('pleaseWait')}</h6>
    //         </div>
    //     );
    // }

    // if (configuratorStore?.configuratorParts?.length === 0) {
    //     return (
    //         <div className={styles.something_went_wrong}>
    //             <h3>{t('configuratorError')}</h3>
    //         </div>
    //     );
    // }
    
    useEffect(() => {
        if (!checkoutStore.data?.email && !emailFromlocal && !configContinue) {
            setEmailPopup(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.configurator}>
            {/* <div id="config-backdrop"></div> */}
            <div className={styles.main_container}>
                <div className={styles.wrap3d_container}>
                    <div className={styles.weight_container}>
                        <h3 className={styles.bike_name}>{configuratorStore.selectedBike?.name}</h3>
                        {!configuratorStore.isTwinner && (
                            <div className={styles.weight_wrapper}>
                                <h6 className={styles.weight_text}>{t('estWeight')}</h6>
                                <h6>{configuratorStore.totalWeight} kg</h6>
                            </div>
                        )}
                    </div>
                    <div className={clsx(styles.configuratorView, styles.image)}>
                        <Bike3D modelData={configuratorStore.loadingData} />
                    </div>
                </div>
                <BikeCarousel
                    data={configuratorStore.configuratorParts}
                    bikeCategories={configuratorStore.configuratorCategories}
                />
            </div>
            <Sidebar type="configurator" />
            <PreconfigurationModal onClose={() => setPreconfigurationPopup(false)} isOpen={preconfigurationPopup} />
            <Portal>
                <ExitIntentModal isOpen={exitIntentPopup} onClose={() => setExitIntentPopup(false)} />
            </Portal>
            {emailPopup && <EmailPopup isOpen={emailPopup} onClose={() => setEmailPopup(false)} />}
        </div>
    );
});
