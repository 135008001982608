import clsx from 'clsx';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './Accordion.module.scss';

interface Props {
    expanded: boolean;
    titleComponent: React.ReactNode;
    contentComponent: React.ReactNode;
    className?: string;
}

export const Accordion: React.FC<Props> = ({ expanded, titleComponent, contentComponent, className }) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const [scrollHeight, setScrollHeight] = useState<number | 'auto'>();
    const [isOverflowVisible, setIsOverflowVisible] = useState(expanded);

    useEffect(() => {
        setScrollHeight(contentRef.current?.scrollHeight);
    }, []);

    useLayoutEffect(() => {
        if (expanded) {
            setScrollHeight(contentRef.current?.scrollHeight);
            setTimeout(() => {
                // This is required for accordion content height changes
                setScrollHeight('auto');
                // This is required for dropdowns that overflow outside of accordion content area
                setIsOverflowVisible(true);
                // 300 is the duration of the expand and collapse transition
            }, 300);
        } else {
            // To have smooth animation we need to convert height from auto to specific value
            setScrollHeight(contentRef.current?.scrollHeight);
            setTimeout(() => {
                setScrollHeight(0);
                setIsOverflowVisible(false);
            }, 1);
        }
    }, [expanded]);

    return (
        <div className={clsx(styles.accordion, expanded && styles.expanded, className)}>
            {titleComponent}
            <div
                ref={contentRef}
                className={styles.content}
                style={{
                    height: scrollHeight ?? 0,
                    overflow: isOverflowVisible ? 'visible' : 'hidden',
                }}
            >
                {contentComponent}
            </div>
        </div>
    );
};
