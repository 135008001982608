import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Input, Tooltip } from 'shared/components/ui';
import CheckoutStore from 'stores/Checkout.store';

import styles from './Coupon.module.scss';

export const Coupon = observer(() => {
    const checkoutStore = useContext(CheckoutStore);

    const voucherCode = checkoutStore.data?.voucherCode ?? '';

    const { t } = useTranslation();

    const [showCouponCodeInput, setShowCouponCodeInput] = useState(false);
    const [couponInput, setCouponInput] = useState('');

    const submitPromoCode = () => {
        try {
            checkoutStore.addPromoCode(couponInput);
        } catch (error) {
            console.error(error);
        }
    };

    const removePromoCode = () => {
        try {
            checkoutStore.removePromoCode(voucherCode);
            setCouponInput('');
            setShowCouponCodeInput(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            {voucherCode ? (
                <div className={styles.coupon} onClick={removePromoCode}>
                    {t('removeCouponButton')}
                </div>
            ) : (
                <>
                    <div className={styles.coupon}>
                        {showCouponCodeInput ? (
                            <span onClick={() => setShowCouponCodeInput(false)}>{t('cancel')}</span>
                        ) : (
                            <>
                                <Tooltip
                                    label={t('addCouponTooltip')}
                                    tooltipWrapperClassName={styles.tooltipWrapper}
                                    tooltipClassName={styles.tooltip}
                                >
                                    <Icon name="question" />
                                </Tooltip>
                                <span onClick={() => setShowCouponCodeInput(true)}>{t('addCouponButton')}</span>
                            </>
                        )}
                    </div>

                    {showCouponCodeInput && (
                        <Input
                            className={styles.couponInput}
                            value={couponInput}
                            onChange={e => setCouponInput(e.target.value.toUpperCase())}
                            placeholder={t('addCouponCode')}
                            suffix={
                                couponInput && (
                                    <span className={styles.submit} onClick={submitPromoCode}>
                                        {t('applyCouponButton')}
                                    </span>
                                )
                            }
                        />
                    )}
                </>
            )}
        </div>
    );
});
