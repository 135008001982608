import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';
import { Portal } from '../Portal/Portal';

import styles from './InstalmentsModal.module.scss';

type InstalmentsModalProps = {
    open: boolean;
    onClose: () => void;
};

export const InstalmentsModal: FC<InstalmentsModalProps> = ({ open, onClose }) => {
    const { t } = useTranslation();

    return (
        <Portal>
            <Modal isOpen={open} onClose={() => onClose()} maxWidth={444} className={styles.modal}>
                <h3>{t('instalmentModalTitle')}</h3>
                <p dangerouslySetInnerHTML={{ __html: t('instalmentModalText') }} />
                <Button className={styles.modalButton} onClick={() => onClose()}>
                    {t('instalmentsModalButton')}
                </Button>
            </Modal>
        </Portal>
    );
};
