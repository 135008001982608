import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button } from 'shared/components/ui';

import styles from './NotFound.module.scss';

export const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <section className={styles.notFound}>
            <div className={styles.textWrapper}>
                <h1>{t('notFoundTitle')}</h1>
                <Button className={styles.button} postIcon="arrow_right" onClick={() => navigate(AppRoutes.home)}>
                    {t('notFoundButton')}
                </Button>
            </div>
        </section>
    );
};
