import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppRoutes } from '../../../../Routes';
import CheckoutStore from '../../../../stores/Checkout.store';
import { LocalStorageKey } from '../../../helpers/localStorage';
import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';

import styles from './ContinueConfigModal.module.scss';

type ContinueConfigModalProps = {};

export const ContinueConfigModal: FC<ContinueConfigModalProps> = observer(() => {
    const [openModal, setOpenModal] = useState(false);
    const [sid, setSid] = useState('');

    const { t } = useTranslation();

    const checkoutID = localStorage.getItem(LocalStorageKey.CHECKOUT_ID);
    const checkoutStore = useContext(CheckoutStore);

    const isHome = window.location.pathname === '/';
    const isBikeModelPage = window.location.pathname.includes(AppRoutes.bikeModel);
    // const isConfigurationPage = window.location.pathname.includes(AppRoutes.configurator);

    const configContinue = window.location.search.includes('continue');

    const showContinueConfigModal = isHome || isBikeModelPage;

    useEffect(() => {
        if (checkoutID && !sid) {
            async function getCheckout() {
                if (!checkoutID) return;

                await checkoutStore.getCheckout(checkoutID);
                const SID = checkoutStore.data?.metadata.find(d => d.key === 'sid')?.value;
                if (SID) setSid(SID);
            }

            getCheckout();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (
            checkoutID &&
            checkoutStore.data?.lines?.length !== 0 &&
            sid &&
            !configContinue &&
            showContinueConfigModal
        ) {
            setOpenModal(true);
        }
    }, [checkoutStore.data, sid]); // eslint-disable-line

    const handleContinue = () => {
        window.location.href = `${AppRoutes.configurator}/${sid}?continue=true`;
    };

    const handleNewConfig = () => {
        checkoutStore.resetCheckoutStore();
        localStorage.removeItem(LocalStorageKey.CHECKOUT_ID);
        setSid('');
        setOpenModal(false);
    };

    return (
        <Modal isOpen={openModal} onClose={handleNewConfig} maxWidth={444} className={styles.continue_config_modal}>
            <div className={styles.title_wrapper}>
                <h3>{t('continueConfigurationModal.title')}</h3>
                <h6>{t('continueConfigurationModal.subtitle')}</h6>
            </div>

            <Button type="primary" onClick={handleContinue}>
                {t('continueConfigurationModal.buttonContinue')}
            </Button>
            <Button type="ghost" className={styles.ghost_button} onClick={handleNewConfig}>
                {t('continueConfigurationModal.buttonNewConfig')}
            </Button>
        </Modal>
    );
});
