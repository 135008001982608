import { observer } from 'mobx-react-lite';
import { CheckoutMetadataKey } from 'pages/Configurator/hooks/useUpdateCheckoutMetadata';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CheckoutStore from 'stores/Checkout.store';

import styles from './MetadataSection.module.scss';

type MetadataSectionProps = {};

export const MetadataSection: FC<MetadataSectionProps> = observer(() => {
    const checkoutStore = useContext(CheckoutStore);

    const colorKeys = ['frameColor1', 'frameColor2', 'barColor', 'color'];
    const metadataKeys = [...colorKeys, 'sittingPosition', 'size', 'colorType'];

    const { t } = useTranslation();

    const mapMetadataKeys = (key: string) => {
        switch (key) {
            case CheckoutMetadataKey.FRAME_COLOR_1:
                return t('orderSummaryMetadataFrameColor1');
            case CheckoutMetadataKey.FRAME_COLOR_2:
                return t('orderSummaryMetadataFrameColor2');
            case CheckoutMetadataKey.BAR_COLOR:
                return t('orderSummaryMetadataBarColor');
            case CheckoutMetadataKey.COLOR:
                return t('orderSummaryMetadataColor');
            case CheckoutMetadataKey.SITTING_POSITION:
                return t('orderSummaryMetadataSittingPosition');
            case CheckoutMetadataKey.SIZE:
                return t('orderSummaryMetadataSize');
            case CheckoutMetadataKey.COLOR_TYPE:
                return t('orderSummaryMetadataColorType');
            default:
                return '';
        }
    };

    return (
        <div className={styles.metadata_section}>
            {checkoutStore.data?.metadata.map((data: any) => {
                if (!metadataKeys.includes(data.key)) return null;

                return (
                    <div key={data.key} className={styles.metadata_line}>
                        <h6 className={styles.key}>{mapMetadataKeys(data.key)}</h6>
                        <h6 className={styles.value}>
                            {colorKeys.includes(data.key) ? (
                                <div className={styles.color_wrapper}>
                                    <div
                                        className={styles.color}
                                        style={{
                                            backgroundColor: `${
                                                JSON.parse(data.value)?.hex || JSON.parse(data.value)?.color
                                            }`,
                                        }}
                                    />
                                    <span>{JSON.parse(data.value)?.name || JSON.parse(data.value)?.colorName}</span>
                                </div>
                            ) : (
                                data.value
                            )}
                        </h6>
                    </div>
                );
            })}
        </div>
    );
});
