import clsx from 'clsx';
import React from 'react';
import ReactSelect, { GroupBase, Props } from 'react-select';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { Icon } from '../Icon/Icon';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './Select.module.scss';

type SelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> = Props<
    Option,
    IsMulti,
    Group
> & {
    label?: string;
    tooltipText?: string;
    labelWrapperClassName?: string;
    error?: string | boolean;
};

export const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: SelectProps<Option, IsMulti, Group>,
) => {
    const { label, tooltipText, labelWrapperClassName, error, ...rest } = props;

    return (
        <div className={styles.root}>
            {label && (
                <div className={clsx(styles.labelWrapper, labelWrapperClassName)}>
                    <label className={styles.label}>{label}</label>
                    {tooltipText && (
                        <Tooltip label={tooltipText}>
                            <Icon name="question" />
                        </Tooltip>
                    )}
                </div>
            )}
            <ReactSelect
                classNamePrefix="react-select"
                className={clsx(styles.select, error && styles.error)}
                components={{
                    IndicatorSeparator: () => null,
                }}
                isSearchable
                {...rest}
            />
            {typeof error === 'string' && error && <ErrorMessage message={error} className={styles.errorMessage} />}
        </div>
    );
};
