import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import CheckoutStore, { CheckoutStep } from 'stores/Checkout.store';

import 'react-phone-input-2/lib/style.css';

import { GuestCard } from './_components/GuestCard/GuestCard';

export const ContactDetails: React.FC = observer(() => {
    const checkoutStore = useContext(CheckoutStore);
    // const { t } = useTranslation();
    // const checkoutRoute = AppRoutes.checkout.replace(':id', checkoutStore.data?.id ?? '');
    // const loggedCheckout = process.env.REACT_APP_LOGIN_CHECKOUT === 'true';

    // useEffect(() => {
    //     if (checkoutStore.data?.email && checkoutStore.data?.user === null) {
    //         setSelectedTab(1);
    //     }
    //     if (!loggedCheckout) setSelectedTab(1);
    // }, [checkoutStore.data?.email, checkoutStore.data?.user, loggedCheckout]);

    // const goToAuth = (authSuccessRedirectUrl: string) => {
    //     const redirectUrl = process.env.REACT_APP_PUBLIC_URL + AppRoutes.authSuccess;
    //     localStorage.setItem(LocalStorageKey.AUTH_SUCCESS_REDIRECT_URL, authSuccessRedirectUrl);
    //     const authUrl = process.env.REACT_APP_AUTH_URL?.replace('{redirectUrl}', redirectUrl) || '';

    //     window.location.href = authUrl;
    // };

    const nextStep = async () => {
        await checkoutStore.updateConfiguration();

        window.dataLayer.push({
            event: 'Checkout section',
            from: 'Contact details',
            to: 'Shipping details',
            label: checkoutStore.productData?.name,
        });
        await checkoutStore.sendDataToCustomerIo('Checkout section', 'Shipping details');

        checkoutStore.checkoutStepProgress = CheckoutStep.SHIPPING;
        checkoutStore.expandedStep = CheckoutStep.SHIPPING;
    };

    const proceedAsGuest = async (data: any) => {
        if (data.phone) data.phone = data.phone?.includes('+') ? data.phone : `+${data.phone}`;
        if (checkoutStore.data?.user !== null) {
            await checkoutStore.disconnectUserFromCheckout();
            checkoutStore.clearTokens();
        }
        await checkoutStore.updateMail(data.email);
        if (data.phone) {
            await checkoutStore.updatePhone(data.phone);
        }
        if (data.phone && checkoutStore.phoneValidationFailed) return;
        nextStep();
    };

    return (
        <div>
            {/* <h6 className={styles.info}>{t('toProceedLogin')}</h6> */}

            {/* <div className={styles.tabs}>
                {loggedCheckout && (
                    <TabItem
                        title={t('continueAsUser')}
                        onSelect={() => setSelectedTab(0)}
                        selected={selectedTab === 0}
                    />
                )}
                <TabItem title={t('continueAsGuest')} onSelect={() => setSelectedTab(1)} selected={selectedTab === 1} />
            </div> */}
            {/* {selectedTab === 0 && (
                <UserCard
                    data={checkoutStore.data}
                    onAuthenticate={() => goToAuth(checkoutRoute)}
                    onNextStep={nextStep}
                />
            )} */}
            {/* {selectedTab === 1 && ( */}
            {/*<div className={styles.subtitle}>{t('checkout_contact_subtitle')}</div>*/}
            {/*<div className={styles.progress}>*/}
            {/*    <CircleIcon icon="check_circle" text={t('checkoutProgress.step1')} completed />*/}
            {/*    <CircleIcon icon="send" text={t('checkoutProgress.step2')} completed current />*/}
            {/*    <CircleIcon icon="share" text={t('checkoutProgress.step3')} />*/}
            {/*    <CircleIcon icon="gift" text={t('checkoutProgress.step4')} />*/}
            {/*</div>*/}
            <GuestCard data={checkoutStore.data} onNextStep={data => proceedAsGuest(data)} />
            {/* )} */}
        </div>
    );
});

// type CircleIconProps = {
//     icon: IconsId;
//     text: string;
//     completed?: boolean;
//     current?: boolean;
// };
// const CircleIcon: React.FC<CircleIconProps> = ({ icon, text, completed = false, current = false }) => {
//     return (
//         <div className={styles.iconWrapper}>
//             <div className={clsx(styles.circleIcon, completed && styles.completed)}>
//                 <Icon name={icon} />
//             </div>
//             {current && <div className={styles.current} />}
//             <div dangerouslySetInnerHTML={{ __html: text }} />
//         </div>
//     );
// };
