import { CHECKOUT_EXPERT_PIN_VERIFY } from 'api/mutations/checkout';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal } from 'shared/components/ui';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { apolloClient } from 'shared/lib/apollo';
import CheckoutStore, { CheckoutMode, CheckoutStep } from 'stores/Checkout.store';

import styles from './CheckoutModeSwitch.module.scss';

export const CheckoutModeSwitch = observer(() => {
    const checkoutStore = useContext(CheckoutStore);

    const { mode } = checkoutStore;

    // const expertCheckoutPaymentGateway = checkoutStore.data?.availablePaymentGateways?.find(
    //     gateway => gateway.id === 'mirumee.payments.dummy',
    // );
    const stripePaymentGateway = checkoutStore.data?.availablePaymentGateways?.find(
        gateway => gateway.id === 'saleor.payments.stripe',
    );

    const [showExpertCheckoutPasswordModal, setShowExpertCheckoutPasswordModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const { register, handleSubmit, reset } = useForm();

    const onSubmit = (value: any) => {
        switchToExpertCheckout(value.password);
    };

    const switchToExpertCheckout = async (password: any) => {
        try {
            setIsLoading(true);
            const { data } = await apolloClient.mutate({
                mutation: CHECKOUT_EXPERT_PIN_VERIFY,
                variables: { checkout: checkoutStore.data?.id, pin: password },
            });
            const token = data.checkoutExpertPinVerify.token;
            if (!token) return;
            localStorage.setItem(LocalStorageKey.EXPERT_CHECKOUT_TOKEN, token);

            // if (expertCheckoutPaymentGateway) {
            //     await checkoutStore.createPayment(expertCheckoutPaymentGateway.id);
            // }
            if (checkoutStore.checkoutStepProgress === CheckoutStep.PAYMENT) {
                checkoutStore.checkoutStepProgress = CheckoutStep.EXPERT_CHECKOUT;
                if (checkoutStore.expandedStep === CheckoutStep.PAYMENT) {
                    checkoutStore.expandedStep = CheckoutStep.EXPERT_CHECKOUT;
                }
            }
            checkoutStore.mode = CheckoutMode.EXPERT;
            setShowExpertCheckoutPasswordModal(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            reset();
        }
    };

    const switchToBasicCheckout = async () => {
        try {
            if (stripePaymentGateway) {
                // First createPayment needs to be executed then checkoutComplete (Saleor docs)
                await checkoutStore.createPayment(stripePaymentGateway.id);
                const stripeData: any = await checkoutStore.checkoutComplete();

                // get client_secret from checkoutComplete method and set it to store
                if (stripeData.confirmationData) {
                    const parsed = JSON.parse(stripeData.confirmationData);
                    checkoutStore.clientSecret = parsed.client_secret;
                }
            }

            if (checkoutStore.checkoutStepProgress === CheckoutStep.EXPERT_CHECKOUT) {
                checkoutStore.checkoutStepProgress = CheckoutStep.PAYMENT;
                if (checkoutStore.expandedStep === CheckoutStep.EXPERT_CHECKOUT) {
                    checkoutStore.expandedStep = CheckoutStep.PAYMENT;
                }
            }
            localStorage.removeItem(LocalStorageKey.EXPERT_CHECKOUT_TOKEN);
            checkoutStore.mode = CheckoutMode.BASIC;
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className={styles.root}>
            {mode === CheckoutMode.BASIC && (
                <span onClick={() => setShowExpertCheckoutPasswordModal(true)}>{t('expertCheckoutButton')}</span>
            )}

            {mode === CheckoutMode.EXPERT && <span onClick={switchToBasicCheckout}>{t('basicCheckoutButton')}</span>}

            <Modal
                isOpen={showExpertCheckoutPasswordModal}
                onClose={() => setShowExpertCheckoutPasswordModal(false)}
                maxWidth={450}
            >
                <h3>{t('expertCheckoutModalTitle')}</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        {...register('password')}
                        className={styles.expertCheckoutModalInput}
                        placeholder={t('enter_password')}
                        type="password"
                        autoFocus
                    />
                    <div className={styles.buttonsWrapper}>
                        <Button
                            htmlType="button"
                            type="ghost"
                            onClick={() => setShowExpertCheckoutPasswordModal(false)}
                        >
                            {t('cancel')}
                        </Button>
                        <Button htmlType="submit" isLoading={isLoading}>
                            {t('ok')}
                        </Button>
                    </div>
                </form>
            </Modal>
        </div>
    );
});
