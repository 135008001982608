import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/ui';

import styles from './GetAQuote.module.scss';

type GetAQuoteProps = {
    openModal: () => void;
    openMoreModal: () => void;
};
export const GetAQuote: React.FC<GetAQuoteProps> = ({ openModal, openMoreModal }) => {
    const { t } = useTranslation();

    const handleClick = (e: any) => {
        const el = e.target.closest('A');
        if (el && e.currentTarget.contains(el)) openMoreModal();
    };
    return (
        <div className={styles.getAQuote}>
            <div onClick={handleClick} dangerouslySetInnerHTML={{ __html: t('getAQuoteText') }} />
            <Button onClick={() => openModal()}>{t('getAQuoteButton')}</Button>
        </div>
    );
};
