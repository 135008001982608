import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation } from 'react-router';
import { AppRoutes } from 'Routes';

import { CheckoutHeader } from './_components/CheckoutHeader/CheckoutHeader';
import { DefaultHeader } from './_components/DefaultHeader/DefaultHeader';

export const Header = observer(() => {
    const location = useLocation();
    const isCheckout = location.pathname.includes('checkout');
    const isAuthSuccess = location.pathname.includes(AppRoutes.authSuccess);
    const isPaymentStatus = location.pathname.includes('/payment-status');
    const isOrderStatus = location.pathname.includes('/order-status');
    const isServiceUpgrade = location.pathname.includes(AppRoutes.serviceUpgrade);

    const isCheckoutHeader = isAuthSuccess || isPaymentStatus || isOrderStatus;

    if (isCheckout || isServiceUpgrade) return null;

    if (isCheckoutHeader) return <CheckoutHeader />;

    return <DefaultHeader />;
});
