import { useCategoryQuery } from 'api/queries/home';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { Button } from 'shared/components/ui';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { DeviceDetectorContext } from 'shared/providers/DeviceDetector';
import ConfiguratorStore from 'stores/Configurator.store';

import { Loader } from '../../shared/components/ui/Loader/Loader';
import { gtmEvents } from '../../shared/types/gtmEvents';
import CheckoutStore from '../../stores/Checkout.store';

import styles from './Home.module.scss';

export const Home = observer(() => {
    const store = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const navigate = useNavigate();
    const { data, loading } = useCategoryQuery();
    const { t } = useTranslation();
    const [activeCollection, setActiveCollection] = useState<any>(null);
    const [selectedCategory, setSelectedCategory] = useState<any>(null);
    const { device } = useContext(DeviceDetectorContext);
    const isMobile = device === 'small-tablet' || device === 'mobile' || device === 'tablet';

    useEffect(() => {
        if (store.selectedBike) {
            store.resetStore();
            checkoutStore.resetCheckoutStore();
            localStorage.removeItem(LocalStorageKey.BIKE_SID); // remove the SID if the user goes manually to homepage
        }

        // document.addEventListener('mouseleave', function (event) {
        //     if (
        //         event.clientY <= 0 ||
        //         event.clientX <= 0 ||
        //         event.clientX >= window.innerWidth ||
        //         event.clientY >= window.innerHeight
        //     ) {
        //         console.log("I'm out");
        //     }
        // });
        checkoutStore.clearTokens();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data && data.collections?.edges) {
            const cat = data.collections?.edges[0]?.node;
            if (cat) setSelectedCategory(cat);
            const firstImage = data?.collections?.edges?.findIndex(c => c.node.backgroundImage);
            if (firstImage > -1) setActiveCollection(firstImage);
        }
    }, [data]);

    const chooseCategory = (category: string, id: string) => {
        if (!category) return;
        store.setBike(category);
        store.setBikeCategoryId(id);
        localStorage.setItem(LocalStorageKey.CATEGORY_ID, id);

        window.dataLayer.push({
            event: gtmEvents.BIKE_CATEGORY,
            bikeCategory: category,
        });

        navigate(`${AppRoutes.bikeModel}/${id}`);
    };

    const selectCategory = (item: any) => {
        if (selectedCategory?.id === item.id) {
            setSelectedCategory(null);
        } else {
            setSelectedCategory(item);
        }
    };

    const setDefaultImage = () => {
        const firstImage = data?.collections?.edges?.findIndex(c => c.node.backgroundImage);
        setActiveCollection(firstImage);
    };

    if (isMobile) {
        return (
            <div className={styles.mobile_home}>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        {selectedCategory?.backgroundImage && (
                            <div
                                className={styles.bgImage}
                                style={{
                                    backgroundImage: `url(${selectedCategory?.backgroundImage?.url}?w=${
                                        window.innerWidth * 2
                                    })`,
                                }}
                            />
                        )}
                        <div className={styles.mobile_categories}>
                            <h4 className={styles.title}>{t('choseBikeCategoryTitle')}</h4>
                            <div className={styles.categories}>
                                {data?.collections?.edges?.map(item => {
                                    return (
                                        <div
                                            key={item.node.id}
                                            className={clsx(
                                                styles.category_cell,
                                                item.node.id === selectedCategory?.id && styles.selected,
                                            )}
                                            onClick={() => selectCategory(item.node)}
                                        >
                                            {item.node.name}
                                        </div>
                                    );
                                })}
                            </div>
                            <Button
                                isDisabled={!selectedCategory}
                                onClick={() => chooseCategory(selectedCategory?.name, selectedCategory?.id)}
                            >
                                {t('selectBikeCategoryButton')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        );
    }

    return (
        <div className={styles.home}>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <h1 className={clsx(styles.title, activeCollection !== null && styles.whiteText)}>
                        {t('choseBikeCategoryTitle')}
                    </h1>
                    <div className={styles.bike_categories}>
                        {data?.collections?.edges?.map((item, index) => {
                            return (
                                <React.Fragment key={item.node.id}>
                                    <div
                                        className={styles.category}
                                        role="button"
                                        onClick={() => chooseCategory(item.node.name, item.node.id)}
                                        onMouseEnter={() => setActiveCollection(index)}
                                        onMouseLeave={() => setDefaultImage()}
                                    >
                                        <p>{item.node.name}</p>
                                    </div>
                                    {item.node.backgroundImage && (
                                        <>
                                            <div
                                                className={styles.coverImage}
                                                style={{
                                                    backgroundImage: `url(${item.node.backgroundImage.url}?w=${window.innerWidth})`,
                                                    opacity: activeCollection === index ? 1 : 0,
                                                }}
                                            />
                                            {activeCollection !== null && <div className={styles.backdrop}></div>}
                                        </>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
});
