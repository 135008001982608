import { Elements } from '@stripe/react-stripe-js';
import { Stripe, StripeElementLocale, loadStripe } from '@stripe/stripe-js';
import { observer } from 'mobx-react-lite';
import { StripeForm } from 'pages/Checkout/_components/StripeForm/StripeForm';
import React, { useContext, useState, useEffect } from 'react';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import CheckoutStore from 'stores/Checkout.store';

import styles from './StripePayment.module.scss';

export const StripePayment = observer(() => {
    const [stripePromise, setStripePromise] = useState<Stripe | PromiseLike<Stripe | null> | null>(null);
    const checkoutStore = useContext(CheckoutStore);
    const { clientSecret } = checkoutStore;
    const language: StripeElementLocale =
        (localStorage.getItem(LocalStorageKey.LANGUAGE)?.toLocaleLowerCase() as any) || 'auto';

    useEffect(() => {
        if (checkoutStore.data?.availablePaymentGateways?.length !== 0) {
            const gateways = checkoutStore.data?.availablePaymentGateways?.map((item: any) => item);
            const stripe = gateways?.find((item: any) => item.name === 'Stripe');
            setStripePromise(loadStripe(stripe?.config[0]?.value));
        }
    }, [checkoutStore.data?.availablePaymentGateways]);

    // only render component if clientSecret exists
    // because Elements component will break without it
    return (
        <>
            {clientSecret && (
                <div className={styles.payment}>
                    <Elements
                        stripe={stripePromise}
                        options={{
                            clientSecret: clientSecret,
                            locale: language,
                            appearance: {
                                variables: {
                                    fontFamily: 'Mulish, sans-serif',
                                },
                            },
                        }}
                    >
                        <StripeForm />
                    </Elements>
                </div>
            )}
        </>
    );
});
