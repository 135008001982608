import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DeviceDetectorContext } from 'shared/providers/DeviceDetector';
import ConfiguratorStore from 'stores/Configurator.store';

import CheckoutStore from '../../../../stores/Checkout.store';
import { LocalStorageKey } from '../../../helpers/localStorage';
import { EMAIL_REGEX } from '../../../helpers/validators';
import { areArraysDifferent } from '../../../providers/utils';
import { gtmEvents } from '../../../types/gtmEvents';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { Input } from '../Input/Input';
import { Loader } from '../Loader/Loader';
import { Modal } from '../Modal/Modal';

import styles from './CopyLink.module.scss';

interface EmailFormType {
    name: string;
    email: string;
    message: string;
}

export const CopyLink = observer(() => {
    const { device } = useContext(DeviceDetectorContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [copy, setCopy] = useState(false);
    const [linkText, setLinkText] = useState('saveForLater');
    const [isLoading, setIsLoading] = useState(true);
    const [userEmail, setUserEmail] = useState('');

    const store = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);

    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmailFormType>();

    useEffect(() => {
        if (device === 'desktop') setLinkText(t('saveForLater'));
        if (device === 'tablet') setLinkText('Copy');
        if (device === 'mobile') setLinkText('');
    }, [device, t]);

    const copyToClipboard = () => {
        setCopy(true);
        setTimeout(() => setCopy(false), 5000);
        const copyText = `${process.env.REACT_APP_PUBLIC_URL}/share/${store.shareableID}`;

        window.dataLayer.push({
            event: gtmEvents.BIKE_SID_COPY,
            copyText,
        });

        navigator.clipboard.writeText(copyText);
    };

    const getSID = async () => {
        const isDifferent = areArraysDifferent(store.initialParts, store.selectedParts);

        // only generate new sid if some parts are changed
        if (!store.shareableID || !isDifferent) {
            await store.saveConfiguration();
            const email = localStorage.getItem(LocalStorageKey.EMAIL) ?? '';
            setUserEmail(email);
        }
        setModalOpen(true);

        window.dataLayer.push({
            event: gtmEvents.BIKE_SID_MODAL,
        });
    };

    useEffect(() => {
        if (store.takeScreenshot) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.takeScreenshot]);

    const onSubmit = async (data: EmailFormType) => {
        await checkoutStore.updateConfiguration(data.email, store.configurationID);
        setModalOpen(false);
    };

    return (
        <>
            <Button
                type="text"
                preIcon={device === 'mobile' ? 'copy-bold' : 'copy'}
                onClick={() => getSID()}
                className={styles.copyButton}
                isDisabled={store.initialLoading}
            >
                {linkText}
            </Button>
            <Modal
                isOpen={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                    // clear screenshot from store because camera view is stacked otherwise
                    store.screenshot = '';
                }}
                maxWidth={510}
                className={styles.modal}
                clickOutsideToClose={false}
            >
                <div className={styles.wrapper}>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <h3>{t('saveForLater')}</h3>
                            <p>{t('saveForLaterExplanation')}</p>

                            <div className={styles.codeWrapper}>
                                <div className={styles.code}>{store.shareableID}</div>
                                <div className={styles.link}>
                                    {t('SaveForLaterYourLink')}: {process.env.REACT_APP_PUBLIC_URL}
                                    /share/<span>{store.shareableID}</span>
                                </div>
                                <Icon onClick={() => copyToClipboard()} className={styles.copy_icon} name="copy-text" />
                            </div>
                            <div className={styles.buttonWrapper}>
                                <div className={clsx(styles.successMessage, copy && styles.show)}>
                                    <Icon name="check" />
                                    {t('SaveForLaterLinkCopied')}
                                </div>
                                <div
                                    className={styles.cashback}
                                    dangerouslySetInnerHTML={{ __html: t('checkoutProgress.step4') }}
                                />
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className={styles.email_wrapper}>
                                        <h5 className={styles.email_label}>{t('yourEmail')}</h5>
                                        <Input
                                            placeholder="alex.wonderlist@example.com"
                                            {...register('email', {
                                                required: t('fieldRequired'),
                                                pattern: {
                                                    value: EMAIL_REGEX,
                                                    message: t('emailIncorrect'),
                                                },
                                            })}
                                            defaultValue={checkoutStore?.data?.email ?? userEmail}
                                            error={errors.email?.message}
                                        />
                                    </div>

                                    <Button htmlType="submit" type="primary">
                                        {t('save')}
                                    </Button>
                                </form>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
});
