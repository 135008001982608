import CheckoutLogoImage from 'assets/images/checkout-logo.svg';
import TwinnerLogo from 'assets/images/twinner_logo_dark.svg';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { ContactModal } from 'shared/components/ui/ContactModal/ContactModal';
import CheckoutStore from 'stores/Checkout.store';
import ConfiguratorStore from 'stores/Configurator.store';

import styles from './CheckoutHeader.module.scss';

//TODO: Remove if not needed anymore
export const CheckoutHeader = observer(() => {
    const store = useContext(CheckoutStore);
    const configuratorStore = useContext(ConfiguratorStore);
    const [isTwinner, setIsTwinner] = useState(false);
    // Check if the bike is a twinner bike
    useEffect(() => {
        const name = configuratorStore.selectedBike?.name || store.productData?.name;
        if (name) {
            const twinner = name.toLowerCase().includes('twinner');
            setIsTwinner(twinner);
        }

        return () => {
            setIsTwinner(false);
        };
    }, [store.productData, configuratorStore.selectedBike]); //eslint-disable-line

    return (
        <header className={styles.checkoutHeader}>
            <div className={styles.container}>
                <div className={styles.logo}>
                    <div className={clsx(styles.checkoutLogo, isTwinner && styles.twinnerLogo)}>
                        <img src={isTwinner ? TwinnerLogo : CheckoutLogoImage} alt="Logo" />
                    </div>
                </div>

                <div className={styles.headerContent}>
                    <div className={styles.buttons}>
                        <ContactModal />
                    </div>
                </div>
            </div>
        </header>
    );
});
