import clsx from 'clsx';
import React from 'react';

import { Icon } from '../Icon/Icon';
import { Tooltip } from '../Tooltip/Tooltip';

import styles from './Label.module.scss';

interface Props {
    title: string;
    tooltipText?: string;
    className?: string;
}

export const Label: React.FC<Props> = ({ title, tooltipText, className }) => {
    return (
        <div className={clsx(styles.labelWrapper, className)}>
            <label className={styles.label}>{title}</label>
            {tooltipText && (
                <Tooltip label={tooltipText}>
                    <Icon name="question" />
                </Tooltip>
            )}
        </div>
    );
};
