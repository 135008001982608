import { GET_AR_BY_ID } from 'api/queries/configurator';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Icon } from 'shared/components/ui';
import { apolloClient } from 'shared/lib/apollo';

import styles from './ArPage.module.scss';

export const ArPage = () => {
    const [file, setFile] = useState('');
    const { id } = useParams();
    const modelRef = useRef();
    const { t } = useTranslation();

    useEffect(() => {
        if (id) {
            apolloClient
                .query({
                    query: GET_AR_BY_ID,
                    variables: { id },
                })
                .then(response => {
                    setFile(response.data.arModel.file);
                })
                .catch(e => console.error(e));
        }
    }, [id]);

    if (!id) return <div>You have no ID</div>;

    return (
        <div className={styles.arBox}>
            <model-viewer
                ref={modelRef}
                ar
                ar-modes="scene-viewer quick-look"
                src={file}
                shadow-intensity="1"
                camera-controls
                style={{ width: '100%', height: '100%' }}
            >
                <button slot="ar-button" id="ar-button" className={styles.btn}>
                    <Icon name="ar" />
                    {t('arButton')}
                </button>
            </model-viewer>
        </div>
    );
};
