import fallbackImg from 'assets/images/default-part-image.png';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { Icon } from 'shared/components/ui';
import { MenuPopup } from 'shared/components/ui/MenuPopup/MenuPopup';
import { Portal } from 'shared/components/ui/Portal/Portal';
import ConfiguratorStore from 'stores/Configurator.store';

import { DeviceDetectorContext } from '../../../../shared/providers/DeviceDetector';
import { PartVariant } from '../PartVariant/PartVariant';

import styles from './BikePart.module.scss';

type BikePartProps = {
    data: any;
    showDivider?: boolean;
    onClick?: () => void;
};

const SIDEBAR_WIDTH = 362;
const BIKE_PART_CARD_WIDTH = 160;

enum PopupPosition {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    CENTER = 'CENTER',
}

export const BikePart: FC<BikePartProps> = observer(({ data, showDivider = false, onClick }) => {
    const { device } = useContext(DeviceDetectorContext);
    const store = useContext(ConfiguratorStore);
    const [openPopup, setOpenPopup] = useState(false);
    const [isPartSelected, setIsPartSelected] = useState(false);
    const [popupOffset, setPopupOffset] = useState(0);
    const popupReference = useRef<HTMLDivElement>(null);
    const [popupPosition, setPopupPosition] = useState(PopupPosition.RIGHT);

    // find selected variant and transfer it to a variant component - needs this for price changing
    const selectedVariant = store.selectedParts.find(item => item.productTypeId === data.typeId);
    const isPartMandatory = store.mandatoryParts.find((item: any) => item.id === data.typeId);

    useEffect(() => {
        if (store.selectedParts.length !== 0) {
            const partSelected = store.selectedParts.find(item => item.productTypeId === data.typeId);
            setIsPartSelected(partSelected);
        }

        // check if the last part is deleted from selectedParts array
        if (store.selectedParts.length === 0) {
            setIsPartSelected(false);
        }
    }, [store.selectedParts, store.selectedParts.length, data, openPopup]);

    useEffect(() => {
        if (openPopup) {
            if (store.selectedGroupType !== data.type) store.selectedGroupType = data.type;

            if (data.values) {
                for (const item of data.values) {
                    const itemId = store.loadingModelIds.find(i => i.id === item.referenceProduct.id);
                    if (!itemId) {
                        store.loadingModelIds.push({
                            id: item.referenceProduct?.id,
                            isLoaded: false,
                            isLoading: false,
                        });
                    }
                }
            }
        } else {
            setTimeout(() => store.selectedGroupType === '', 300);
        }
    }, [store, store.selectedGroupType, openPopup, data]);

    const onPartClick = (event: MouseEvent) => {
        const carouselWidth = window.innerWidth - SIDEBAR_WIDTH;
        const element = event.currentTarget as HTMLDivElement;
        const left = element?.getBoundingClientRect().left;

        const popupAvailableSpace = carouselWidth - left;

        // Disable click if part is not fully visible in carousel
        if (popupAvailableSpace < BIKE_PART_CARD_WIDTH || left < 0) return;
        const popupWidth = 662;
        const isPopupLeft = left + popupWidth + 50 > window.innerWidth;

        var offset = left;

        if (isPopupLeft) {
            offset = Math.abs(left - 450);
            setPopupPosition(PopupPosition.LEFT);
        }

        setPopupOffset(offset);

        const isPopupCenter = isPopupLeft && left - popupWidth + BIKE_PART_CARD_WIDTH < 0;
        if (isPopupLeft && isPopupCenter) {
            setPopupPosition(PopupPosition.CENTER);
        }

        if (!openPopup) {
            setOpenPopup(!openPopup);
        }
    };

    return (
        <div className={styles.bike_part_wrapper}>
            <div
                className={clsx('BikePart', styles.bike_part, openPopup && styles.active_bike_part)}
                key={data.id}
                onClick={onClick ?? onPartClick}
            >
                <img
                    src={data && data.image ? `${data.image}?w=300&auto=compress` : fallbackImg}
                    alt={data.type}
                    className={styles.part_image}
                />

                <h6 className={styles.name}>{selectedVariant?.shortName || data.type}</h6>

                {isPartSelected && (
                    <div className={styles.variations}>
                        <Icon name="check" />
                    </div>
                )}

                {device === 'desktop' && (
                    <>
                        {openPopup && (
                            <>
                                <Portal>
                                    <div
                                        ref={popupReference}
                                        style={{
                                            position: 'absolute',
                                            left: popupOffset,
                                            bottom: 34,
                                        }}
                                    >
                                        <MenuPopup
                                            width={662}
                                            className={clsx(styles.parts_popup)}
                                            bottomArrow
                                            arrowClassName={clsx(
                                                styles.popup_arrow,
                                                popupPosition === PopupPosition.LEFT && styles.popup_arrow_to_left,
                                                popupPosition === PopupPosition.RIGHT && styles.popup_arrow_to_right,
                                                popupPosition === PopupPosition.CENTER && styles.popup_arrow_to_center,
                                            )}
                                            onClose={e => {
                                                e.stopPropagation();
                                                setOpenPopup(false);
                                            }}
                                        >
                                            <div className={styles.title_wrapper}>
                                                <h6 className={styles.parent}>{data.type}</h6>
                                            </div>
                                            <div className={styles.container_with_variants}>
                                                <div className={styles.variants}>
                                                    {data.values.map((value: any, i: number) => {
                                                        return (
                                                            <PartVariant
                                                                data={value}
                                                                key={`${value.id}- ${i}`}
                                                                closePopup={() => {
                                                                    setOpenPopup(false);
                                                                }}
                                                                typeId={data.typeId}
                                                                selectedVariant={selectedVariant}
                                                                partGroup={data.group}
                                                                isPartMandatory={isPartMandatory}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </MenuPopup>
                                    </div>
                                </Portal>
                            </>
                        )}
                    </>
                )}
            </div>

            {showDivider && (
                <div className={styles.divider}>
                    <div className={styles.line} />
                </div>
            )}
        </div>
    );
});
