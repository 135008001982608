import { UPLOAD_AR_FILE } from 'api/mutations/configurator';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { Button, Loader, Modal } from 'shared/components/ui';
import { apolloClient } from 'shared/lib/apollo';
import ConfiguratorStore from 'stores/Configurator.store';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';

import styles from './AR.module.scss';

export const ARModal: React.FC<any> = ({ onOpen, onClose }) => {
    const modelRef = useRef<any>();

    const [arFile, setArFile] = useState<any>('');
    const [arID, setArID] = useState(null);
    const [launchingAR, setLaunchingAR] = useState(false);
    const store = useContext(ConfiguratorStore);
    const scene = store.threeScene;
    const QRurl = `${process.env.REACT_APP_PUBLIC_URL}/ar/` || '';
    const { t } = useTranslation();

    useEffect(() => {
        if (onOpen) {
            generateARFile();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onOpen]);

    const generateARFile = () => {
        const exporter = new GLTFExporter();
        const options = { onlyVisible: true, binary: true };

        let sc = scene.clone();
        const scale = 0.01;
        for (var i = 0; i < sc.children.length; i++) {
            sc.children[i].scale.set(scale, scale, scale);
        }

        exporter.parse(
            sc,
            function (result: any) {
                const stamp = new Date().getTime();
                const blob = new Blob([result], { type: 'application/octet-stream' });
                const file = new File([blob], `ar-${stamp}.glb`);
                uploadFile(file);
            },
            e => {
                console.error(e);
            },
            options,
        );
    };

    const uploadFile = async (file: File) => {
        await apolloClient
            .mutate({
                mutation: UPLOAD_AR_FILE,
                variables: { input: { file } },
            })
            .then((resp: any) => {
                const canActivate = modelRef.current.canActivateAR;
                if (canActivate) {
                    setArFile(resp.data.arModelCreate.arModel.file);
                } else {
                    setArID(resp.data.arModelCreate.arModel.id);
                }
            })
            .catch(e => {
                console.error(e);
            });
    };

    const closeModal = () => {
        setArFile('');
        setArID(null);
        store.listenForNavigationChange = true;
        onClose();
    };

    const launchAR = () => {
        store.listenForNavigationChange = false;
        setLaunchingAR(true);
        setTimeout(() => modelRef.current.activateAR(), 300);
        setTimeout(() => setLaunchingAR(false), 10000);
    };

    return (
        <>
            <Modal isOpen={onOpen} maxWidth={450} onClose={() => closeModal()} className={styles.modal}>
                <h3>{t('arModalTitle')}</h3>
                <div className={styles.modalContent}>
                    {!arID && !arFile && (
                        <div className={styles.loadingBox}>
                            <Loader />
                            <div>{t('arGeneratingModel')}</div>
                        </div>
                    )}
                    {!arID && arFile && (
                        <div className={styles.loadingBox}>
                            <div>{t('arGenerationComplete')}</div>
                            <Button
                                preIcon="ar"
                                type="secondary"
                                onClick={() => launchAR()}
                                isDisabled={launchingAR}
                                className="ar-button"
                            >
                                {launchingAR ? t('arButtonLoadingEngine') : t('arButton')}
                            </Button>
                        </div>
                    )}
                    {arID && (
                        <div className={styles.qrBox}>
                            <QRCode value={`${QRurl}${arID}`} size={200} />
                            <div dangerouslySetInnerHTML={{ __html: t('arNotSupported') }}></div>
                        </div>
                    )}
                </div>
            </Modal>
            <model-viewer
                ref={modelRef}
                ar
                ar-modes="scene-viewer quick-look"
                reveal="manual"
                src={arFile}
                shadow-intensity="1"
                style={{ width: '0px', height: '0px' }}
            />
        </>
    );
};
