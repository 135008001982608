import React from 'react';
import {useTranslation} from "react-i18next";
import { Button } from 'shared/components/ui';

import styles from './AddAddressCard.module.scss';

interface Props {
    onClick?: () => void;
}

export const AddAddressCard: React.FC<Props> = ({ onClick }) => {

    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <Button type="ghost" className={styles.button} onClick={onClick}>
                + {t('addAddress')}
            </Button>
        </div>
    );
};
