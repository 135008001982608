import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components/ui';
import { ShippingMethodType, WarehouseType } from 'shared/types/checkout';
import CheckoutStore, { CheckoutMode, CheckoutStep } from 'stores/Checkout.store';

import { DeliveryMethodCard } from './_components/DeliveryMethodCard/DeliveryMethodCard';
import { Pickup } from './_components/Pickup/Pickup';
import styles from './Delivery.module.scss';

enum CustomDeliveryMethod {
    PICKUP = 'PICKUP',
}

export const Delivery = observer(() => {
    const checkoutStore = useContext(CheckoutStore);
    const shippingMethods = checkoutStore.data?.shippingMethods;
    const availableCollectionPoints = toJS(checkoutStore.data?.availableCollectionPoints) ?? [];
    const shippingAddress = checkoutStore.data?.shippingAddress;
    const stripePaymentGateway = checkoutStore.data?.availablePaymentGateways?.find(
        gateway => gateway.id === 'saleor.payments.stripe',
    );

    const [selectedMethod, setSelectedMethod] = useState<ShippingMethodType>();
    const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseType>(availableCollectionPoints[0]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeliveryMethodUpdating, setIsDeliveryMethodUpdating] = useState(false);
    const { t } = useTranslation();

    const isNextStepDisabled = useMemo(
        () => !selectedMethod || (selectedMethod.id === CustomDeliveryMethod.PICKUP && !selectedWarehouse),
        [selectedMethod, selectedWarehouse],
    );

    useEffect(() => {
        if (checkoutStore.data?.deliveryMethod) {
            const method = checkoutStore.data?.shippingMethods?.find(
                (item: any) => item.name === checkoutStore.data?.deliveryMethod?.name,
            );
            if (method) {
                setSelectedMethod(method);
            } else {
                const location = checkoutStore.data?.availableCollectionPoints?.find(
                    (item: any) => item.name === checkoutStore.data?.deliveryMethod?.name,
                );
                if (location) {
                    setSelectedMethod({
                        id: CustomDeliveryMethod.PICKUP,
                        name: t('delivery_pickup'),
                        price: { amount: 0, currency: checkoutStore.data?.totalPrice?.currency || 'CHF' },
                    });
                }
            }
        }
    }, []); //eslint-disable-line

    const nextStep = async () => {
        try {
            setIsLoading(true);

            // move this logic to payment Component if multiple payment providers will be used
            if (stripePaymentGateway && !checkoutStore.clientSecret) {
                // First createPayment needs to be executed then checkoutComplete (Saleor docs)
                await checkoutStore.createPayment(stripePaymentGateway.id);
                const stripeData: any = await checkoutStore.checkoutComplete();

                // Get client_secret from checkoutComplete method and set it to store
                if (stripeData.confirmationData) {
                    const parsed = JSON.parse(stripeData.confirmationData);
                    checkoutStore.clientSecret = parsed.client_secret;
                }
            }

            window.dataLayer.push({
                event: 'Checkout section',
                from: 'Delivery details',
                to: checkoutStore.mode === CheckoutMode.BASIC ? 'Payment details' : 'Expert checkout details',
                label: checkoutStore.productData?.name,
            });

            await checkoutStore.sendDataToCustomerIo(
                'Checkout section',
                checkoutStore.mode === CheckoutMode.BASIC ? 'Payment details' : 'Expert checkout details',
            );

            if (checkoutStore.mode === CheckoutMode.BASIC) {
                checkoutStore.checkoutStepProgress = CheckoutStep.PAYMENT;
                checkoutStore.expandedStep = CheckoutStep.PAYMENT;
            }
            if (checkoutStore.mode === CheckoutMode.EXPERT) {
                checkoutStore.checkoutStepProgress = CheckoutStep.EXPERT_CHECKOUT;
                checkoutStore.expandedStep = CheckoutStep.EXPERT_CHECKOUT;
            }
            if (selectedMethod?.id === CustomDeliveryMethod.PICKUP) {
                pickUpUpdate();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const deliveryMethodUpdate = async (method: ShippingMethodType) => {
        try {
            setIsDeliveryMethodUpdating(true);
            setSelectedMethod(method);
            await checkoutStore.setDeliveryMethod(method.id);
        } catch (error) {
            console.error(error);
        } finally {
            setIsDeliveryMethodUpdating(false);
        }
    };

    const selectPickup = (data: WarehouseType) => {
        setSelectedWarehouse(data);
    };

    const pickUpUpdate = async () => {
        try {
            setIsDeliveryMethodUpdating(true);

            setSelectedMethod({
                id: CustomDeliveryMethod.PICKUP,
                name: t('delivery_pickup'),
                price: { amount: 0, currency: checkoutStore.data?.totalPrice?.currency || 'CHF' },
            });

            await checkoutStore.setDeliveryMethod(selectedWarehouse.id);
        } catch (error) {
            console.error(error);
        } finally {
            setIsDeliveryMethodUpdating(false);
        }
    };

    return (
        <div className={styles.root}>
            <div className={styles.deliveryMethods}>
                {shippingMethods?.map((method: ShippingMethodType) => (
                    <DeliveryMethodCard
                        key={method.id}
                        selected={selectedMethod?.id === method.id}
                        title={method.translation?.name || method.name}
                        price={method.price.amount}
                        currency={method.price.currency}
                        onClick={() => deliveryMethodUpdate(method)}
                    />
                ))}

                <DeliveryMethodCard
                    selected={selectedMethod?.id === CustomDeliveryMethod.PICKUP}
                    title={t('delivery_pickup')}
                    price={0}
                    currency={checkoutStore.data?.totalPrice.currency}
                    onClick={() => pickUpUpdate()}
                />
            </div>

            {shippingAddress && selectedMethod?.id !== CustomDeliveryMethod.PICKUP && (
                <div className={styles.homeAddress}>
                    <p>
                        {shippingAddress.firstName} {shippingAddress.lastName}
                    </p>
                    <p>{shippingAddress.streetAddress1},</p>
                    <p>
                        {shippingAddress.postalCode} {shippingAddress.city}, {shippingAddress.country.country}
                    </p>
                    <p>{shippingAddress.phone}</p>
                </div>
            )}

            {selectedMethod?.id === CustomDeliveryMethod.PICKUP && <Pickup onChange={data => selectPickup(data)} />}

            <Button
                className={styles.button}
                isDisabled={isNextStepDisabled}
                isLoading={isLoading || isDeliveryMethodUpdating}
                onClick={nextStep}
            >
                {t('next')}
            </Button>
        </div>
    );
});
