import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ErrorMessage } from 'shared/components/ui';
import { localizePrice } from 'shared/providers/utils';

import { AppRoutes } from '../../../../Routes';
import CheckoutStore from '../../../../stores/Checkout.store';

import styles from './StripeForm.module.scss';

export const StripeForm = observer(() => {
    const checkoutStore = useContext(CheckoutStore);
    const stripe = useStripe();
    const elements = useElements();

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation();

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);

        if (!elements || !stripe) return;

        await checkoutStore.updatePaymentIntent();

        const { error }: any = await stripe?.confirmPayment({
            elements,
            confirmParams: {
                return_url:
                    process.env.REACT_APP_PUBLIC_URL +
                    AppRoutes.paymentStatus.replace(':id', `${checkoutStore.data?.id}`),
            },
        });

        setIsLoading(false);

        window.dataLayer.push({
            event: 'Checkout',
            action: 'Next',
            category: 'Payment',
            label: checkoutStore.productData?.name,
        });

        await checkoutStore.sendDataToCustomerIo('Checkout', 'payment');

        if (error) {
            console.error('error', error);

            switch (error.code) {
                case 'payment_intent_authentication_failure':
                    return setErrorMessage(t('cardAuthorizationFailed'));
                case 'card_declined':
                    return setErrorMessage(t('cardInsufficientFunds'));
                default:
                    return t('somethingWentWrong');
            }
        }
    };

    return (
        <form onSubmit={handleSubmit} className={styles.form}>
            <PaymentElement />
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <div className={styles.infoText}>
                {checkoutStore.prepaymentPercentage < 100 &&
                    t('partialPaymentInfoText', {
                        discount: checkoutStore.prepaymentPercentage,
                    })}
            </div>
            <div className={styles.buttonsWrapper}>
                <Button
                    preIcon="lock"
                    className={styles.button}
                    onClick={handleSubmit}
                    isLoading={isLoading}
                    isDisabled={!elements}
                >
                    {t(`paymentPay`, {
                        price: `${checkoutStore.data?.totalPrice.currency} ${localizePrice(
                            checkoutStore.data?.totalPrice.gross?.amount &&
                                checkoutStore.data?.totalPrice.gross?.amount *
                                    (checkoutStore.prepaymentPercentage / 100),
                        )}`,
                    })}
                </Button>
            </div>
        </form>
    );
});
