import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/ui/Icon/Icon';
import { Radio } from 'shared/components/ui/Radio/Radio';

import { gtmEvents } from '../../../../../types/gtmEvents';
import { ContactModalContext, ContactModalScreen } from '../../stores/ContactModal.store';
import { ContactCover } from '../ContactCover/ContactCover';

import { EmailForm } from './_components/EmailForm/EmailForm';
import { PhoneForm } from './_components/PhoneForm/PhoneForm';
import styles from './ContactForm.module.scss';

enum Option {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE',
}

export const ContactForm = observer(() => {
    const contactModalStore = useContext(ContactModalContext);

    const [selectedOption, setSelectedOption] = useState(Option.EMAIL);
    const { t } = useTranslation();

    const chosenOption = (option: any) => {
        setSelectedOption(option);

        window.dataLayer.push({
            event: gtmEvents.CONTACT_FORM_OPTION,
            option: option,
        });
    };

    return (
        <div>
            <Icon
                name="arrow_left"
                className={styles.backIcon}
                onClick={() => (contactModalStore.currentScreen = ContactModalScreen.START)}
            />

            <ContactCover />

            <div className={styles.body}>
                <h5>{t('howDoYouWantContact')}</h5>

                <div className={styles.options}>
                    <Radio selected={selectedOption === Option.EMAIL} onClick={() => chosenOption(Option.EMAIL)}>
                        {t('contactFormEmail')}
                    </Radio>
                    <Radio selected={selectedOption === Option.PHONE} onClick={() => chosenOption(Option.PHONE)}>
                        {t('phoneTitle')}
                    </Radio>
                </div>

                {selectedOption === Option.EMAIL && <EmailForm />}
                {selectedOption === Option.PHONE && <PhoneForm />}
            </div>
        </div>
    );
});
