import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { localizePrice } from 'shared/providers/utils';

import styles from './InstallmentOption.module.scss';

type InstallmentOptionProps = {
    price: number;
    installments: number;
    popular?: number;
    onClick: () => void;
    selected: null | number;
};
export const InstallmentOption: React.FC<InstallmentOptionProps> = ({
    price,
    installments,
    popular,
    selected,
    onClick,
}) => {
    const { t } = useTranslation();
    return (
        <div className={clsx(styles.option, selected === installments && styles.selected)} onClick={onClick}>
            <div className={styles.popular_option}>
                <span>{t('installmentsNumber').replace('{number}', `${installments}`)}</span>
                {popular === installments && <label>{t('installmentsMostPopularLabel')}</label>}
            </div>
            <div className={styles.optionPrice}>
                {t('installmentsPricePerMonth').replace('{amount}', localizePrice(price / installments))}
            </div>
        </div>
    );
};
