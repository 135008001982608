import { gql } from '@apollo/client';

export const SSO_SESSION_AUTORIZE = gql`
    mutation SsoSessionAuthorize($token: String!) {
        ssoSessionAuthorize(token: $token) {
            access
            refresh
            errors {
                field
                message
                code
            }
        }
    }
`;

export const TOKEN_REFRESH = gql`
    mutation tokenRefresh($refreshToken: String!) {
        tokenRefresh(refreshToken: $refreshToken) {
            token
            errors {
                code
                field
                message
            }
        }
    }
`;
