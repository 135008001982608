import ReactGA from 'react-ga4';

interface SendAnalyticsEvent {
    category: string;
    action: string;
    label?: string;
}

export const analytics = {
    sendEvent: ({ category, action, label }: SendAnalyticsEvent) => {
        ReactGA.event({ category, action, label });
    },
};
