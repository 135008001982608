const get = (name: string) => {
    if (typeof window === 'undefined') return;
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
};

const remove = (name: string) => {
    if (typeof window === 'undefined') return;
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

const set = (name: string, value: string, data?: Date) => {
    if (typeof window === 'undefined') return;

    const expiredDate = data || new Date();

    if (!data) expiredDate.setFullYear(expiredDate.getFullYear() + 10);

    document.cookie = `${name}=${value}; path=/; expires=${expiredDate.toUTCString()}`;
};

export const CookieHelper = {
    get,
    set,
    remove,
};
