import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'shared/components/ui';
import CheckoutStore from 'stores/Checkout.store';

import { InstallmentPayment } from './_components/InstallmentPayment/InstallmentPayment';
import { StripePayment } from './_components/StripePayment/StripePayment';
import styles from './Payment.module.scss';

type PaymentProps = {};

export const Payment: FC<PaymentProps> = observer(() => {
    const checkoutStore = useContext(CheckoutStore);

    const [tab, setTab] = useState(1);
    const { t } = useTranslation();

    useEffect(() => {
        if (checkoutStore.totalPrice > 11000) {
            setTab(2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={styles.tabs}>
                {checkoutStore.totalPrice <= 11000 && (
                    <>
                        <TabItem selected={tab === 1} onClick={() => setTab(1)}>
                            {t('paymentFinance')}
                        </TabItem>
                        {tab === 1 && <InstallmentPayment />}
                    </>
                )}

                <TabItem selected={tab === 2} onClick={() => setTab(2)}>
                    {checkoutStore.prepaymentPercentage >= 100
                        ? t('paymentStripeInFull')
                        : t('paymentStripe').replace('{percentage}', `${checkoutStore.prepaymentPercentage}%`)}
                </TabItem>
                {tab === 2 && <StripePayment />}
            </div>
        </>
    );
});

type TabItemProps = {
    children: string | JSX.Element;
    selected: boolean;
    onClick: () => void;
};
export const TabItem: React.FC<TabItemProps> = ({ children, selected, onClick }) => {
    return (
        <div className={clsx(styles.tab, selected && styles.selected)} onClick={onClick}>
            <Radio selected={selected} />
            {children}
        </div>
    );
};
