import clsx from 'clsx';
import React from 'react';
import { Icon } from 'shared/components/ui';

import styles from './AccordionTitle.module.scss';

interface Props {
    title: string;
    titleStyle?: string;
    summary?: string;
    showIcon?: boolean;
    onClick?: () => void;
}

export const AccordionTitle: React.FC<Props> = ({ title, titleStyle, summary, showIcon = false, onClick }) => {
    return (
        <div>
            <div className={styles.titleWrapper}>
                <h2 onClick={onClick} className={clsx(styles.title, titleStyle)}>
                    {title}
                </h2>
                {showIcon && <Icon onClick={onClick} name="edit" />}
            </div>
            {summary && showIcon && <div className={styles.subtitle}>{summary}</div>}
        </div>
    );
};
