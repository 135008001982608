import clsx from 'clsx';
import React from 'react';
import { Icon } from 'shared/components/ui';

import styles from './FrameSizeButton.module.scss';

type FrameSizeButtonProps = {
    onClick?: () => void;
    className?: string;
};

export const FrameSizeButton: React.FC<FrameSizeButtonProps> = ({ onClick, className }) => {
    return (
        <div className={clsx(styles.frame_size, className)} onClick={onClick}>
            <Icon name="ruler" />
        </div>
    );
};
