import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { LocalStorageKey } from 'shared/helpers/localStorage';

import { Header } from '../Header/Header';

import styles from './Layout.module.scss';

type LayoutProps = {
    children?: React.ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
    const location = useLocation();
    const isCheckout = location.pathname.includes('checkout');
    const isAuthSuccess = location.pathname.includes(AppRoutes.authSuccess);
    const isPaymentStatus = location.pathname.includes('/payment-status');
    const isOrderStatus = location.pathname.includes('/order-status');
    const [searchParams] = useSearchParams();
    const language = searchParams.get('l')?.toUpperCase();

    const isCheckoutLayout = isCheckout || isAuthSuccess || isPaymentStatus || isOrderStatus;

    useEffect(() => {
        if (language) {
            const currentLanguage = localStorage.getItem(LocalStorageKey.LANGUAGE);
            if (!process.env.REACT_APP_LANGUAGES?.includes(language)) return;
            if (currentLanguage && currentLanguage === language) return;
            localStorage.setItem(LocalStorageKey.LANGUAGE, language);
            window.location.reload();
        }
    }, [language]);

    return (
        <div className={clsx(styles.layout, isCheckoutLayout && styles.checkout)}>
            <Header />
            <div className={clsx(styles.main, isCheckoutLayout && styles.checkout)}>{children}</div>
        </div>
    );
};
