import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LocalStorageKey } from 'shared/helpers/localStorage';

// Locales
import German from './shared/locales/de.json';
import AmericanEnglish from './shared/locales/en-US.json';
import English from './shared/locales/en.json';
import French from './shared/locales/fr.json';
import Italian from './shared/locales/it.json';

const resources = {
    en: {
        translation: English,
    },
    de: {
        translation: German,
    },
    fr: {
        translation: French,
    },
    it: {
        translation: Italian,
    },
    'en-US': {
        translation: AmericanEnglish,
    },
};

const language = localStorage.getItem(LocalStorageKey.LANGUAGE) || process.env.REACT_APP_LOCALE || 'DE';

i18n.use(initReactI18next).init({
    resources,
    saveMissing: true,
    lng: language.toLowerCase(),
});

export default i18n;
