import React from 'react';
import { Radio } from 'shared/components/ui';
import { localizePrice } from 'shared/providers/utils';

import styles from './DeliveryMethodCard.module.scss';

interface Props {
    selected?: boolean;
    title?: string;
    price?: number;
    currency?: string;
    onClick?: () => void;
}

export const DeliveryMethodCard: React.FC<Props> = ({ selected, title, price, currency, onClick }) => {
    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <Radio selected={selected} onClick={onClick} />

                <span>{title}</span>
            </div>
            <div className={styles.price}>
                {currency} {localizePrice(price ?? 0)}
            </div>
        </div>
    );
};
