import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { Language } from 'shared/types/types';
import ConfiguratorStore from 'stores/Configurator.store';

import styles from './LanguageSelect.module.scss';

export const LanguageSelect = () => {
    const [languages, setLanguages] = useState<Language[]>([{ label: 'DE', value: 'DE' }]);
    useEffect(() => {
        if (process.env.REACT_APP_LANGUAGES) {
            const languagesList = process.env.REACT_APP_LANGUAGES.split(',');
            const langs = languagesList.map(l => ({
                label: l.split('-')[0].toUpperCase(),
                value: l,
            }));
            setLanguages(langs);
        }
    }, []);

    const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
    const store = useContext(ConfiguratorStore);

    useEffect(() => {
        const lang = localStorage.getItem(LocalStorageKey.LANGUAGE);
        if (lang && languages.length > 0) {
            const selected = languages.find(l => l.value === lang);
            setSelectedLanguage(selected || languages[0]);
        }
    }, [languages, store]);

    const selectLanguage = (e: any) => {
        localStorage.setItem(LocalStorageKey.LANGUAGE, e.value);
        window.location.reload();
    };

    return (
        <div className={styles.languageSelect}>
            <Select
                className={styles.select}
                value={selectedLanguage}
                classNamePrefix="lang-select"
                options={languages}
                isSearchable={false}
                onChange={e => selectLanguage(e)}
            />
        </div>
    );
};
