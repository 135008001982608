import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'shared/components/ui';
import { AddressInputType, mapAddressTypeToAddressInputType } from 'shared/types/checkout';
import CheckoutStore, { AddressModalState } from 'stores/Checkout.store';

import { AddressForm } from '../AddressForm/AddressForm';

export const AddressModal: React.FC = observer(() => {
    const checkoutStore = useContext(CheckoutStore);
    const { isAddressModalOpen, addressModalInitialData, data } = checkoutStore;
    const { t } = useTranslation();

    const isEdit = !!addressModalInitialData;

    const onClose = () => {
        // reset();
        checkoutStore.addressModalState = AddressModalState.CLOSED;
        // checkoutStore.addressModalInitialData = undefined;
    };

    const onSubmit = async (data: AddressInputType) => {
        data.phone = data.phone?.includes('+') ? data.phone : `+${data.phone}`;
        const isGuestCheckout = checkoutStore.data?.user === null && checkoutStore.data?.email !== null;
        try {
            if (isGuestCheckout) {
                await checkoutStore.guestAddressModalOnSubmit(data);
            } else {
                await checkoutStore.addressModalOnSubmit(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Modal isOpen={isAddressModalOpen} onClose={onClose} maxWidth={576}>
            <h3>{isEdit ? t('editAddressTitle') : t('addAddressTitle')}</h3>

            <AddressForm
                onSubmit={onSubmit}
                isLoading={checkoutStore.addressLoading}
                isEdit={isEdit}
                countries={checkoutStore.countries}
                initialData={addressModalInitialData && mapAddressTypeToAddressInputType(addressModalInitialData)}
                phone={data?.phone}
            />
        </Modal>
    );
});
