import { gql } from '@apollo/client';
import { CHECKOUT_FIELDS } from 'api/fragments/checkout';

export const CHECKOUT = gql`
    query getCheckout($id: ID, $language: LanguageCodeEnum!) {
        checkout(id: $id) {
            ${CHECKOUT_FIELDS}
        }
    }
`;

export const SHIPPING_ZONES = gql`
    query {
        shippingZones(first: 100) {
            edges {
                node {
                    id
                    name
                    countries {
                        code
                        country
                    }
                }
            }
        }
    }
`;

export const FETCH_PRODUCT_DATA = gql`
    query product($id: ID!, $channel: String, $language: LanguageCodeEnum!) {
        product(id: $id, channel: $channel) {
            name
            translation(languageCode: $language) {
                name
            }
        }
    }
`;

export const ADDRESS_VALIDATION_RULES = gql`
    query addressValidationRules($countryCode: CountryCode!) {
        addressValidationRules(countryCode: $countryCode) {
            countryAreaType
            countryAreaChoices {
                raw
                verbose
            }
        }
    }
`;

export const CHECKOUT_EXPERT_DROPDOWN_FIELDS = gql`
    query checkoutExpertDropdownFields($checkoutId: ID!, $token: String!) {
        checkoutExpertDropdownFields(id: $checkoutId, token: $token) {
            name
            values {
                name
            }
        }
    }
`;

export const SHOP = gql`
    query Shop {
        shop {
            prepaymentPercentage
        }
    }
`