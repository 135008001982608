import { ApolloClient, ApolloLink, fromPromise, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { TOKEN_REFRESH } from 'api/mutations/auth';
import { LocalStorageKey } from 'shared/helpers/localStorage';

import { httpLink } from './httpLink';

let isRefreshing = false;
let pendingRequests: Function[] = [];

const setIsRefreshing = (value: boolean) => {
    isRefreshing = value;
};

const addPendingRequest = (pendingRequest: Function) => {
    pendingRequests.push(pendingRequest);
};

const renewTokenApiClient = new ApolloClient({
    link: ApolloLink.from([httpLink]),
    cache: new InMemoryCache(),
});

const resolvePendingRequests = () => {
    pendingRequests.map(callback => callback());
    pendingRequests = [];
};

const refreshToken = async () => {
    const refreshToken = localStorage.getItem(LocalStorageKey.REFRESH_TOKEN);

    const resp = await renewTokenApiClient.mutate({ mutation: TOKEN_REFRESH, variables: { refreshToken } });

    const { errors } = resp;
    if (errors) {
        console.info('Refresh token error:', errors);
    }

    const accessToken = resp.data.tokenRefresh.token;

    localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, accessToken);
    return accessToken;
};

export const refreshTokenErrorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
        for (const e of graphQLErrors) {
            const err = e as any;

            // If refresh token is invalid remove tokens from local storage
            if (err?.extensions?.exception?.code === 'InvalidSignatureError') {
                localStorage.removeItem(LocalStorageKey.REFRESH_TOKEN);
                localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
                return forward(operation);
            }

            if (err?.extensions?.exception?.code === 'ExpiredSignatureError') {
                if (!isRefreshing) {
                    setIsRefreshing(true);
                    return fromPromise(
                        refreshToken().catch(() => {
                            // If token refresh fails try to resolve pending requests and clear local storage
                            resolvePendingRequests();
                            setIsRefreshing(false);
                            localStorage.removeItem(LocalStorageKey.REFRESH_TOKEN);
                            localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);

                            return forward(operation);
                        }),
                    ).flatMap(() => {
                        // After successful refresh is done call all pending requests
                        resolvePendingRequests();
                        setIsRefreshing(false);

                        return forward(operation);
                    });
                } else {
                    // Queue all pending requests if refresh is in progress
                    return fromPromise(
                        new Promise(resolve => {
                            addPendingRequest(resolve);
                        }),
                    ).flatMap(() => {
                        return forward(operation);
                    });
                }
            }
        }
    }
});
