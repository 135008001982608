import { ArPage } from 'pages/ArPage/ArPage';
import { AuthSuccess } from 'pages/AuthSuccess/AuthSuccess';
import { Checkout } from 'pages/Checkout/Checkout';
import { FinanceStatus } from 'pages/FinanceStatus/FinanceStatus';
import { NotFound } from 'pages/NotFound/NotFound';
import { OrderStatus } from 'pages/OrderStatus/OrderStatus';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import { useAnalytics } from 'shared/hooks/useAnalytics';

// PAGES
import { BikeModel } from './pages/BikeModel/BikeModel';
import { Configurator } from './pages/Configurator/Configurator';
import { Home } from './pages/Home/Home';
import { PaymentStatus } from './pages/PaymentStatus/PaymentStatus';
import { ServiceUpgrade } from './pages/ServiceUpgrade/ServiceUpgrade';

export enum AppRoutes {
    home = '/',
    bikeModel = '/bike-model',
    configurator = '/configurator',
    checkout = '/checkout/:id',
    serviceUpgrade = '/service-upgrade',
    paymentStatus = '/payment-status/:id',
    orderStatus = '/order-status/:id',
    financeStatus = '/finance-status/:id',
    authSuccess = '/auth/success',
    ar = '/ar/:id',
}

export const Routes = () => {
    useAnalytics();

    return (
        <ReactRoutes>
            <Route path={AppRoutes.home} element={<Home />} />
            <Route path={`${AppRoutes.bikeModel}/:category`} element={<BikeModel />} />
            <Route path={AppRoutes.configurator} element={<Configurator />} />
            <Route path={`${AppRoutes.configurator}/:sid`} element={<Configurator />} />
            <Route path={AppRoutes.serviceUpgrade} element={<ServiceUpgrade />} />
            <Route path={AppRoutes.checkout} element={<Checkout />} />
            <Route path={AppRoutes.paymentStatus} element={<PaymentStatus />} />
            <Route path={AppRoutes.orderStatus} element={<OrderStatus />} />
            <Route path={AppRoutes.financeStatus} element={<FinanceStatus />} />
            <Route path={AppRoutes.authSuccess} element={<AuthSuccess />} />
            <Route path={AppRoutes.ar} element={<ArPage />} />
            <Route path="*" element={<NotFound />} />
        </ReactRoutes>
    );
};
