import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { Tooltip, ErrorMessage } from 'shared/components/ui';

import { Icon } from '../Icon/Icon';

import styles from './Input.module.scss';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    labelWrapperClassName?: string;
    tooltipText?: string;
    suffix?: React.ReactNode;
    inputClassName?: string;
    error?: string | boolean;
    errorClassName?: string;
};

export const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>(
    (
        {
            className,
            label,
            labelWrapperClassName,
            tooltipText,
            inputClassName,
            suffix,
            error,
            errorClassName,
            ...rest
        },
        ref,
    ) => {
        return (
            <div className={clsx(styles.inputWrapper, className)}>
                {label && (
                    <div className={clsx(styles.labelWrapper, labelWrapperClassName)}>
                        <label className={styles.label}>{label}</label>
                        {tooltipText && (
                            <Tooltip label={tooltipText}>
                                <Icon name="question" />
                            </Tooltip>
                        )}
                    </div>
                )}

                <div className={clsx(styles.input, inputClassName, error && styles.error)}>
                    <input ref={ref} {...rest} />
                    {suffix && <div className={styles.suffix}>{suffix}</div>}
                </div>

                {typeof error === 'string' && error && (
                    <ErrorMessage message={error} className={clsx(styles.errorMessage, errorClassName)} />
                )}
            </div>
        );
    },
);
