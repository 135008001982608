import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PartDescription.module.scss';

type PartDescriptionProps = {
    description: string;
    shortDescription: string;
    readMore: (e: any) => void;
};

export const PartDescription: React.FC<PartDescriptionProps> = ({ shortDescription, description, readMore }) => {
    const showMore = description && description?.length > shortDescription?.length;
    const { t } = useTranslation();
    return (
        <div className={styles.shortDescription}>
            <p>{shortDescription} </p>
            {showMore && (
                <span
                    className={styles.read_more}
                    onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => readMore(e)}
                >
                    {t('readMore')}
                </span>
            )}
        </div>
    );
};
