import clsx from 'clsx';
import React from 'react';

import styles from './ErrorMessage.module.scss';

interface Props {
    message: string;
    className?: string;
}

export const ErrorMessage: React.FC<Props> = ({ message, className }) => {
    return <div className={clsx(styles.message, className)}>{message}</div>;
};
