import { gql } from '@apollo/client';

export const SERVICE_UPGRADE = gql`
    query ServiceUpgrade($channel: String, $language: LanguageCodeEnum!) {
        categories(first: 100, filter: { isUpsell: true }) {
            edges {
                node {
                    name
                    description
                    id
                    translation(languageCode: $language) {
                        name
                        description
                    }
                    products(first: 100, channel: $channel) {
                        edges {
                            node {
                                name
                                id
                                description
                                translation(languageCode: $language) {
                                    name
                                    description
                                }
                                defaultVariant {
                                    id
                                    pricing {
                                        discount {
                                            net {
                                                amount
                                                currency
                                            }
                                            gross {
                                                amount
                                                currency
                                            }
                                        }
                                        price {
                                            net {
                                                amount
                                                currency
                                            }
                                            gross {
                                                amount
                                                currency
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
