import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { InlineWidget } from 'react-calendly';
import { Icon } from 'shared/components/ui/Icon/Icon';
import ConfiguratorStore from 'stores/Configurator.store';

import { ContactModalContext, ContactModalScreen } from '../../stores/ContactModal.store';

import styles from './ContactCalendar.module.scss';

export const ContactCalendar = observer(() => {
    const contactModalStore = useContext(ContactModalContext);
    const configuratorStore = useContext(ConfiguratorStore);

    return (
        <div>
            <Icon
                name="arrow_left"
                className={styles.backIcon}
                onClick={() => (contactModalStore.currentScreen = ContactModalScreen.START)}
            />
            <InlineWidget
                url="https://calendly.com/konfigurator/30min"
                styles={{ padding: 0, margin: 0, height: 650, overflowY: 'hidden' }}
                prefill={{ customAnswers: { a2: configuratorStore.shareableID } }}
            />
        </div>
    );
});
