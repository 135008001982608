import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Loader } from 'shared/components/ui';
import CheckoutStore from 'stores/Checkout.store';

import { FinanceFail } from './_components/FinanceFail/FinanceFail';
import { FinanceSuccess } from './_components/FinanceSuccess/FinanceSuccess';
import styles from './FinanceStatus.module.scss';

export const FinanceStatus = observer(() => {
    const { id } = useParams();
    const checkoutStore = useContext(CheckoutStore);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const loadCheckout = async () => {
            try {
                await checkoutStore.getCheckout(id);
                if (checkoutStore.data) {
                    // if (checkoutStore.data.financingStatus === 'success') {
                    // }
                    await checkoutStore.checkoutComplete(checkoutStore.data.id);
                } else {
                    navigate('/not-found', { replace: true });
                }
            } catch (e) {
                console.error(e);
            } finally {
                setLoading(false);
            }
        };

        if (id && !checkoutStore.data) loadCheckout();
    }, [id, checkoutStore, navigate]);

    if (loading)
        return (
            <div className={styles.financeStatus}>
                <Loader />
            </div>
        );

    return (
        <div className={styles.financeStatus}>
            {checkoutStore.data?.financingStatus === 'success' ? (
                <FinanceSuccess data={checkoutStore.data} order={checkoutStore.orderData.order} />
            ) : (
                <FinanceFail data={checkoutStore.data} order={checkoutStore.orderData.order} />
            )}
        </div>
    );
});
