import clsx from 'clsx';
import React from 'react';

import { IconsId } from '../../../types/icons';
import { Icon } from '../Icon/Icon';
import { Loader } from '../Loader/Loader';

import styles from './Button.module.scss';

type ButtonProps = {
    className?: string;
    htmlType?: 'button' | 'submit';
    type?: 'primary' | 'primaryDark' | 'secondary' | 'ghost' | 'text';
    withArrow?: boolean;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    isLoading?: boolean;
    isDisabled?: boolean;
    children?: React.ReactNode;
    preIcon?: IconsId;
    postIcon?: IconsId;
    loaderClassName?: string;
    loadingText?: string;
};

export const Button: React.FC<ButtonProps> = ({
    className,
    isDisabled,
    htmlType = 'button',
    type = 'primary',
    onClick,
    isLoading = false,
    loaderClassName,
    loadingText,
    children,
    preIcon,
    postIcon,
}) => {
    const classes = clsx(styles.button, className, styles[type], { [styles.isDisabled]: isDisabled });
    const loaderClasses = clsx(
        styles.loader,
        (type === 'ghost' || type === 'secondary') && styles.darkLoader,
        loaderClassName,
    );
    return (
        <button className={classes} type={htmlType} disabled={isDisabled} onClick={isLoading ? undefined : onClick}>
            {isLoading ? (
                <>
                    {loadingText}
                    <Loader className={loaderClasses} />
                </>
            ) : (
                <>
                    {preIcon && <Icon name={preIcon} className={styles.preIcon} />}
                    {children}
                    {postIcon && <Icon name={postIcon} className={styles.postIcon} />}
                </>
            )}
        </button>
    );
};
