import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Icon, Sidebar } from 'shared/components/ui';
import CheckoutStore from 'stores/Checkout.store';
import ConfiguratorStore from 'stores/Configurator.store';

import MockedBikeImg from '../../assets/images/default-part-image-popup.png';
import { Loader } from '../../shared/components/ui/Loader/Loader';
import { RichText } from '../../shared/components/ui/RIchText/RichText';
import { DeviceDetectorContext } from '../../shared/providers/DeviceDetector';

import styles from './BikeModel.module.scss';

type PreConfiguratorProps = {};

export const BikeModel: FC<PreConfiguratorProps> = observer(() => {
    const store = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const { device } = useContext(DeviceDetectorContext);
    const { category } = useParams();
    const { t } = useTranslation();
    const isMobile = device === 'small-tablet' || device === 'mobile';
    const [showMore, setShowMore] = useState(false);

    const configuratorCover = store.selectedBike?.media[0]
        ? `${store.selectedBike?.media[0].url}?trim=color&trim-tol=50`
        : MockedBikeImg;

    // LOAD DATA ON PAGE LOAD
    useEffect(() => {
        if (category && store.selectedParts.length === 0) store.getBikeCategory(category);
        return () => {
            store.category.name = '';
        };
    }, [category, store, checkoutStore]);

    useEffect(() => {
        if (store.selectedBike) {
            store.resetStore();
            checkoutStore.resetCheckoutStore();
        }
        checkoutStore.clearTokens();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Check if the bike is a twinner bike
    useEffect(() => {
        if (store.selectedBike) {
            store.isTwinner = store.selectedBike.name.toLowerCase().includes('twinner');
        }
        return () => {
            store.isTwinner = false;
        };
    }, [store.selectedBike, store]);

    if (store.bikeModelPageLoading) {
        return (
            <div className={styles.loader}>
                <Loader />
            </div>
        );
    }

    if (!store.selectedBike) {
        return (
            <div className={styles.no_products}>
                <h3>{t('noProductsInCategory')}</h3>
            </div>
        );
    }

    return (
        <div className={styles.bike_model}>
            <div className={styles.main_container}>
                <h1 className={styles.title}>{store.category?.name || ''}</h1>

                <div className={styles.imageWrapper}>
                    <div
                        className={styles.bikeImage}
                        style={{
                            background: `center / contain no-repeat url('${configuratorCover}')`,
                        }}
                    ></div>
                </div>

                <div className={styles.bike_description}>
                    {isMobile ? (
                        <>
                            <div className={styles.bike_glance}>
                                <h3>
                                    {store.selectedBike?.translation?.bikeDescriptionTitle ||
                                        store.selectedBike?.bikeDescriptionTitle ||
                                        t('bikeAtAGlance')}
                                </h3>
                                {store.selectedBike?.description && (
                                    <div className={styles.mobile_desc}>
                                        <RichText
                                            jsonStringData={
                                                store.selectedBike.translation?.description ||
                                                store.selectedBike.description
                                            }
                                            isMobile={!showMore}
                                        />
                                        {!showMore && (
                                            <span className={styles.show_more} onClick={() => setShowMore(true)}>
                                                {t('moreButton')}
                                            </span>
                                        )}
                                    </div>
                                )}

                                {showMore && store.selectedBike && (
                                    <div className={styles.specifications}>
                                        <USP usp={store.selectedBike.translation?.usp1 || store.selectedBike.usp1} />
                                        <USP usp={store.selectedBike.translation?.usp2 || store.selectedBike.usp2} />
                                        <USP usp={store.selectedBike.translation?.usp3 || store.selectedBike.usp3} />
                                        {showMore && (
                                            <span className={styles.show_more} onClick={() => setShowMore(false)}>
                                                {t('lessButton')}
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.bike_glance}>
                                <h3>
                                    {store.selectedBike?.translation?.bikeDescriptionTitle ||
                                        store.selectedBike?.bikeDescriptionTitle ||
                                        t('bikeAtAGlance')}
                                </h3>
                                {store.selectedBike?.description && (
                                    <RichText
                                        jsonStringData={
                                            store.selectedBike.translation?.description ||
                                            store.selectedBike.description
                                        }
                                    />
                                )}
                            </div>
                            {store.selectedBike && (
                                <div className={styles.specifications}>
                                    <USP usp={store.selectedBike.translation?.usp1 || store.selectedBike.usp1} />
                                    <USP usp={store.selectedBike.translation?.usp2 || store.selectedBike.usp2} />
                                    <USP usp={store.selectedBike.translation?.usp3 || store.selectedBike.usp3} />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <Sidebar type="bikeModel" bikeModelData={store.categoryProducts} />
        </div>
    );
});

type USPProps = {
    usp: string;
};
const USP: React.FC<USPProps> = ({ usp }) => {
    if (!usp) return null;
    return (
        <div className={styles.spec} key={`${usp}`}>
            <Icon name="arrow_alt" />
            <p>{usp}</p>
        </div>
    );
};
