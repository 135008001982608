import { gql } from '@apollo/client';

import { PARTS_FIELD, PRODUCT_FIELDS } from '../fragments/configurator';

export const BIKE_PRECONFIGURATIONS = gql`
    query Configurations($template: ID!) {
        configurations(
            first: 100
            filter: { template: $template, featured: true, published: true, active: true }
            sortBy: { field: SORT_ORDER, direction: ASC }
        ) {
            edges {
                node {
                    id
                    sid
                    cover
                    title
                    description
                    frameSize
                    sittingPosition
                    parts
                    default
                    featured
                }
            }
        }
    }
`;

export const PARTS = gql`
    query Parts($productId: ID!, $language: LanguageCodeEnum = DE) {
        product(id: $productId) {
            ${PARTS_FIELD}
            optionsByType(languageCode: $language) {
                values {
                    referenceProduct {
                        translation(languageCode: $language) {
                            name
                            description
                            shortTitle
                            shortDescription
                        }
                        variants {
                            translation(languageCode: $language) {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const BIKE_PRECONFIGURATION = gql`
    query Configuration($id: ID!) {
        configuration(id: $id) {
            id
            title
            description
            cover
            frameSize
            sittingPosition
            parts
            colors
            default
            featured
        }
    }
`;

export const PRODUCT_PART_MODEL = gql`
    query productPartModels($id: ID!, $size: ID!) {
        productPartModels(id: $id, size: $size) {
            id
            name
            productModel
            modelId
            productType {
                id
                name
                isBikeType
                metadata {
                    key
                    value
                }
                conflictingProductTypes {
                    id
                    name
                }
            }
            slots {
                id
                productType {
                    id
                    name
                }
                transformMatrix
                size {
                    id
                }
                modelReferences {
                    id
                    file
                }
            }
        }
    }
`;

export const PRODUCT = gql`
    query ProductOnRefresh($id: ID, $language: LanguageCodeEnum = DE) {
        product(id: $id) {
            ${PRODUCT_FIELDS}
        }
    }
`;

export const GET_BIKE_BY_SID = gql`
    query Configuration($sid: String, $language: LanguageCodeEnum!) {
        configuration(sid: $sid) {
            id
            template {
                ${PRODUCT_FIELDS}
                ${PARTS_FIELD}
            }
            title
            description
            cover
            frameSize
            sittingPosition
            parts
            colors
            default
            featured
            referralEmail
            referralOrders
        }
    }

`;

export const GET_AR_BY_ID = gql`
    query GetFile($id: ID!) {
        arModel(id: $id) {
            id
            file
        }
    }
`;
