import { gql } from '@apollo/client';

export const SEND_CONFIGURATION_TO_EMAIL = gql`
    mutation RequestConfigurationSend($id: ID!, $emails: [String]!, $channel: String) {
        requestConfigurationSend(id: $id, emails: $emails, channel: $channel) {
            errors {
                field
                message
            }
        }
    }
`;

export const CREATE_CONFIGURATION = gql`
    mutation CreateConfigurationSID(
        $template: ID
        $title: String
        $frameSize: String
        $sittingPosition: String
        $parts: GenericScalar
        $colors: GenericScalar
    ) {
        configurationCreate(
            input: {
                title: $title
                template: $template
                frameSize: $frameSize
                sittingPosition: $sittingPosition
                parts: $parts
                colors: $colors
                published: false
            }
        ) {
            configuration {
                id
                sid
                parts
            }
            errors {
                field
                message
            }
        }
    }
`;

export const CONFIGURATION_UPDATE = gql`
    mutation configurationUpdate($id: ID!, $input: ConfigurationInput!) {
        configurationUpdate(id: $id, input: $input) {
            configuration {
                id
                sid
            }
        }
    }
`;

export const SAVE_CONFIGURATION_IMAGE = gql`
    mutation SaveConfigurationImage($id: ID!, $image: Upload) {
        configurationCoverUpload(id: $id, base64String: $image) {
            configuration {
                id
                cover
            }
            errors {
                field
                message
            }
        }
    }
`;

export const UPLOAD_AR_FILE = gql`
    mutation UploadARFile($input: ArModelInput!) {
        arModelCreate(input: $input) {
            arModel {
                id
                file
            }
            errors {
                field
                message
            }
        }
    }
`;
