import clsx from 'clsx';
import React, { FC } from 'react';

import styles from './Loader.module.scss';

type LoaderType = {
    className?: string;
};

export const Loader: FC<LoaderType> = ({ className }) => {
    return <div className={clsx(styles.loader, className)} />;
};
