import { RefObject, useEffect } from 'react';

type Event = MouseEvent | TouchEvent;

const useClickOutside = <T extends HTMLElement = HTMLElement>(
    ref: RefObject<T>,
    handler: (event: Event) => void,
    ignoreClickOutside: boolean = false,
) => {
    useEffect(() => {
        const listener = (event: Event) => {
            if (ignoreClickOutside) return;
            const el = ref?.current as any;

            // Do nothing if clicking ref's element or descendent elements
            if (el && !el?.contains(event.target as Node)) {
                handler(event);
            }
        };
        document.addEventListener('click', listener, true);

        return () => {
            document.removeEventListener('click', listener, true);
        };
    }, [ref, handler, ignoreClickOutside]);
};

export default useClickOutside;
