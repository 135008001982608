import clsx from 'clsx';
import React, { useState } from 'react';

import { Portal } from '../Portal/Portal';

import styles from './Tooltip.module.scss';

interface Props {
    label: string | React.ReactNode;
    children: React.ReactNode;
    tooltipWrapperClassName?: string;
    tooltipClassName?: string;
}

export const Tooltip: React.FC<Props> = ({ label, children, tooltipWrapperClassName, tooltipClassName }) => {
    const [coords, setCoords] = useState({ top: 0, left: 0 });
    const [showTooltip, setShowTooltip] = useState(false);

    const setCoordinates = (e: React.MouseEvent) => {
        const element = e.currentTarget as HTMLDivElement;
        const rect = element.getBoundingClientRect();
        setCoords({
            top: rect.y + window.scrollY,
            left: rect.x + rect.width / 2,
        });
        setShowTooltip(true);
    };

    return (
        <div
            className={clsx(styles.tooltipWrapper, showTooltip && 'active', tooltipWrapperClassName)}
            onClick={e => setCoordinates(e)}
            onMouseEnter={e => setCoordinates(e)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {children}
            {showTooltip && (
                <Portal>
                    <div
                        className={clsx(styles.tooltip, tooltipClassName)}
                        style={{
                            top: coords?.top || 0,
                            left: coords?.left || 0,
                        }}
                    >
                        {typeof label === 'string' ? (
                            <p className={styles.tooltipText}>{label}</p>
                        ) : (
                            <div className={styles.tooltipText}>{label}</div>
                        )}
                    </div>
                </Portal>
            )}
        </div>
    );
};
