import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useRef } from 'react';
import ConfiguratorStore from 'stores/Configurator.store';

import useClickOutside from '../../../providers/useClickOutside';

import styles from './MenuPopup.module.scss';

type MenuPopupProps = {
    width: number | string;
    maxHeight?: number;
    className?: string;
    arrowClassName?: string;
    bottomArrow?: boolean;
    children?: React.ReactNode;
    onClose?: (e: Event) => void;
};

export const MenuPopup: FC<MenuPopupProps> = observer(
    ({ width, maxHeight, className, arrowClassName, bottomArrow, onClose = () => {}, children }) => {
        const configuratorStore = useContext(ConfiguratorStore);

        const popupRef = useRef<HTMLDivElement>(null);

        useClickOutside(popupRef, e => onClose(e), configuratorStore.menuPopupIgnoreClickOutside);

        return (
            <div className={styles.wrapper} ref={popupRef}>
                <div className={clsx(styles.overflow_wrapper, className)} style={{ width, maxHeight }}>
                    <div className={styles.menu_popup}>{children}</div>
                </div>
                <div className={clsx(styles.arrow, bottomArrow && styles.arrow_bottom, arrowClassName)} />
            </div>
        );
    },
);
