export const PRODUCT_FIELDS = `
    id
    name
    description
    configuratorCover
    isEBike
    configuratorPriceLabel
    isCustomColorActive
    isAvailable
    bikeDescriptionTitle
    weight {
        value
        unit
    }
    translation(languageCode: $language) {
        bikeDescriptionTitle
        description
        name
        usp1
        usp2
        usp3
        shortTitle
        shortDescription
    }
    customColorVariantsByGroup(languageCode: $language) {
        group
        key
        values {
            code
            name
            variant { 
                id
            }
        }
    }
    media {
        id
        url
    }
    attributes {
        attribute {
            slug
        }
        values {
            id
            name
        }
    }
    configuratorPrice {
        currency
        amount
    }
    collections {
        name
    }
    sittingPositions {
        id
        name
        note
    }
    variants {
        id
        name
        media {
            id
            url
            configuratorOnly
        }
        weight {
            value
            unit
        }
        attributes {
            attribute {
                name
                slug
            }
            values {
                id
                name
                value
            }
        }
    }
    usp1
    usp2
    usp3
    sittingPositions {
        name
        id
        note
    }
`;

export const PARTS_FIELD = `
    requiredTypes {
        id
        name
    }
    configuratorModels {
        id
    }
    optionsByType(languageCode: $language) {
        type
        typeId
        group
        image
        conflictingProductTypes {
            id
            name
        }
        values {
            id
            name
            referenceProduct {
                id
                name
                description
                no3dRequired
                longDescription
                shortTitle
                shortDescription
                rating
                requiredProducts {
                    id
                    name
                }
                conflictingProducts {
                    id
                    name
                }
                productType {
                    id
                    name
                    conflictingProductTypes {
                        id
                        name
                    }
                    requiredTypes {
                        id
                        name
                    }
                }
                weight {
                    value
                    unit
                }
                media {
                    url
                    configuratorOnly
                }
                variants {
                    id
                    encryptedStock
                    weight {
                        value
                        unit
                    }
                    media {
                        url
                        configuratorOnly
                        id
                    }
                    pricing {
                        onSale
                        price {
                            net {
                                amount
                                currency
                            }
                            gross {
                                amount
                                currency
                            }
                        }
                        discount {
                            net {
                                amount
                                currency
                            }
                            gross {
                                amount
                                currency
                            }
                        }
                        priceUndiscounted {
                            net {
                                amount
                                currency
                            }
                            gross {
                                amount
                                currency
                            }
                        }
                    }
                    
                }
                
            }
        }
    }
    attributes {
        attribute {
            name
            id
        }
        values {
            name
        }
    }
`;
