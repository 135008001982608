import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { Button, Icon } from 'shared/components/ui';
import { DeviceDetectorContext } from 'shared/providers/DeviceDetector';
import CheckoutStore from 'stores/Checkout.store';

import { localizePrice } from '../../../../shared/providers/utils';
import { LinesByVariantGroup, LinesType } from '../../../../shared/types/checkout';
import { gtmEvents } from '../../../../shared/types/gtmEvents';
import { Accordion } from '../Accordion/Accordion';

import { Coupon } from './_components/Coupon/Coupon';
import { MetadataSection } from './_components/MetadataSection/MetadataSection';
import styles from './CheckoutSidebar.module.scss';

type CheckoutSidebarProps = {};

export const CheckoutSidebar: FC<CheckoutSidebarProps> = observer(() => {
    const { device } = useContext(DeviceDetectorContext);
    const checkoutStore = useContext(CheckoutStore);

    const voucherCode = checkoutStore.data?.voucherCode;
    const navigate = useNavigate();
    const location = useLocation();

    const [isExpanded, setIsExpanded] = useState(false);
    const [groups, setGroups] = useState<any[]>([]);
    const { t } = useTranslation();
    const SID = checkoutStore.data?.metadata.find(d => d.key === 'sid')?.value;

    const configPrice = localizePrice(checkoutStore.totalPrice / 24);

    useEffect(() => {
        // Set bycicle type to be the first in the list
        if (checkoutStore.data?.linesByVariantGroup?.length) {
            const index = checkoutStore.data?.linesByVariantGroup.findIndex((d: any) => d.group === 'BICYCLE');
            if (index !== -1) {
                checkoutStore.data?.linesByVariantGroup.unshift(
                    checkoutStore.data?.linesByVariantGroup.splice(index, 1)[0],
                );
            }
            setGroups(checkoutStore.data?.linesByVariantGroup);
        }
    }, [checkoutStore.data?.linesByVariantGroup]);

    const handleEditClick = async () => {
        checkoutStore.resetCheckoutStore();

        window.dataLayer.push({
            event: gtmEvents.CHECKOUT_EDIT,
        });

        await checkoutStore.sendDataToCustomerIo(gtmEvents.CHECKOUT_EDIT);

        if (location.key === 'default') {
            navigate(`${AppRoutes.configurator}/${SID}`);
        } else {
            navigate(-1);
        }
    };

    return (
        <Accordion
            expanded={device === 'desktop' ? true : isExpanded}
            className={styles.accordion}
            titleComponent={
                <div className={styles.headingWrapper}>
                    <div className={styles.heading}>
                        <div className={styles.titleWrapper}>
                            <h3>{t('order_summary')}</h3>
                        </div>
                        {!isExpanded && (
                            <h3 className={styles.headerPrice}>
                                {checkoutStore.data?.totalPrice?.currency} {configPrice}
                                <span className={styles.per_month}> / {t('month')}</span>
                            </h3>
                        )}
                    </div>
                    <div className={clsx(styles.heading, styles.mobileHeading, isExpanded && styles.expanded)}>
                        {SID && (
                            <div className={styles.edit} onClick={() => handleEditClick()}>
                                {t('editConfigurationButton')}
                            </div>
                        )}
                        <div className={styles.showMore} onClick={() => setIsExpanded(!isExpanded)}>
                            <Button type="text">{t(isExpanded ? 'showLessText' : 'showMoreText')}</Button>
                            <Icon name="arrow_down" />
                        </div>
                    </div>
                </div>
            }
            contentComponent={
                <div className={styles.content}>
                    <div className={styles.summary}>
                        <div className={styles.parts}>
                            <MetadataSection />
                            {groups?.map((item: LinesByVariantGroup) => {
                                if (item.lines.length === 0 || !item.group) return '';

                                return (
                                    <div key={item.group} className={styles.part_section}>
                                        <h6 className={styles.group}>{t(item.group)}</h6>
                                        {item.lines?.map((part: LinesType) => {
                                            return (
                                                <div key={part.id} className={styles.variant_wrapper}>
                                                    <h6 className={styles.variant_name}>
                                                        {part.variant.product.translation?.name ||
                                                            part.variant.product.name}
                                                    </h6>
                                                    <h6 className={styles.price}>
                                                        {part.totalPrice.gross.currency}{' '}
                                                        {localizePrice(part.totalPrice.gross.amount)}
                                                    </h6>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.divider} />
                        <div className={styles.footer_line}>
                            <h6>{t('order_subtotal')}</h6>
                            <h6>
                                {checkoutStore.data?.subtotalPrice.gross?.currency}{' '}
                                {localizePrice(checkoutStore.data?.subtotalPrice.gross?.amount)}
                            </h6>
                        </div>
                        <div className={styles.footer_line}>
                            <h6>{t('order_checkout_shipping')}</h6>
                            <h6>
                                {checkoutStore?.data?.shippingPrice?.gross?.currency}{' '}
                                {localizePrice(checkoutStore?.data?.shippingPrice?.gross?.amount)}
                            </h6>
                        </div>
                        <div className={styles.footer_line}>
                            <h6>{t('order_tax')}</h6>
                            <h6>
                                {checkoutStore?.data?.shippingPrice?.tax?.currency}{' '}
                                {localizePrice(checkoutStore?.data?.shippingPrice?.tax?.amount)}
                            </h6>
                        </div>

                        {voucherCode && (
                            <div className={styles.footer_line}>
                                <h6>{t('order_coupon')}</h6>
                                <h6>
                                    {checkoutStore?.data?.discount?.currency}{' '}
                                    {localizePrice(checkoutStore?.data?.discount?.amount)}
                                </h6>
                            </div>
                        )}

                        <Coupon />

                        <div className={styles.total_price}>
                            <h6>{t('priceStartingFrom')}</h6>
                            <h2>
                                {checkoutStore.data?.totalPrice.currency} {configPrice}
                                <span className={styles.per_month}> / {t('month')}</span>
                            </h2>
                            <div className={styles.include_taxes}>
                                <h6>{t('totalPriceIncludeTax')}</h6>
                                <h6>
                                    {checkoutStore.data?.totalPrice.currency} {localizePrice(checkoutStore.totalPrice)}
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            }
        />
    );
});
