import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/components/ui/Icon/Icon';

import styles from './SuccessScreen.module.scss';

interface Props {
    message?: string;
}

export const SuccessScreen: React.FC<Props> = ({ message }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <Icon name="checkmark" />
            <h3>{t('contactSuccessThankYou')}</h3>
            <p>{message}</p>
        </div>
    );
};
