import clsx from 'clsx';
import React from 'react';

import styles from './Radio.module.scss';

interface Props {
    selected?: boolean;
    onClick?: (event?: React.MouseEvent) => void;
    children?: React.ReactNode;
}

export const Radio: React.FC<Props> = ({ selected = false, onClick, children }) => {
    return (
        <div className={styles.root} onClick={onClick}>
            <div className={clsx(styles.radio, selected && styles.active)}>
                <div className={styles.inner}></div>
            </div>
            {children && <label className={styles.label}>{children}</label>}
        </div>
    );
};
