import clsx from 'clsx';
import React from 'react';

import { Icon } from '../Icon/Icon';

import styles from './Rating.module.scss';

interface RatingProps {
    rating: number;
}

export const Rating: React.FC<RatingProps> = ({ rating }) => {
    const getRate = (r: any) => {
        if (r <= 1) return 1;
        if (r >= 5) return 5;
        return r;
    };
    return (
        <div className={clsx(styles.rating, styles[`rate-${getRate(rating)}`])}>
            <Icon name="star" />
            <Icon name="star" />
            <Icon name="star" />
            <Icon name="star" />
            <Icon name="star" />
        </div>
    );
};
