import { gql } from '@apollo/client';
import { ADDRESS_FIELDS, CHECKOUT_FIELDS } from 'api/fragments/checkout';

export const CREATE_CHECKOUT = gql`
    mutation checkoutCreate($input: CheckoutCreateInput!, $language: LanguageCodeEnum!) {
        checkoutCreate(input: $input) {
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CHECKOUT_LINES_UPDATE = gql`
    mutation checkoutLinesUpdate($id: ID, $lines: [CheckoutLineUpdateInput!]!, $language: LanguageCodeEnum!) {
        checkoutLinesUpdate(id: $id, lines: $lines) {
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CHECKOUT_LINES_DELETE = gql`
    mutation checkoutDelete($id: ID, $linesIds: [ID!]!) {
        checkoutLinesDelete(id: $id, linesIds: $linesIds) {
            checkout {
                id
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CHECKOUT_SHIPPING_ADDRESS_UPDATE = gql`
    mutation checkoutShippingAddressUpdate($id: ID, $shippingAddress: AddressInput!, $language: LanguageCodeEnum!) {
        checkoutShippingAddressUpdate(id: $id, shippingAddress: $shippingAddress) {
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CHECKOUT_BILLING_ADDRESS_UPDATE = gql`
    mutation checkoutBillingAddressUpdate($id: ID, $billingAddress: AddressInput!, $language: LanguageCodeEnum!) {
        checkoutBillingAddressUpdate(id: $id, billingAddress: $billingAddress) {
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CHECKOUT_DELIVERY_METHOD_UPDATE = gql`
    mutation checkoutDeliveryMethodUpdate($id: ID, $deliveryMethodId: ID!, $language: LanguageCodeEnum!) {
        checkoutDeliveryMethodUpdate(id: $id, deliveryMethodId: $deliveryMethodId) {
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CUSTOMER_CHECKOUT_ATTACH = gql`
    mutation checkoutCustomerAttach($id: ID, $language: LanguageCodeEnum!) {
        checkoutCustomerAttach(id: $id) {
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CUSTOMER_CHECKOUT_DETACH = gql`
    mutation checkoutCustomerDetach($id: ID, $language: LanguageCodeEnum!) {
        checkoutCustomerDetach(id: $id) {
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CHECKOUT_UPDATE_MAIL = gql`
    mutation updateMail($id: ID, $email: String!, $language: LanguageCodeEnum!) {
        checkoutEmailUpdate(id: $id, email: $email) {
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CHECKOUT_UPDATE_PHONE = gql`
    mutation checkoutPhoneUpdate($id: ID, $phone: String!, $language: LanguageCodeEnum!) {
        checkoutPhoneUpdate(id: $id, phone: $phone) {
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const ACCOUNT_ADDRESS_CREATE = gql`
    mutation accountAddressCreate($input: AddressInput!) {
        accountAddressCreate(input: $input) {
            address {
                ${ADDRESS_FIELDS}
            }
            errors {
                field
                message
                code
                addressType
            }
        }
    }
`;

export const ACCOUNT_ADDRESS_UPDATE = gql`
    mutation accountAddressUpdate($id: ID!, $input: AddressInput!) {
        accountAddressUpdate(id: $id, input: $input) {
            address {
                ${ADDRESS_FIELDS}
            }
            errors {
                field
                message
                code
                addressType
            }
        }
    }
`;

export const CHECKOUT_ADD_PROMO_CODE = gql`
    mutation checkoutAddPromoCode($id: ID, $promoCode: String!, $expertToken: String, $language: LanguageCodeEnum!){
        checkoutAddPromoCode(id: $id, promoCode: $promoCode, expertToken: $expertToken){
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CHECKOUT_REMOVE_PROMO_CODE = gql`
    mutation checkoutRemovePromoCode($id: ID!, $promoCode: String!, $language: LanguageCodeEnum!){
        checkoutRemovePromoCode(id: $id, promoCode: $promoCode){
            checkout {
                ${CHECKOUT_FIELDS}
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const PAYMENT_CREATE = gql`
    mutation checkoutPaymentCreate($id: ID, $paymentInput: PaymentInput!, $isOffer: Boolean) {
        checkoutPaymentCreate(id: $id, input: $paymentInput, isOffer: $isOffer) {
            payment {
                id
                chargeStatus
                metadata {
                    key
                    value
                }
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const PAYMENT_INTENT_UPDATE = gql`
    mutation checkoutPaymentIntentUpdate($amount: Float!, $id: ID!) {
        checkoutPaymentIntentUpdate(amount: $amount, checkoutId: $id) {
            errors {
                field
                message
                code
            }
            checkout {
                id
            }
        }
    }
`;

export const PAYMENT_COMPLETE = gql`
    mutation checkoutComplete($id: ID, $isOffer: Boolean) {
        checkoutComplete(id: $id, isOffer: $isOffer) {
            order {
                id
                userEmail
                created
                paymentStatus
                number
                isOffer
                metadata {
                    key
                    value
                }
                total {
                    gross {
                        currency
                        amount
                    }
                }
            }
            confirmationNeeded
            confirmationData
            errors {
                field
                message
                code
            }
        }
    }
`;

export const FINANCE_CREATE = gql`
    mutation bobFinanceCreate($id: ID!, $input: FinanceInput!) {
        bobFinanceCreate(checkout: $id, input: $input) {
            redirectUrl
            errors {
                field
                message
                code
            }
        }
    }
`;

export const UPDATE_IMAGE_CHECKOUT = gql`
    mutation checkoutConfigurationImageUpdate($checkoutId: ID, $image: String) {
        checkoutConfigurationImageUpdate(id: $checkoutId, base64String: $image) {
            checkout {
                configurationImage
            }
        }
    }
`;

export const UPDATE_METADATA = gql`
    mutation updateMetadata($id: ID!, $input: [MetadataInput!]!) {
        updateMetadata(id: $id, input: $input) {
            errors {
                field
                message
                code
            }
            item {
                metadata {
                    key
                    value
                }
            }
        }
    }
`;

export const CHECKOUT_EXPERT_PIN_VERIFY = gql`
    mutation checkoutExpertPinVerify($checkout: ID!, $pin: String!) {
        checkoutExpertPinVerify(checkout: $checkout, pin: $pin) {
            token
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CHECKOUT_UPLOAD_FILE = gql`
    mutation checkoutUploadFile($base64File: String!, $checkout: ID!, $fileName: String!) {
        checkoutFileAdd(base64File: $base64File, checkout: $checkout, fileName: $fileName) {
            checkout {
                files {
                    id
                    fileName
                }
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CHECKOUT_DELETE_FILE = gql`
    mutation checkoutDeleteFile($checkout: ID!, $file: ID!) {
        checkoutFileDelete(checkout: $checkout, file: $file) {
            checkout {
                files {
                    id
                    fileName
                }
            }
            errors {
                field
                message
                code
            }
        }
    }
`;

export const CUSTOMER_IO = gql`
    mutation checkoutCustomerTrack($anonymous: Boolean!, $customerId: String!, $event: String!, $actionItem: String) {
        checkoutCustomerTrack(anonymous: $anonymous, customerId: $customerId, event: $event, actionItem: $actionItem) {
            success
        }
    }
`;

export const EXPERT_VOUCHERS = gql`
    query expertVouchers($checkoutId: ID!, $token: String!) {
        expertVouchers(checkoutId: $checkoutId, token: $token, first: 100) {
            edges {
                node {
                    name
                    code
                }
            }
        }
    }
`;
