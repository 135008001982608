import clsx from 'clsx';
import React, { FC } from 'react';

import { Icon } from '../Icon/Icon';

import styles from './Modal.module.scss';

type ModalProps = {
    isOpen: boolean;
    onClose: () => void;
    className?: string;
    overlayClassName?: string;
    closeClassName?: string;
    maxWidth?: number | string;
    darkMode?: boolean;
    position?: string;
    children?: React.ReactNode;
    uncloseable?: boolean;
    clickOutsideToClose?: boolean;
};

export const Modal: FC<ModalProps> = ({
    isOpen,
    onClose,
    className,
    overlayClassName,
    closeClassName,
    maxWidth,
    darkMode,
    children,
    uncloseable,
    clickOutsideToClose = true,
}) => {
    if (!isOpen) return null;

    return (
        <div
            className={clsx(styles.modal_overlay, overlayClassName)}
            onClick={e => {
                e.stopPropagation();
                if (!uncloseable && clickOutsideToClose) onClose();
            }}
        >
            <div
                className={clsx(styles.modal, className, darkMode && styles.dark)}
                style={{ maxWidth }}
                onClick={event => event.stopPropagation()}
            >
                {!uncloseable && (
                    <Icon
                        name="close"
                        className={clsx(styles.close_icon, closeClassName, darkMode && styles.dark_icon)}
                        onClick={e => {
                            e.stopPropagation();
                            onClose();
                        }}
                    />
                )}
                {children}
            </div>
        </div>
    );
};
