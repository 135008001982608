import clsx from 'clsx';
import React, { FC, useContext, useState } from 'react';

import { Icon, Radio } from '../../../../shared/components/ui';
import { RichText } from '../../../../shared/components/ui/RIchText/RichText';
import { DeviceDetectorContext } from '../../../../shared/providers/DeviceDetector';
import { localizePrice } from '../../../../shared/providers/utils';

import styles from './UpsellItem.module.scss';

type UpsellItemProps = {
    name?: string;
    id?: string;
    price?: any;
    description?: string;
    onSelect: (id: string) => void;
    active?: boolean;
    isNone?: boolean;
    discount?: any;
};

export const UpsellItem: FC<UpsellItemProps> = ({ name, id, description, onSelect, active, price, discount }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { device } = useContext(DeviceDetectorContext);

    // if (isNone) {
    //     return (
    //         <div className={clsx(styles.upsell_item_empty, active && styles.active)}>
    //             <div className={styles.empty_section}>
    //                 <Radio
    //                     selected={active}
    //                     onClick={event => {
    //                         event?.stopPropagation();
    //                         onSelect('none');
    //                     }}
    //                 />
    //                 <div className={styles.name_price_none}>
    //                     <h5 className={styles.name}>None</h5>
    //                     <div className={styles.price}>CHF {localizePrice(0)}</div>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }

    return (
        <div
            className={clsx(styles.upsell_item, active && styles.active, !description && styles.no_desc)}
            onClick={() => {
                if (!description) return;
                setIsExpanded(!isExpanded);
            }}
        >
            <div className={styles.name_price_container}>
                <div className={styles.name_wrapper}>
                    <Radio
                        selected={active}
                        onClick={event => {
                            event?.stopPropagation();
                            if (!id) return;
                            onSelect(id);
                        }}
                    />
                    <h5 className={styles.name}>{name}</h5>
                </div>
                <div className={clsx(styles.price, device === 'mobile' && styles.mobile_price)}>
                    {discount && (
                        <div className={styles.discount}>
                            {discount?.gross?.currency} {localizePrice(discount?.gross?.amount)}
                        </div>
                    )}
                    {price.currency} {localizePrice(price?.amount)}
                </div>
            </div>
            {device === 'mobile' && (
                <div className={styles.arrow_container}>
                    {device === 'mobile' && <h6 className={styles.more}>More</h6>}
                    <Icon name={'arrow_down'} className={clsx(styles.arrow, isExpanded && styles.rotate)} />
                </div>
            )}

            {description && device !== 'mobile' && (
                <Icon name={'arrow_down'} className={clsx(styles.arrow, isExpanded && styles.rotate)} />
            )}

            <div className={clsx(styles.wrap, isExpanded && styles.opened)}>
                <div className={styles.desc}>
                    <RichText jsonStringData={description} />
                </div>
            </div>
        </div>
    );
};
