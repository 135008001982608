import { gql, useQuery } from '@apollo/client';

import { PRODUCT_FIELDS } from '../fragments/configurator';

interface ConfiguratorPrice {
    currency: string;
    amount: number;
}

interface ConfiguratorCategory {
    id: string;
    name: string;
    defaultSelected: {
        name: string;
    };
}

interface CollectionCategory {
    id: string;
    name: string;
}

interface BikeModelNode {
    id: string;
    name: string;
    description: string;
    configuratorCover?: string | null;
    configuratorPriceLabel?: string | null;
    configuratorPrice: ConfiguratorPrice;
    configuratorCategory: ConfiguratorCategory;
    collections: CollectionCategory[];
    usp1: string;
    usp2: string;
    usp3: string;
    isEBike: boolean;
}

export interface BikeModelEdges extends BikeModelNode {}

export interface BikeModelResp {
    products?: {
        edges?: BikeModelEdges[];
    };
    category?: {
        name?: string;
    };
}

interface BikeModelVars {
    first: number;
    filter: {
        configuratorCategory: string;
    };
}

export const BIKE_MODEL = gql`
    query BikeModel($category: ID!, $channel: String, $language: LanguageCodeEnum = DE) {
        products(first: 100, channel: $channel, filter: { collections: [$category] }) {
            edges {
                node {
                    ${PRODUCT_FIELDS}
                }
            }
        }
        collection(id: $category) {
            name
        }
    }
`;

export const useBikeModelQuery = (variables: any) => {
    return useQuery<BikeModelResp, BikeModelVars>(BIKE_MODEL, variables);
};
