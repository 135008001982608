import clsx from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { parseEditorJSData } from '../../../providers/utils';

import styles from './RichText.module.scss';

type RichTextProps = {
    jsonStringData?: string;
    isMobile?: boolean;
    className?: string;
    readMore?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
    isVariant?: boolean;
    hasLongDesc?: string;
};

export const RichText: FC<RichTextProps> = ({
    jsonStringData,
    className,
    isMobile,
    readMore = () => {},
    hasLongDesc,
    isVariant = false,
}) => {
    const data = parseEditorJSData(jsonStringData);
    const { t } = useTranslation();

    if (!data) return null;

    const getElement = (el: string, data: any, blockId: number) => {
        switch (el) {
            case 'paragraph':
                return (
                    <div key={data.text} className={styles.variant_desc}>
                        <p
                            dangerouslySetInnerHTML={{ __html: isVariant ? data.text.substring(0, 100) : data.text }}
                            className={clsx(isVariant && hasLongDesc && styles.product_variant_desc)}
                        />
                        {isVariant && hasLongDesc && (
                            <span
                                className={styles.read_more}
                                onClick={(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => readMore(e)}
                            >
                                {t('readMore')}
                            </span>
                        )}
                    </div>
                );
            case 'header':
                return <h1 key={data.text} dangerouslySetInnerHTML={{ __html: data.text }} />;
            case 'list':
                return (
                    <ol key={blockId}>
                        {data.items.map((i: any) => (
                            <li key={i} dangerouslySetInnerHTML={{ __html: i }} />
                        ))}
                    </ol>
                );
            default:
                return <p key={data} dangerouslySetInnerHTML={{ __html: data }} />;
        }
    };

    if (isMobile)
        return (
            <p
                className={clsx(styles.rich_text, className)}
                dangerouslySetInnerHTML={{ __html: `${data.blocks[0].data?.text?.substring(0, 140)}...` }}
            />
        );

    return (
        <div className={clsx(styles.rich_text, className)}>
            {data.blocks.map((block: any) => getElement(block.type, block.data, block.id))}
        </div>
    );
};
