export enum gtmEvents {
    // BIKES SELECTION
    BIKE_CATEGORY = 'Bike category',
    BIKE_MODEL = 'Bike model',

    // CONFIGURATOR
    BIKE_COLOR = 'Bike color',
    BIKE_CUSTOM_COLOR = 'Bike custom color',
    BIKE_COLOR_TYPE = 'Bike color type',
    BIKE_SIZE = 'Bike size',
    BIKE_SIZE_MODAL = 'Bike size modal',
    BIKE_SITTING_POSITION = 'Bike sitting position',
    BIKE_PRECONFIGURATION = 'Bike preconfiguration',
    BIKE_PRECONFIGURATION_MODAL = 'Bike preconfiguration modal',
    BIKE_PART_CHANGE = 'Bike part change',
    BIKE_LIGHTS_ON = 'Bike lights on',
    BIKE_LIGHTS_OFF = 'Bike lights off',
    BIKE_AR_BUTTON = 'Bike AR view',
    BIKE_CAMERA_RESET = 'Bike camera reset',
    BIKE_CAMERA_LEFT = 'Bike camera left view',
    BIKE_CAMERA_RIGHT = 'Bike camera right view',
    BIKE_CAMERA_FRONT = 'Bike camera front view',
    BIKE_CAMERA_BACK = 'Bike camera back view',
    BIKE_EMAIL_MODAL = 'Bike email modal',
    BIKE_EMAIL_SHARE = 'Bike email share',
    BIKE_SID_MODAL = 'Bike sharable id modal',
    BIKE_SID_COPY = 'Bike sharable id copy',
    BIKE_LEAVE_CONFIGURATOR_POPUP = 'Bike leave configurator popup',
    BIKE_LEAVE_CONFIGURATOR_CONFIRM = 'Bike leave configurator confirm',
    BIKE_LEAVE_CONFIGURATOR_CANCEL = 'Bike leave configurator cancel',

    // CHECKOUT
    CHECKOUT_CREATE = 'Checkout create',
    CHECKOUT_EDIT = 'Checkout edit',

    // SERVICE UPGRADE
    UPSELL_ITEM_SELECT = 'Service upgrade item select',

    // CONTACT SUPPORT
    CONTACT_SUPPORT = 'Contact support',
    CONTACT_FORM_OPTION = 'Contact form option',
    CONTACT_FORM_EMAIL = 'Contact form email',
    CONTACT_FORM_PHONE = 'Contact form phone',

    // OTHER
    LOGO_CLICK = 'Logo click',
    BIKE_MODEL_EMAIL_POPUP = 'Email popup',
    EXIT_INTENT_POPUP = 'Exit intent popup',
    EXIT_INTENT_SAVE_CONFIG = 'Exit intent save configuration',
    EXIT_INTENT_SEND_MAIL = 'Exit intent send mail',
}
