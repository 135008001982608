import React from 'react';

import { Icon } from '../Icon/Icon';

import styles from './Chip.module.scss';

interface Props {
    label: string;
    onDelete?: () => void;
}

export const Chip: React.FC<Props> = ({ label, onDelete }) => {
    return (
        <div className={styles.root} style={onDelete && { paddingRight: 12 }}>
            <span className={styles.label}>{label}</span>
            {onDelete && <Icon name="close" className={styles.closeIcon} onClick={onDelete} />}
        </div>
    );
};
