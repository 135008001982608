import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router';

export function useAnalytics() {
    const GA_MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

    const location = useLocation();

    useEffect(() => {
        if (!GA_MEASUREMENT_ID) return;
        ReactGA.initialize(GA_MEASUREMENT_ID);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const path = location.pathname + location.search;
        ReactGA.send({ hitType: 'pageview', page: path });
    }, [location]);
}
