import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button, Loader } from 'shared/components/ui';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import CheckoutStore from 'stores/Checkout.store';

import fallbackBikeImg from '../../assets/images/mock-bike.jpg';

import styles from './OrderStatus.module.scss';

export const OrderStatus = observer(() => {
    const checkoutStore = useContext(CheckoutStore);
    const [image, setImage] = useState(null);
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        if (checkoutStore.orderData) {
            const url = checkoutStore.orderData.order?.metadata.find((o: any) => o.key === 'configurationImage')?.value;
            setImage(url ? url : fallbackBikeImg);
            setEmail(checkoutStore.orderData.order?.userEmail);
        }
    }, [checkoutStore.orderData]);

    const { t } = useTranslation();
    const { id } = useParams();

    useEffect(() => {
        async function getOrderId() {
            await checkoutStore.checkoutComplete(id);
        }

        if (localStorage.getItem(LocalStorageKey.CHECKOUT_ID)) {
            localStorage.removeItem(LocalStorageKey.CHECKOUT_ID);
        }

        getOrderId();
    }, [checkoutStore, id]);

    const goToCheckout = () => {
        const sid = checkoutStore.orderData?.order?.metadata.find((d: any) => d.key === 'sid')?.value;
        if (!sid) return;
        navigate(`${AppRoutes.configurator}/${sid}`, { replace: true });
    };

    if (!checkoutStore.orderData) {
        return (
            <div className={styles.loaderWrapper}>
                <Loader />
            </div>
        );
    }

    return (
        <div className={styles.root}>
            {image && (
                <img
                    className={styles.bikeImage}
                    src={`${image}?h=200&trim=color&trim-tol=50`}
                    alt="Screenshot of the bike"
                />
            )}
            <h1>{t('orderStatusTitle')}</h1>
            <div className={styles.text}>{t('orderStatusDescription').replace('{email}', email)}</div>
            <Button type="primary" onClick={() => goToCheckout()} className={styles.button}>
                {t('orderStatusButton')}
            </Button>
        </div>
    );
});
