import clsx from 'clsx';
import { Sidebar as BikeModelSidebar } from 'pages/BikeModel/_components/Sidebar/Sidebar';
import { ConfiguratorSidebar } from 'pages/Configurator/_components/ConfiguratorSidebar/ConfiguratorSidebar';
import { MobileTabletSidebar } from 'pages/Configurator/_components/MobileTabletSidebar/MobileTabletSidebar';
import React, { FC, useContext } from 'react';
import { useLocation } from 'react-router';

import { CheckoutSidebar } from '../../../../pages/Checkout/_components/CheckoutSidebar/CheckoutSidebar';
import { DeviceDetectorContext } from '../../../providers/DeviceDetector';

import styles from './Sidebar.module.scss';

type SidebarProps = {
    type: 'bikeModel' | 'configurator' | 'checkout';
    bikeModelData?: any;
    setUsps?: (usp: string[]) => void;
    setDescription?: (desc: string) => void;
    loading?: boolean;
};

export const Sidebar: FC<SidebarProps> = ({ type, bikeModelData, loading = false }) => {
    const { device } = useContext(DeviceDetectorContext);
    const location = useLocation();

    return (
        <div
            className={clsx(
                styles.side_bar,
                device !== 'desktop' && type === 'configurator' && styles.mobile_tablet_wrapper,
                location.pathname.includes('checkout') && styles.checkout,
            )}
        >
            {type === 'bikeModel' && <BikeModelSidebar bikeModelData={bikeModelData} loading={loading} />}
            {type === 'configurator' && <>{device !== 'desktop' ? <MobileTabletSidebar /> : <ConfiguratorSidebar />}</>}
            {type === 'checkout' && <CheckoutSidebar />}
        </div>
    );
};
