import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button } from 'shared/components/ui/Button/Button';
import { Radio } from 'shared/components/ui/Radio/Radio';
import { Tooltip } from 'shared/components/ui/Tooltip/Tooltip';
import { analytics } from 'shared/helpers/analytics';
import ConfiguratorStore from 'stores/Configurator.store';

import { gtmEvents } from '../../../../../types/gtmEvents';
import { ContactModalContext, ContactModalScreen } from '../../stores/ContactModal.store';
import { ContactCover } from '../ContactCover/ContactCover';

import styles from './ContactOptions.module.scss';

enum Option {
    BIKE_QUESTION = 'BIKE_QUESTION',
    GENERAL_CONSULTATION = 'GENERAL_CONSULTATION',
    VIDEO_CALL = 'VIDEO_CALL',
    CHAT = 'CHAT',
}

export const ContactOptions: React.FC<any> = observer(({ onChat }) => {
    const contactModalStore = useContext(ContactModalContext);
    const configuratorStore = useContext(ConfiguratorStore);

    const location = useLocation();
    const isConfigurator = location.pathname.includes(AppRoutes.configurator);

    const [selectedOption, setSelectedOption] = useState<Option>();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const next = async () => {
        if (selectedOption === Option.CHAT) {
            onChat();
            return;
        }
        if (selectedOption === Option.VIDEO_CALL) {
            if (isConfigurator) {
                try {
                    setIsLoading(true);
                    await configuratorStore.saveConfiguration();
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            }

            analytics.sendEvent({ category: 'contact_modal', action: 'open_calendar' });
            window.dataLayer.push({
                event: gtmEvents.CONTACT_SUPPORT,
                contactType: selectedOption,
            });
            contactModalStore.currentScreen = ContactModalScreen.CALENDAR;
            return;
        }

        analytics.sendEvent({ category: 'contact_modal', action: 'open_contact_form' });
        window.dataLayer.push({
            event: gtmEvents.CONTACT_SUPPORT,
            contactType: selectedOption,
        });
        contactModalStore.currentScreen = ContactModalScreen.CONTACT_FORM;
    };

    return (
        <div>
            <ContactCover />

            <div className={styles.body}>
                <p className={styles.description}>{t('contactOptionsTitle')}</p>

                <div className={styles.workingHours}>
                    <div dangerouslySetInnerHTML={{ __html: t('workingHours') }} />
                </div>

                <h5 className={styles.subtitle}>{t('howCanWeHelp')} </h5>
                <div className={styles.radioOptions}>
                    {/*<Radio selected={selectedOption === Option.CHAT} onClick={() => setSelectedOption(Option.CHAT)}>*/}
                    {/*    {t('contactChatWithUs')}*/}
                    {/*</Radio>*/}
                    <Radio
                        selected={selectedOption === Option.BIKE_QUESTION}
                        onClick={() => setSelectedOption(Option.BIKE_QUESTION)}
                    >
                        {t('contactSpecificQuestion')}{' '}
                        <Tooltip label={t('contactAllDetailsTooltip')}>
                            <span className={styles.highlightedText}>{t('contactMyBike')}</span>
                        </Tooltip>
                        .
                    </Radio>
                    <Radio
                        selected={selectedOption === Option.GENERAL_CONSULTATION}
                        onClick={() => setSelectedOption(Option.GENERAL_CONSULTATION)}
                    >
                        {t('contactGeneralConsultation')}
                    </Radio>
                    <Radio
                        selected={selectedOption === Option.VIDEO_CALL}
                        onClick={() => setSelectedOption(Option.VIDEO_CALL)}
                    >
                        {t('contactMakeAnAppointment')}
                    </Radio>
                </div>

                <Button
                    postIcon="arrow_right"
                    className={styles.nextButton}
                    onClick={next}
                    isDisabled={!selectedOption}
                    isLoading={isLoading}
                >
                    {t('contactNextButton')}
                </Button>
            </div>
        </div>
    );
});
