import { ApolloLink } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { toast } from 'react-toastify';

export const responseErrorsHandlerLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(data => {
        const operationName = operation.operationName;
        const operationData = data.data;

        if (!operationData) return data;
        const operationErrors = operationData[operationName]?.errors;

        if (operationErrors?.length) {
            for (let e of operationErrors) {
                let fieldName = e?.field?.split(/(?=[A-Z])/)?.join(' ');
                fieldName = fieldName?.charAt(0)?.toUpperCase() + fieldName?.slice(1) || '';
                if (fieldName) {
                    fieldName += ': ';
                }

                toast.error(fieldName + e?.message);
            }
            throw new GraphQLError('Response handler error occurred.');
        }

        return data;
    });
});
