import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Portal } from 'shared/components/ui';

import { GuestCard } from '../ContactDetails/_components/GuestCard/GuestCard';

import styles from './GetAQuoteModal.module.scss';

type GetAQuoteModalProps = {
    data: any;
    isOpen: boolean;
    onClose: () => void;
    onRequestOffer: any;
    isLoading?: boolean;
};
export const GetAQuoteModal: React.FC<GetAQuoteModalProps> = ({ data, isOpen, onClose, onRequestOffer, isLoading }) => {
    const { t } = useTranslation();

    return (
        <Portal>
            <Modal isOpen={isOpen} onClose={() => onClose()} maxWidth={444} className={styles.quoteModal}>
                <h3>{t('quoteModalTitle')}</h3>
                <div className={styles.quoteText}>{t('quoteModalText')}</div>
                <GuestCard
                    data={data}
                    onNextStep={onRequestOffer}
                    buttonText={t('quoteModalButton')}
                    className={styles.quoteInputs}
                    isLoading={isLoading}
                    phoneRequired={true}
                />
            </Modal>
        </Portal>
    );
};
