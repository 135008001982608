import { SSO_SESSION_AUTORIZE } from 'api/mutations/auth';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppRoutes } from 'Routes';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { apolloClient } from 'shared/lib/apollo';
import CheckoutStore from 'stores/Checkout.store';

import { Loader } from '../../shared/components/ui/Loader/Loader';

import styles from './AuthSuccess.module.scss';

export const AuthSuccess = observer(() => {
    const checkoutStore = useContext(CheckoutStore);

    const { hash } = useLocation();
    const navigate = useNavigate();

    const authSuccessRedirectUrl = localStorage.getItem(LocalStorageKey.AUTH_SUCCESS_REDIRECT_URL);

    useEffect(() => {
        const idToken = hash.split('#id_token=')[1];
        ssoSessionAuthorize(idToken);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ssoSessionAuthorize = async (token: string) => {
        try {
            const response = await apolloClient.mutate({ mutation: SSO_SESSION_AUTORIZE, variables: { token } });
            const { access, refresh }: { access: string; refresh: string } = response.data.ssoSessionAuthorize;
            localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, access);
            localStorage.setItem(LocalStorageKey.REFRESH_TOKEN, refresh);
            await handlePostAuthLogic();
            await checkoutStore.fetchProductData();
            navigate(authSuccessRedirectUrl ?? AppRoutes.home, { replace: true });
        } catch (error) {
            console.error(error);
            navigate(AppRoutes.home);
        }
    };

    const handlePostAuthLogic = async () => {
        if (authSuccessRedirectUrl?.split('/')[1] === 'checkout') {
            if (!checkoutStore.data?.user) {
                await checkoutStore.connectUserToCheckout();
            }
        }
    };

    return (
        <div className={styles.loader}>
            <Loader />
        </div>
    );
});
