import { gql, useQuery } from '@apollo/client';

interface HomePageNode {
    id: string;
    name: string;
    availableFramesizes?: {
        id: string;
        name: string;
    };
    availableSittings?: {
        id: string;
        name: string;
    };
    parent?: {
        id: string;
        name: string;
    };
    backgroundImage?: {
        url: string;
    };
}

interface HomePageEdges {
    node: HomePageNode;
}

interface HomePageReps {
    collections?: {
        edges?: HomePageEdges[];
    };
}

export const HOME_PAGE = gql`
    query Collections {
        collections(first: 10, filter: { visibleOnboarding: true }) {
            edges {
                node {
                    id
                    name
                    backgroundImage {
                        url
                    }
                }
            }
        }
    }
`;

export const useCategoryQuery = () => {
    return useQuery<HomePageReps>(HOME_PAGE);
};
