import CheckoutLogoImage from 'assets/images/checkout-logo.svg';
import clsx from 'clsx';
import { CheckoutMetadataKey } from 'pages/Configurator/hooks/useUpdateCheckoutMetadata';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MetadataType } from 'shared/types/checkout';

import { DeviceDetectorContext } from '../../../../shared/providers/DeviceDetector';
import { localizePrice } from '../../../../shared/providers/utils';

import styles from './CheckoutHero.module.scss';

type CheckoutHeroProps = {
    data: any;
    showPrice?: boolean;
    className?: string;
};

export const CheckoutHero: React.FC<CheckoutHeroProps> = ({ data, showPrice, className }) => {
    const [bikeName, setBikeName] = useState('');
    const [bikeWeight, setBikeWeight] = useState(null);
    const { t } = useTranslation();
    const { device } = useContext(DeviceDetectorContext);

    const { configurationImage, linesByVariantGroup, metadata, totalPrice } = data;

    const configPrice = localizePrice(totalPrice.gross.amount / 24);

    useEffect(() => {
        const group = linesByVariantGroup.find((g: any) => g.group === 'BICYCLE');
        const name = group?.lines[0]?.variant?.product?.name;
        if (name) setBikeName(name);
    }, [linesByVariantGroup]);

    useEffect(() => {
        const weight = metadata.find((m: MetadataType) => m.key === CheckoutMetadataKey.WEIGHT);
        if (weight) setBikeWeight(weight.value);
    }, [metadata]);

    return (
        <section className={clsx(styles.checkoutHero, !showPrice && styles.remove_price, className)}>
            <div className={styles.container}>
                <img src={CheckoutLogoImage} alt="Thoemus Logo" className={styles.logo} />
                <div className={styles.title_container}>
                    <div className={styles.title}>
                        <h2>{t('checkoutHeroTitle')}</h2>
                        {bikeName && <h1>{bikeName}</h1>}
                    </div>

                    {showPrice && device === 'desktop' && (
                        <div className={styles.priceContainer}>
                            <h6 className={styles.total}>{t('priceStartingFrom')}</h6>
                            <h2 className={styles.price}>
                                {totalPrice.currency} {configPrice}
                                <span className={styles.per_month}> / {t('month')}</span>
                            </h2>
                            <div className={styles.total_price_section}>
                                <h6>{t('totalPriceIncludeTax')}: </h6>
                                <h6 className={styles.total_amount}>
                                    {totalPrice.currency} {localizePrice(totalPrice.gross.amount)}
                                </h6>
                            </div>
                        </div>
                    )}
                </div>

                {bikeWeight && (
                    <p>
                        {t('estWeight')} <span>{bikeWeight}kg</span>
                    </p>
                )}

                <div className={styles.bikeImageWrapper}>
                    <img
                        src={`${configurationImage}?h=340&trim=color&trim-tol=50`}
                        alt="Bike"
                        className={styles.bikeImage}
                    />
                </div>

                {showPrice && device !== 'desktop' && (
                    <div className={styles.priceContainer}>
                        <div>
                            <h6 className={styles.total}>{t('priceStartingFrom')}</h6>
                            <h2 className={styles.price}>
                                {totalPrice.currency} {configPrice}
                                <span className={styles.per_month}> / {t('month')}</span>
                            </h2>
                        </div>

                        <div className={styles.total_price_section}>
                            <h6>{t('totalPriceIncludeTax')}</h6>
                            <h6 className={styles.total_amount}>
                                {totalPrice.currency} {localizePrice(totalPrice.gross.amount)}
                            </h6>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};
