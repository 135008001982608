import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button } from 'shared/components/ui/Button/Button';
import { ContactModalContext, ContactModalScreen } from 'shared/components/ui/ContactModal/stores/ContactModal.store';
import { Input } from 'shared/components/ui/Input/Input';
import { Textarea } from 'shared/components/ui/Textarea/Textarea';
import { EMAIL_REGEX } from 'shared/helpers/validators';
import ConfiguratorStore from 'stores/Configurator.store';

import { gtmEvents } from '../../../../../../../types/gtmEvents';

import styles from './EmailForm.module.scss';

interface EmailFormType {
    name: string;
    email: string;
    message: string;
}

export const EmailForm = () => {
    const contactModalStore = useContext(ContactModalContext);
    const configuratorStore = useContext(ConfiguratorStore);

    const location = useLocation();
    const isConfigurator = location.pathname.includes(AppRoutes.configurator);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmailFormType>();

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: EmailFormType) => {
        try {
            setIsLoading(true);
            if (isConfigurator) {
                await configuratorStore.saveConfiguration();
            }
            await contactModalStore.sendForm({
                ...data,
                configurationSid: configuratorStore.shareableID,
                channel: process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL,
            });
            contactModalStore.successScreenMessage = t(`successScreenMessage`, { email: data.email });
            contactModalStore.currentScreen = ContactModalScreen.SUCCESS;
            window.dataLayer.push({
                event: gtmEvents.CONTACT_FORM_EMAIL,
                option: 'EMAIL',
                email: data.email,
                name: data.name,
                message: data.message,
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
            <div className={styles.inputWrapper}>
                <Input
                    label={t('contactFormName')}
                    {...register('name', { required: t('fieldRequired') })}
                    error={errors.name?.message}
                />
                <Input
                    label={t('contactFormEmail')}
                    {...register('email', {
                        required: t('fieldRequired'),
                        pattern: {
                            value: EMAIL_REGEX,
                            message: t('emailIncorrect'),
                        },
                    })}
                    error={errors.email?.message}
                />
                <Textarea label={t('messageOptional')} {...register('message')} />
            </div>

            <Button htmlType="submit" className={styles.button} isLoading={isLoading}>
                {t('sendRequest')}
            </Button>
        </form>
    );
};
