import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';

import { authLink } from './links/authLink';
import { httpLink } from './links/httpLink';
import { refreshTokenErrorLink } from './links/refreshTokenErrorLink';
import { responseErrorsHandlerLink } from './links/responseErrorsHandlerLink';

export const apolloClient = new ApolloClient({
    link: ApolloLink.from([refreshTokenErrorLink, authLink, responseErrorsHandlerLink, httpLink]),
    cache: new InMemoryCache(),
});
