import { useContext } from 'react';
import { MetadataType } from 'shared/types/checkout';
import CheckoutStore from 'stores/Checkout.store';
import ConfiguratorStore from 'stores/Configurator.store';

export enum CheckoutMetadataKey {
    COLOR = 'color',
    SITTING_POSITION = 'sittingPosition',
    SIZE = 'size',
    TEMPLATE_ID = 'templateId',
    FRAME_COLOR_1 = 'frameColor1',
    FRAME_COLOR_2 = 'frameColor2',
    BAR_COLOR = 'barColor',
    COLOR_TYPE = 'colorType',
    EXPERT_CHECKOUT = 'expertCheckout',
    SID = 'sid',
    CONFIGURATION_ID = 'configurationID',
    WEIGHT = 'weight',
    EXPERT_CHECKOUT_CHARGE_PERCENTAGE = 'exportCheckoutChargePercentage',
}

export const useUpdateCheckoutMetadata = () => {
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);

    const updateCheckoutMetadata = async () => {
        const metadata: MetadataType[] = [];

        const color = configuratorStore.selectedColors.length > 0 ? '' : configuratorStore.selectedBikeVariant;
        if (color) {
            metadata.push({
                key: CheckoutMetadataKey.COLOR,
                value: JSON.stringify(color),
            });
        }

        const sittingPosition =
            configuratorStore.selectedSittingPosition?.name || configuratorStore.selectedSittingPosition;
        if (sittingPosition) {
            metadata.push({
                key: CheckoutMetadataKey.SITTING_POSITION,
                value: sittingPosition,
            });
        }

        const size = configuratorStore.selectedSize?.name || configuratorStore.selectedSize;
        if (sittingPosition) {
            metadata.push({
                key: CheckoutMetadataKey.SIZE,
                value: size,
            });
        }

        const templateId = configuratorStore.selectedBike?.id;
        if (sittingPosition) {
            metadata.push({
                key: CheckoutMetadataKey.TEMPLATE_ID,
                value: templateId,
            });
        }

        const frameColor1 = configuratorStore.selectedColors.find((item: any) => item.key === 'frame-color-1') ?? '';
        if (frameColor1) {
            metadata.push({
                key: CheckoutMetadataKey.FRAME_COLOR_1,
                value: JSON.stringify(frameColor1),
            });
        }

        const frameColor2 = configuratorStore.selectedColors.find((item: any) => item.key === 'frame-color-2') ?? '';
        if (frameColor2) {
            metadata.push({
                key: CheckoutMetadataKey.FRAME_COLOR_2,
                value: JSON.stringify(frameColor2),
            });
        }
        const barColor = configuratorStore.selectedColors.find((item: any) => item.frame === 'Bar tape color') ?? '';
        if (barColor) {
            metadata.push({
                key: CheckoutMetadataKey.BAR_COLOR,
                value: JSON.stringify(barColor),
            });
        }

        const colorType = configuratorStore.selectedColors.length > 0 ? configuratorStore.selectedColorType : '';
        if (colorType) {
            metadata.push({
                key: CheckoutMetadataKey.COLOR_TYPE,
                value: colorType,
            });
        }

        const sid = configuratorStore.shareableID;
        if (sid) {
            metadata.push({
                key: CheckoutMetadataKey.SID,
                value: sid,
            });
        }

        const configurationID = configuratorStore.configurationID;
        if (configurationID) {
            metadata.push({
                key: CheckoutMetadataKey.CONFIGURATION_ID,
                value: configurationID,
            });
        }

        const weight = configuratorStore.totalWeight;
        if (weight) {
            metadata.push({
                key: CheckoutMetadataKey.WEIGHT,
                value: weight,
            });
        }

        const chargePercentage = checkoutStore.prepaymentPercentage.toString();
        if (weight) {
            metadata.push({
                key: CheckoutMetadataKey.EXPERT_CHECKOUT_CHARGE_PERCENTAGE,
                value: chargePercentage,
            });
        }

        await checkoutStore.updateCheckoutMetadata(metadata);
    };

    return [updateCheckoutMetadata];
};
