import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ContactCover.module.scss';

export const ContactCover = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.contactCover}>
            <h3>{t('consultation')}</h3>
        </div>
    );
};
