import React from 'react';
import {useTranslation} from "react-i18next";
import { Icon, Radio } from 'shared/components/ui';
import { AddressType } from 'shared/types/checkout';

import styles from './AddressCard.module.scss';

interface Props {
    data: AddressType;
    selected: boolean;
    onClick?: () => void;
    onEditClick?: () => void;
}

export const AddressCard: React.FC<Props> = ({ data, selected, onClick, onEditClick }) => {
    const { firstName, lastName, streetAddress1, postalCode, city, country, phone } = data;
    const { t } = useTranslation();


    return (
        <div className={styles.root}>
            <div className={styles.main}>
                <div className={styles.wrapper}>
                    <Radio selected={selected} onClick={onClick} />

                    <span>{t('mainAddress')}</span>
                </div>
                <Icon name="edit" onClick={onEditClick} />
            </div>
            <p>
                {firstName} {lastName}
            </p>
            <p>{streetAddress1},</p>
            <p>
                {postalCode} {city}, {country.country}
            </p>
            <p>{phone}</p>
        </div>
    );
};
