import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'shared/components/ui';

import styles from './PartVariant.module.scss';

type ConflictModalProps = {
    data: any;
    parts: any;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
};
export const ConflictModal: React.FC<ConflictModalProps> = ({ data, parts, isOpen, onClose, onConfirm }) => {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isOpen} onClose={onClose} className={styles.long_desc_modal} maxWidth={450} uncloseable>
            <div className={styles.modalContent}>
                <h3>{t('conflictModal.title')}</h3>
                <div className={styles.modalText}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('conflictModal.text').replace('{name}', data.name),
                        }}
                    ></div>

                    {parts.conflictTypes.length > 0 &&
                        parts.conflictTypes.map((p: any) => (
                            <div key={p.variantName} className={styles.modalRemove}>- {p.variantName}</div>
                        ))}
                    {parts.conflict.length > 0 &&
                        parts.conflict.map((c: any) => <div key={c.name} className={styles.modalRemove}>- {c.name}</div>)}
                    {parts.required.length > 0 &&
                        parts.required.map((p: any) => <div key={p.name} className={styles.modalAdd}>+ {p.name}</div>)}
                    {parts.requiredTypes.length > 0 &&
                        parts.requiredTypes.map((p: any) => <div key={p.name} className={styles.modalAdd}>+ {p.name}</div>)}
                </div>

                <Button onClick={onConfirm}>{t('conflictModal.confirmButton')}</Button>
                <Button onClick={onClose} type="text">
                    {t('conflictModal.declineButton')}
                </Button>
            </div>
        </Modal>
    );
};
