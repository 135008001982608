import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'shared/components/ui';
import { WarehouseType } from 'shared/types/checkout';
import CheckoutStore from 'stores/Checkout.store';

import styles from './Pickup.module.scss';

interface Props {
    onChange?: (data: WarehouseType) => void;
}

export const Pickup: React.FC<Props> = observer(({ onChange }) => {
    const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    const thoemusPinIconUrl = 'https://storage.googleapis.com/thoemus-preprod-media/pin.png';
    const checkoutStore = useContext(CheckoutStore);
    const availableCollectionPoints = toJS(checkoutStore.data?.availableCollectionPoints) ?? [];
    const { t } = useTranslation();

    const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseType | undefined>(availableCollectionPoints[0]);

    useEffect(() => {
        if (checkoutStore.data?.deliveryMethod) {
            const shop = availableCollectionPoints.find(
                (item: any) => item.name === checkoutStore.data?.deliveryMethod?.name,
            );
            if (shop) setSelectedWarehouse(shop);
        }
    }, []); //eslint-disable-line

    return (
        <div className={styles.pickup}>
            <div>
                <h5 className={styles.label}>{t('selectStore')}</h5>
                <Select<WarehouseType>
                    value={selectedWarehouse}
                    options={availableCollectionPoints}
                    getOptionLabel={option => option.name}
                    onChange={value => {
                        setSelectedWarehouse(value as WarehouseType);
                        onChange && onChange(value as WarehouseType);
                    }}
                    isSearchable={false}
                />
                <div className={styles.pickupDetails}>
                    <div className={styles.pickupDetail}>
                        <span>{t('address')}</span>
                        <p>
                            {selectedWarehouse?.address.streetAddress1}, {selectedWarehouse?.address.postalCode}{' '}
                            {selectedWarehouse?.address.city}
                        </p>
                    </div>
                    {selectedWarehouse?.openingHours.map(data => (
                        <div key={data.id} className={styles.pickupDetail}>
                            <span>{data.weekday.toLocaleLowerCase()}</span>
                            <p>
                                {/* Remove :00 (seconds) from the end of time string */}
                                {data.fromHour.slice(0, -3)} – {data.toHour.slice(0, -3)}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                {selectedWarehouse && (
                    <img
                        className={styles.map}
                        src={`https://maps.googleapis.com/maps/api/staticmap?center=${selectedWarehouse?.longitude},${selectedWarehouse?.latitude}&zoom=14&size=400x250&scale=2&markers=anchor:bottom%7Cicon:${thoemusPinIconUrl}|${selectedWarehouse?.longitude},${selectedWarehouse?.latitude}&key=${googleMapApiKey}`}
                        alt="Location of the store on map"
                    />
                )}
            </div>
        </div>
    );
});
