import clsx from 'clsx';
import dayjs from 'dayjs';
import { CheckoutMetadataKey } from 'pages/Configurator/hooks/useUpdateCheckoutMetadata';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button, Icon, Input, Select, Textarea } from 'shared/components/ui';
import CheckoutStore from 'stores/Checkout.store';
import 'react-datepicker/dist/react-datepicker.css';

import { Accordion } from '../Accordion/Accordion';
import { StripePayment } from '../Payment/_components/StripePayment/StripePayment';

import { CheckoutFileUpload } from './_components/CheckoutFileUpload';
import styles from './ExpertCheckout.module.scss';

interface ExpertCheckoutFormType {
    officialComments: string;
    internalComments: string;
    options: string;
    description: string;
    location: string;
    discountPercentage: number;
    discountAmountOne: number;
    discountAmountTwo: number;
    salesperson: string;
    point_of_sale: string;
    order_type: string;
    deliveryDate: string;
    amount_to_charge_percentage: string;
    discount_code: string;
}

export const ExpertCheckout = () => {
    const checkoutStore = useContext(CheckoutStore);

    const { register, control, watch, handleSubmit, getValues } = useForm<ExpertCheckoutFormType>({
        mode: 'onChange',
        defaultValues: {
            discount_code: '',
        },
    });
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOffer, setIsLoadingOffer] = useState(false);
    const [isLoadingOrder, setIsLoadingOrder] = useState(false);
    const [chargeClient, setChargeClient] = useState(false);
    const [dropdownFields, setDropdownFields] = useState<any>();
    const [voucherList, setVoucherList] = useState<any>();

    const [isTradeInAccordionExpanded, setIsTradeInAccordionExpanded] = useState(false);
    const [isDiscountAccordionExpanded, setIsDiscountAccordionExpanded] = useState(false);

    useEffect(() => {
        const fetchDropdownFields = async () => {
            try {
                const dropdownFields = await checkoutStore.getExpertCheckoutDropdownFields();
                setDropdownFields(dropdownFields);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchDiscountVouchers = async () => {
            try {
                const voucherList = await checkoutStore.getExpertCheckoutVouchers();
                const list = voucherList.expertVouchers.edges.map((voucher: any) => ({
                    label: voucher.node.name,
                    value: voucher.node.code,
                }));
                setVoucherList([{ label: t('expertNoDiscount'), value: '' }, ...list]);
            } catch (e) {
                console.error(e);
            }
        };
        fetchDropdownFields();
        fetchDiscountVouchers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const salespersonDropdown = useMemo(
        () =>
            dropdownFields?.salesperson?.map((data: any) => {
                return { label: data.name, value: data.name };
            }),
        [dropdownFields],
    );

    const pointOfSaleDropdown = useMemo(
        () =>
            dropdownFields?.point_of_sale?.map((data: any) => {
                return { label: data.name, value: data.name };
            }),
        [dropdownFields],
    );

    const orderTypeDropdown = useMemo(() => {
        return dropdownFields?.order_type?.map((data: any) => {
            return { label: data.name, value: data.name };
        });
    }, [dropdownFields]);

    useEffect(() => {
        const subscription = watch((formData, { name }) => {
            if (!name) return;
            if (name === 'discountPercentage') {
                checkoutStore.expertCheckoutDiscountPercentage = Number(formData[name]);
            }
            if (name === 'discountAmountOne') {
                checkoutStore.expertCheckoutDiscountAmountOne = Number(formData[name]);
            }
            if (name === 'discountAmountTwo') {
                checkoutStore.expertCheckoutDiscountAmountTwo = Number(formData[name]);
            }
            if (name === 'discount_code') {
                if (formData.discount_code) {
                    checkoutStore.addPromoCode(formData.discount_code, true).catch(e => console.error(e));
                }
            }
        });
        return () => subscription.unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch]);

    const submit = async (data: ExpertCheckoutFormType, isOffer = true, unpaid = false) => {
        data.deliveryDate = dayjs(data.deliveryDate).format('YYYY-MM-DD');
        // data.amount_to_charge_percentage = checkoutStore.prepaymentPercentage.toString();
        try {
            if (isOffer) setIsLoadingOffer(true);

            if (!isOffer) setIsLoadingOrder(true);
            const expertCheckoutPaymentGateway = checkoutStore.data?.availablePaymentGateways?.find(
                gateway => gateway.id === 'mirumee.payments.dummy',
            );
            if (expertCheckoutPaymentGateway) {
                await checkoutStore.createPayment(expertCheckoutPaymentGateway.id, isOffer, unpaid);
            }
            const metadata = [{ key: CheckoutMetadataKey.EXPERT_CHECKOUT, value: JSON.stringify(data) }];
            await checkoutStore.updateCheckoutMetadata(metadata);
            await checkoutStore.checkoutComplete(checkoutStore.data?.id, isOffer);

            navigate(AppRoutes.orderStatus.replace(':id', checkoutStore.data?.id ?? ''));
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            setIsLoadingOrder(false);
            setIsLoadingOffer(false);
        }
    };

    const submitPay = async (data: ExpertCheckoutFormType) => {
        data.deliveryDate = dayjs(data.deliveryDate).format('YYYY-MM-DD');
        // data.amount_to_charge_percentage = checkoutStore.prepaymentPercentage.toString();
        try {
            setIsLoading(true);
            const metadata = [{ key: CheckoutMetadataKey.EXPERT_CHECKOUT, value: JSON.stringify(data) }];
            await checkoutStore.updateCheckoutMetadata(metadata);
            setChargeClient(true);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    // const updatePrepayment = async (value: any) => {
    //     checkoutStore.prepaymentPercentage = value.value;
    //
    //     try {
    //         const metadata = [{ key: CheckoutMetadataKey.EXPERT_CHECKOUT_CHARGE_PERCENTAGE, value: value.value }];
    //         await checkoutStore.updateCheckoutMetadata(metadata);
    //     } catch (e) {
    //         console.error(e);
    //     }
    // };

    return (
        <div className={styles.root}>
            <h3>Bemerkungen</h3>
            <Textarea
                label="Offizielle Bemerkungen"
                placeholder="Offizielle Bemerkungen"
                textareaClassName={styles.input}
                {...register('officialComments')}
            />
            <Textarea
                label="Interne Bemerkungen"
                placeholder="Interne Bemerkungen"
                textareaClassName={styles.input}
                {...register('internalComments')}
            />

            <h3>Kosten</h3>
            <Textarea
                label="Option hinzufügen"
                placeholder="Option hinzufügen"
                textareaClassName={styles.input}
                {...register('options')}
            />

            <Accordion
                expanded={isTradeInAccordionExpanded}
                className={clsx(styles.accordion, isTradeInAccordionExpanded && styles.expanded)}
                titleComponent={
                    <h4 onClick={() => setIsTradeInAccordionExpanded(!isTradeInAccordionExpanded)}>
                        {t('bikeReplacement')} <Icon name="arrow_down" />
                    </h4>
                }
                contentComponent={
                    <>
                        <Input
                            label="Bezeichnung"
                            placeholder="Bezeichnung"
                            inputClassName={styles.input}
                            {...register('description')}
                        />
                        <div className={styles.row}>
                            <Input
                                label="Standort"
                                placeholder="Standort"
                                inputClassName={styles.input}
                                {...register('location')}
                            />
                            <Input
                                label="Wert"
                                placeholder="0"
                                type="number"
                                suffix={checkoutStore.data?.totalPrice?.currency}
                                inputClassName={styles.input}
                                {...register('discountAmountOne')}
                            />
                        </div>
                    </>
                }
            />

            <Accordion
                expanded={isDiscountAccordionExpanded}
                className={clsx(styles.accordion, isDiscountAccordionExpanded && styles.expanded)}
                titleComponent={
                    <h4 onClick={() => setIsDiscountAccordionExpanded(!isDiscountAccordionExpanded)}>
                        {t('discountExpert')} <Icon name="arrow_down" />
                    </h4>
                }
                contentComponent={
                    <>
                        <div className={styles.row}>
                            <Controller
                                name="discount_code"
                                control={control}
                                render={({ field: { name, onChange } }) => {
                                    return (
                                        <Select
                                            label="Rabattbetrag"
                                            placeholder="Rabattbetrag"
                                            labelWrapperClassName={styles.selectLabelWrapper}
                                            options={voucherList}
                                            name={name}
                                            onChange={(data: any) => {
                                                if (!data?.value) {
                                                    if (checkoutStore.data?.voucherCode)
                                                        checkoutStore
                                                            .removePromoCode(checkoutStore.data?.voucherCode)
                                                            .catch(e => console.error(e));
                                                }

                                                onChange(data?.value);
                                            }}
                                        />
                                    );
                                }}
                            />

                            <div />
                        </div>
                    </>
                }
            />

            <h3>Abschluss</h3>
            <div className={styles.row}>
                <Controller
                    name="salesperson"
                    control={control}
                    render={({ field: { name, onChange } }) => {
                        return (
                            <Select
                                label="Verkäufer"
                                placeholder="Verkäufer"
                                labelWrapperClassName={styles.selectLabelWrapper}
                                options={salespersonDropdown}
                                name={name}
                                onChange={(data: any) => onChange(data?.value)}
                            />
                        );
                    }}
                />

                <Controller
                    name="point_of_sale"
                    control={control}
                    render={({ field: { name, onChange } }) => {
                        return (
                            <Select
                                label="Verkaufsstelle"
                                placeholder="Verkaufsstelle"
                                labelWrapperClassName={styles.selectLabelWrapper}
                                options={pointOfSaleDropdown}
                                name={name}
                                onChange={(data: any) => onChange(data?.value)}
                            />
                        );
                    }}
                />
            </div>

            <div className={styles.row}>
                <Controller
                    name="order_type"
                    control={control}
                    render={({ field: { name, onChange } }) => {
                        return (
                            <Select
                                label="Auftragstyp"
                                placeholder="Auftragstyp"
                                labelWrapperClassName={styles.selectLabelWrapper}
                                options={orderTypeDropdown}
                                name={name}
                                onChange={(data: any) => onChange(data?.value)}
                            />
                        );
                    }}
                />

                {/* <Input
                    label="Lieferdatum"
                    placeholder="Gewünschtes Lieferdatum"
                    inputClassName={styles.input}
                    {...register('deliveryDate')}
                /> */}
                <div>
                    <Controller
                        name="deliveryDate"
                        control={control}
                        render={({ field: { name, onChange } }) => {
                            return (
                                <DatePicker
                                    selected={getValues('deliveryDate') as any}
                                    onChange={onChange}
                                    name={name}
                                    dateFormat="dd MMM yyyy"
                                    minDate={dayjs().toDate()}
                                    customInput={<Input inputClassName={styles.input} label="Lieferdatum" />}
                                />
                            );
                        }}
                    />
                </div>
            </div>

            <CheckoutFileUpload />

            <div className={styles.actions}>
                {!chargeClient && (
                    <Button className={styles.button} isLoading={isLoading} onClick={handleSubmit(submitPay)}>
                        Continue & pay
                    </Button>
                )}
                <Button
                    className={styles.button}
                    onClick={handleSubmit(data => submit(data, false, true))}
                    type="secondary"
                    isLoading={isLoadingOrder}
                >
                    Order & finish
                </Button>
                <Button
                    className={styles.button}
                    type="secondary"
                    onClick={handleSubmit(data => submit(data, true, false))}
                    isLoading={isLoadingOffer}
                >
                    {t('create_offer')}
                </Button>
            </div>

            {chargeClient && (
                <div className={styles.stripe_form}>
                    <StripePayment />
                </div>
            )}

            {/*{chargeClient && (*/}
            {/*    <div className={styles.customCharge}>*/}
            {/*        <hr />*/}
            {/*        <h3>Zahlung</h3>*/}
            {/*        <div className={styles.amount}>*/}
            {/*            <Select*/}
            {/*                label="Amount to charge"*/}
            {/*                options={percentages}*/}
            {/*                onChange={updatePrepayment}*/}
            {/*                defaultValue={percentages.find(p => p.value === 30)}*/}
            {/*            />*/}
            {/*        </div>*/}

            {/*        <StripePayment />*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};
