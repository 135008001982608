import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import { useLocation } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button } from 'shared/components/ui/Button/Button';
import { ContactModalContext, ContactModalScreen } from 'shared/components/ui/ContactModal/stores/ContactModal.store';
import { ErrorMessage } from 'shared/components/ui/ErrorMessage/ErrorMessage';
import { Input } from 'shared/components/ui/Input/Input';
import { Label } from 'shared/components/ui/Label/Label';
import { Textarea } from 'shared/components/ui/Textarea/Textarea';
import { PHONE_REGEX } from 'shared/helpers/validators';
import ConfiguratorStore from 'stores/Configurator.store';

import { gtmEvents } from '../../../../../../../types/gtmEvents';

import styles from './PhoneForm.module.scss';

interface PhoneFormType {
    name: string;
    phone: string;
    message: string;
}

export const PhoneForm = () => {
    const contactModalStore = useContext(ContactModalContext);
    const configuratorStore = useContext(ConfiguratorStore);

    const location = useLocation();
    const isConfigurator = location.pathname.includes(AppRoutes.configurator);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<PhoneFormType>();

    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const onSubmit = async (data: PhoneFormType) => {
        try {
            setIsLoading(true);
            if (isConfigurator) {
                await configuratorStore.saveConfiguration();
            }
            await contactModalStore.sendForm({
                ...data,
                configurationSid: configuratorStore.shareableID,
                channel: process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL,
            });
            contactModalStore.successScreenMessage = t('successScreenMessagePhone', { phone: data.phone });
            contactModalStore.currentScreen = ContactModalScreen.SUCCESS;

            window.dataLayer.push({
                event: gtmEvents.CONTACT_FORM_PHONE,
                option: 'PHONE',
                phone: data.phone,
                name: data.name,
                message: data.message,
            });
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
            <div className={styles.inputWrapper}>
                <Input
                    label="Name"
                    {...register('name', { required: t('fieldRequired') })}
                    error={errors.name?.message}
                />

                <div>
                    <Label title={t('phoneTitle')} className={styles.label} />
                    <Controller
                        name="phone"
                        control={control}
                        rules={{
                            required: t('fieldRequired'),
                            pattern: {
                                value: PHONE_REGEX,
                                message: t('phoneNumberTooShort'),
                            },
                        }}
                        render={({ field: { value, onChange } }) => (
                            <PhoneInput
                                placeholder={t('phonePlaceholder')}
                                value={value}
                                onChange={onChange}
                                country={'ch'}
                            />
                        )}
                    />

                    {errors.phone?.message && <ErrorMessage message={errors.phone?.message} className={styles.error} />}
                </div>

                <Textarea label="Message (optional)" {...register('message')} />
            </div>

            <Button htmlType="submit" className={styles.button} isLoading={isLoading}>
                {t('sendRequest')}
            </Button>
        </form>
    );
};
