import { BikeModelEdges } from 'api/queries/bikeModel';
import React, { FC, useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'Routes';
import { BikeCard, Button } from 'shared/components/ui';
import ConfiguratorStore from 'stores/Configurator.store';

import { Loader } from '../../../../shared/components/ui/Loader/Loader';
import { gtmEvents } from '../../../../shared/types/gtmEvents';

import styles from './Sidebar.module.scss';

type BikeModelSidebarType = {
    bikeModelData: BikeModelEdges[];
    loading: boolean;
};

export const Sidebar: FC<BikeModelSidebarType> = ({ bikeModelData, loading }) => {
    const store = useContext(ConfiguratorStore);
    const [bikeModelsList, setBikeModelsList] = useState<BikeModelEdges[]>();
    // const [mailModal, setMailModal] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (bikeModelData) {
            setBikeModelsList(bikeModelData);
        }
    }, [bikeModelData]);

    const setBikeModel = (data: any) => {
        store.selectedBike = data;

        window.dataLayer.push({
            event: gtmEvents.BIKE_MODEL,
            category: store.bike,
            bikeModel: data.name,
        });
    };

    const goToConfigurator = () => {
        // const emailFromlocal = localStorage.getItem(LocalStorageKey.EMAIL);
        // if (!emailFromlocal) {
        //     setMailModal(true);
        //     return;
        // }
        navigate(`${AppRoutes.configurator}?bike=${store.selectedBike?.id}`);
    };

    return (
        <>
            <div className={styles.bike_model}>
                <h5 className={styles.title}>{t('selectModelTitle')}</h5>
                <p className={styles.description}>{t('selectModelDescription')}</p>
                {loading ? (
                    <div className={styles.spinner}>
                        <Loader />
                    </div>
                ) : (
                    <div className={styles.bike_models}>
                        {bikeModelsList &&
                            bikeModelsList.map((bike, i) => {
                                return (
                                    <BikeCard
                                        key={`${bike.id}-${i}`}
                                        data={bike}
                                        setBikeModel={data => setBikeModel(data)}
                                        activeModel={store.selectedBike.id}
                                        type="bikeModel"
                                    />
                                );
                            })}
                    </div>
                )}
            </div>
            <div className={styles.bike_model_footer}>
                <Button type="primary" postIcon="arrow_right" onClick={() => goToConfigurator()}>
                    {t('openInConfigButton')}
                </Button>
            </div>
            {/*<EmailPopup isOpen={mailModal} onClose={() => setMailModal(false)} />*/}
        </>
    );
};
