import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BikeCard } from 'shared/components/ui';
import { MenuPopup } from 'shared/components/ui/MenuPopup/MenuPopup';
import ConfiguratorStore from 'stores/Configurator.store';

import { Portal } from '../../../../shared/components/ui/Portal/Portal';
import useClickOutside from '../../../../shared/providers/useClickOutside';
import { gtmEvents } from '../../../../shared/types/gtmEvents';
import CheckoutStore from '../../../../stores/Checkout.store';

import styles from './Preconfigurations.module.scss';

type PreConfigurationsProps = {};

export const PreConfigurations: FC<PreConfigurationsProps> = observer(() => {
    const store = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const [bikePreconfigurationId, setBikePreconfigurationId] = useState('');
    const [openPopup, setOpenPopup] = useState(false);
    const [coords, setCoords] = useState({ top: '0', left: '0' });
    const popupRef = useRef(null);
    const morePreconfigurationsRef = useRef(null);
    const { t } = useTranslation();

    const isPreconfigurationFromMoreSelected = useMemo(
        () =>
            !!store.preconfigurationsList?.slice(3)?.find((preconfig: any) => {
                return preconfig.id === bikePreconfigurationId;
            }),
        [bikePreconfigurationId, store.preconfigurationsList],
    );

    useEffect(() => {
        if (store.preconfigurationId) {
            setBikePreconfigurationId(store.preconfigurationId);
        }

        if (openPopup) {
            const current = popupRef.current;
            window.addEventListener('resize', () => openPortal(current));

            // const index = store.preconfigurationsList.findIndex((part: any) => part.id === store.preconfigurationId);
            // const variantId = document.getElementById(store.preconfigurationsList[index].id);
            // variantId?.scrollIntoView(false);

            return () => {
                window.removeEventListener('resize', () => openPortal(current));
            };
        }
    }, [store, store.preconfigurationId, bikePreconfigurationId, openPopup]);

    const openPortal = (e: any) => {
        const rect = e.getBoundingClientRect();
        setCoords({
            left: rect.x + rect.width / 2, // add half the width of the button for centering
            top: rect.y + window.scrollY, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
        });
    };

    const setPreConfigurationId = (id: string) => {
        setBikePreconfigurationId(id);
        store.setPreconfigurationId(id);
    };

    useClickOutside(morePreconfigurationsRef, () => setOpenPopup(false));

    return (
        <>
            {store.preconfigurationsList && (
                <div className={styles.pre_configurations_wrapper}>
                    <h5>{t('sidebarPreconfigurationsTitle')}</h5>
                    <div className={styles.pre_configurations}>
                        {store.preconfigurationsList.length > 4 ? (
                            <>
                                {store.preconfigurationsList.slice(0, 3).map((bike: any) => {
                                    return (
                                        <BikeCard
                                            key={bike.id}
                                            data={bike}
                                            setBikeModel={data => {
                                                setPreConfigurationId(data.id);
                                            }}
                                            activeModel={bikePreconfigurationId}
                                            type="configurator"
                                        />
                                    );
                                })}
                                <div
                                    className={clsx(
                                        styles.more_bikes,
                                        isPreconfigurationFromMoreSelected && styles.more_bikes_active,
                                    )}
                                    onClick={(e: any) => {
                                        openPortal(e.target);
                                        setOpenPopup(!openPopup);

                                        window.dataLayer.push({
                                            event: gtmEvents.BIKE_PRECONFIGURATION_MODAL,
                                        });
                                    }}
                                    ref={popupRef}
                                >
                                    <h5>
                                        +{store.preconfigurationsList.length - 3} {t('sidebarMoreButton')}
                                    </h5>
                                </div>
                                {openPopup && (
                                    <Portal>
                                        <div
                                            ref={morePreconfigurationsRef}
                                            style={{ position: 'absolute', top: coords.top, left: coords.left }}
                                        >
                                            <MenuPopup
                                                width={362}
                                                className={styles.popup}
                                                arrowClassName={styles.popup_arrow}
                                                bottomArrow
                                                maxHeight={408}
                                            >
                                                <p className={styles.subtitle}>
                                                    {t('sidebarChoosePreconfigurationTitle')}
                                                </p>
                                                <div className={styles.choose_preconfiguration}>
                                                    {store.preconfigurationsList
                                                        .slice(3, store.preconfigurationsList.length)
                                                        .map((bike: any) => {
                                                            return (
                                                                <BikeCard
                                                                    key={bike.id}
                                                                    data={bike}
                                                                    setBikeModel={async data => {
                                                                        setPreConfigurationId(data.id);
                                                                        setOpenPopup(false);

                                                                        window.dataLayer.push({
                                                                            event: gtmEvents.BIKE_PRECONFIGURATION,
                                                                            preconfiguration: data.title,
                                                                        });

                                                                        await checkoutStore.sendDataToCustomerIo(
                                                                            gtmEvents.BIKE_PRECONFIGURATION,
                                                                            data.title,
                                                                        );
                                                                    }}
                                                                    activeModel={bikePreconfigurationId}
                                                                    type="configurator"
                                                                    lightMode
                                                                />
                                                            );
                                                        })}
                                                </div>
                                            </MenuPopup>
                                        </div>
                                    </Portal>
                                )}
                            </>
                        ) : (
                            <>
                                {store.preconfigurationsList.slice(0, 4).map((bike: any) => {
                                    return (
                                        <BikeCard
                                            key={bike.id}
                                            data={bike}
                                            setBikeModel={async data => {
                                                setPreConfigurationId(data.id);
                                                window.dataLayer.push({
                                                    event: gtmEvents.BIKE_PRECONFIGURATION,
                                                    preconfiguration: data.title,
                                                });
                                                await checkoutStore.sendDataToCustomerIo(
                                                    gtmEvents.BIKE_PRECONFIGURATION,
                                                    data.title,
                                                );
                                            }}
                                            activeModel={bikePreconfigurationId}
                                            type="configurator"
                                        />
                                    );
                                })}
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
});
