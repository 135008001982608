import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';
import { LocalStorageKey } from 'shared/helpers/localStorage';
import { apolloClient } from 'shared/lib/apollo';
import {
    AddressInputType,
    AddressType,
    CheckoutType,
    CountryType,
    mapAddressTypeToAddressInputType,
} from 'shared/types/checkout';

import {
    ACCOUNT_ADDRESS_CREATE,
    ACCOUNT_ADDRESS_UPDATE,
    CUSTOMER_CHECKOUT_ATTACH,
    CHECKOUT_UPDATE_MAIL,
    CHECKOUT_BILLING_ADDRESS_UPDATE,
    CHECKOUT_DELIVERY_METHOD_UPDATE,
    CREATE_CHECKOUT,
    CHECKOUT_SHIPPING_ADDRESS_UPDATE,
    PAYMENT_CREATE,
    PAYMENT_COMPLETE,
    UPDATE_IMAGE_CHECKOUT,
    CHECKOUT_ADD_PROMO_CODE,
    CHECKOUT_REMOVE_PROMO_CODE,
    UPDATE_METADATA,
    CUSTOMER_CHECKOUT_DETACH,
    CHECKOUT_UPLOAD_FILE,
    CHECKOUT_DELETE_FILE,
    PAYMENT_INTENT_UPDATE,
    CHECKOUT_LINES_UPDATE,
    CHECKOUT_UPDATE_PHONE,
    CUSTOMER_IO,
    FINANCE_CREATE,
    EXPERT_VOUCHERS,
} from '../api/mutations/checkout';
import { CONFIGURATION_UPDATE } from '../api/mutations/configurator';
import {
    CHECKOUT,
    CHECKOUT_EXPERT_DROPDOWN_FIELDS,
    FETCH_PRODUCT_DATA,
    SHIPPING_ZONES,
    SHOP,
} from '../api/queries/checkout';
import { SERVICE_UPGRADE } from '../api/queries/upsell';
import { getLSKeyWithExpiry } from '../shared/providers/utils';

export enum CheckoutMode {
    BASIC = 'BASIC',
    EXPERT = 'EXPERT',
}

export enum CheckoutStep {
    CONTACT = 1,
    SHIPPING,
    DELIVERY,
    PAYMENT,
    EXPERT_CHECKOUT,
}

export enum AddressModalState {
    CLOSED = 'CLOSED',
    NEW_SHIPPING = 'NEW_SHIPPING',
    NEW_BILLING = 'NEW_BILLING',
    EDIT_SHIPPING = 'EDIT_SHIPPING',
    EDIT_BILLING = 'EDIT_BILLING',
}

export const BASE_CHECKOUT_STEP_ORDER = [
    CheckoutStep.CONTACT,
    CheckoutStep.SHIPPING,
    CheckoutStep.DELIVERY,
    CheckoutStep.PAYMENT,
];

export const EXPERT_CHECKOUT_STEP_ORDER = [
    CheckoutStep.CONTACT,
    CheckoutStep.SHIPPING,
    CheckoutStep.DELIVERY,
    CheckoutStep.EXPERT_CHECKOUT,
];

export const CHECKOUT_STEPS_ORDER = {
    [CheckoutMode.BASIC]: BASE_CHECKOUT_STEP_ORDER,
    [CheckoutMode.EXPERT]: EXPERT_CHECKOUT_STEP_ORDER,
};

class CheckoutStore {
    mode = CheckoutMode.BASIC;

    checkoutStepProgress = CheckoutStep.CONTACT;
    expandedStep = 0;

    data?: CheckoutType;
    serviceUpgradeData: any;

    countries: CountryType[] = [];
    locale = localStorage.getItem(LocalStorageKey.LANGUAGE)?.split('-')[0].toUpperCase() || 'DE';

    selectedShippingAddress?: AddressType;
    selectedBillingAddress?: AddressType;
    isBillingAddressSameAsShipping = true;
    addressModalState: AddressModalState = AddressModalState.CLOSED;
    addressModalInitialData?: AddressType;
    addressLoading = false;

    productData?: any;
    orderData: any;
    clientSecret?: string;

    fileUploadingProgress = false;

    screenshot: any;
    takeScreenshot: any;
    checkoutImage: any;
    // Set prepayment percentage to 100% by default, so the full price is charged
    // unless specified differently on CMS
    prepaymentPercentage = 100;

    expertCheckoutDiscountPercentage = 0;
    expertCheckoutDiscountAmountOne = 0;
    expertCheckoutDiscountAmountTwo = 0;

    phoneValidationFailed = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    get checkoutStepsOrder() {
        return CHECKOUT_STEPS_ORDER[this.mode];
    }

    get isAddressModalOpen() {
        return this.addressModalState !== AddressModalState.CLOSED;
    }

    get addresses(): AddressType[] {
        return this.data?.user?.addresses ?? [];
    }

    get totalPrice() {
        const totalPrice = this.data?.totalPrice.gross?.amount ?? 0;

        if (this.mode === CheckoutMode.EXPERT) {
            return (
                totalPrice -
                ((totalPrice * this.expertCheckoutDiscountPercentage) / 100 || 0) -
                (this.expertCheckoutDiscountAmountOne || 0) -
                (this.expertCheckoutDiscountAmountTwo || 0)
            );
        }

        return totalPrice;
    }

    getShopData = () => {
        return apolloClient
            .query({
                query: SHOP,
            })
            .then(resp => {
                if (resp && resp.data) {
                    if (resp.data.shop.prepaymentPercentage) {
                        this.prepaymentPercentage = resp.data.shop.prepaymentPercentage;
                    }
                }
            });
    };

    createCheckout = (parts: any, customColors: any, variant: any) => {
        const checkoutProducts = parts
            .map((item: any) => {
                return { variantId: item.variantId, quantity: 1 };
            })
            .filter((p: any) => p.variantId);

        const customColorObj = {
            variantId: customColors[0]?.id,
            quantity: 1,
        };

        const frame = {
            variantId: variant.id,
            quantity: 1,
        };

        const checkoutLines =
            customColors.length > 0 ? [frame, customColorObj, ...checkoutProducts] : [frame, ...checkoutProducts];

        const emailFromLocal = localStorage.getItem(LocalStorageKey.EMAIL);

        let configurationID = '';

        if (getLSKeyWithExpiry(LocalStorageKey.REFERRAL_ITEM)) {
            configurationID = getLSKeyWithExpiry(LocalStorageKey.REFERRAL_ITEM);

            // check if this needs to be removed
            localStorage.removeItem(LocalStorageKey.REFERRAL_ITEM);
        }

        return apolloClient
            .mutate({
                mutation: CREATE_CHECKOUT,
                variables: {
                    input: {
                        channel: process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL,
                        lines: checkoutLines,
                        email: emailFromLocal || '',
                        configuration: configurationID,
                    },
                    language: this.locale,
                },
            })
            .then(resp => {
                this.data = resp.data.checkoutCreate.checkout;
                if (emailFromLocal) localStorage.removeItem(LocalStorageKey.EMAIL);
            })
            .catch(e => console.error(e));
    };

    updateCheckoutLines = (parts: any, customColors: any, variant: any, id: string) => {
        const mappedProducts = parts
            .map((item: any) => {
                return { variantId: item.variantId, quantity: 1 };
            })
            .filter((p: any) => p.variantId);

        // Get parts already in checkout and set for removal excess ones
        const checkoutParts = this.data?.linesByVariantGroup
            ?.map((a: any) => a.lines)
            ?.flat()
            ?.map(item => item.variant);

        // Find products that are ony in initial list and set them for removal
        const forRemoval =
            checkoutParts
                ?.filter((p: any) => !parts.find((a: any) => a.variantId === p.id))
                .map((item: any) => ({ variantId: item.id, quantity: 0 })) || [];

        const checkoutProducts = [...mappedProducts, ...forRemoval];

        const customColorObj = {
            variantId: customColors[0]?.id,
            quantity: 1,
        };

        const frame = {
            variantId: variant.id,
            quantity: 1,
        };

        const checkoutLines =
            customColors.length > 0 ? [...checkoutProducts, customColorObj, frame] : [...checkoutProducts, frame];

        return apolloClient
            .mutate({
                mutation: CHECKOUT_LINES_UPDATE,
                variables: {
                    id: id,
                    lines: checkoutLines,
                    language: this.locale,
                },
            })
            .then(resp => {
                this.data = resp.data.checkoutLinesUpdate.checkout;
            })
            .catch(e => console.error(e));
    };

    checkoutLinesAddDelete = async (checkoutId: string, lineId: string, productName: string) => {
        const overrideProduct = this.data?.lines.find(
            (item: any) => item.variant.product.category?.name === productName,
        );

        // override
        if (overrideProduct) {
            await this.checkoutLineDelete(overrideProduct.variant.name, true);
        }

        const line = [{ variantId: lineId, quantity: 1 }];
        return apolloClient
            .mutate({
                mutation: CHECKOUT_LINES_UPDATE,
                variables: {
                    id: checkoutId,
                    lines: line,
                    language: this.locale,
                },
            })
            .then(resp => {
                this.data = resp.data.checkoutLinesUpdate.checkout;
            })
            .catch(e => console.error(e));
    };

    checkoutLineDelete = (id: string, optimisticUpdate?: boolean) => {
        const line = [{ variantId: id, quantity: 0 }];
        return apolloClient
            .mutate({
                mutation: CHECKOUT_LINES_UPDATE,
                variables: {
                    id: this.data?.id,
                    lines: line,
                    language: this.locale,
                },
            })
            .then(resp => {
                // do not update the data in checkout store if this flag is active
                if (optimisticUpdate) return;
                this.data = resp.data.checkoutLinesUpdate.checkout;
            })
            .catch(e => console.error(e));
    };

    updateCheckoutMetadata = (metadata: { key: string; value: string }[]) => {
        return apolloClient
            .mutate({
                mutation: UPDATE_METADATA,
                variables: { id: this.data?.id, input: metadata },
            })
            .then(resp => {
                if (this.data) {
                    this.data.metadata = resp.data.updateMetadata.item?.metadata;
                }
            })
            .catch(e => console.error(e));
    };

    getCheckout = (id?: string) => {
        return apolloClient
            .query({
                query: CHECKOUT,
                variables: { id, language: this.locale },
            })
            .then(resp => {
                this.data = resp.data.checkout;

                this.handleSelectedStep();
                if (!this.productData) this.fetchProductData();
            })
            .catch(e => console.error(e));
    };

    handleSelectedStep = () => {
        if (!this.data?.email || !this.data?.phone) {
            this.checkoutStepProgress = CheckoutStep.CONTACT;
            this.expandedStep = CheckoutStep.CONTACT;
        }
        if (this.data?.email && this.data?.phone) {
            this.checkoutStepProgress = CheckoutStep.SHIPPING;
            this.expandedStep = CheckoutStep.SHIPPING;
        }
        if (this.data?.shippingAddress) {
            this.checkoutStepProgress = CheckoutStep.DELIVERY;
            this.expandedStep = CheckoutStep.DELIVERY;
        }
    };

    // CREATE A SCREENSHOT USED FOR THE CONFIGURATION
    createScreenshot = async () => {
        this.takeScreenshot = true;

        // take screenshot after the bike is set to default position
        // it take some time for animation to be done, that's why 2200
        setTimeout(() => {
            const el = document.getElementsByTagName('canvas')[0] as any;
            if (!el) return;
            const img = el.toDataURL('image/png');
            this.screenshot = img;
            this.takeScreenshot = false;
            this.resetSteps();
        }, 2200);
    };

    saveScreenshot = async (id: string) => {
        if (!this.screenshot) return;

        return apolloClient
            .mutate({
                mutation: UPDATE_IMAGE_CHECKOUT,
                variables: {
                    checkoutId: id,
                    image: this.screenshot,
                },
            })
            .then(resp => {
                this.data = { ...this.data, ...resp.data.checkoutConfigurationImageUpdate.checkout };
                // send configuration image in metadata, needed for PaymentSuccess page
                const configurationImage = resp.data.checkoutConfigurationImageUpdate.checkout.configurationImage;
                if (configurationImage) {
                    const metadataPayload = [{ key: 'configurationImage', value: configurationImage }];
                    this.updateCheckoutMetadata(metadataPayload);
                }
                this.screenshot = '';
            });
    };

    getCountries = async () => {
        try {
            const response = await apolloClient.query({ query: SHIPPING_ZONES });
            const shippingZones = response.data.shippingZones.edges;
            const countries = shippingZones.map((zone: any) => zone.node.countries).flat();
            const clearList = countries.filter(
                (value: any, index: any, self: any) => index === self.findIndex((t: any) => t.code === value.code),
            );
            this.countries = clearList;
        } catch (error) {
            console.error(error);
        }
    };

    connectUserToCheckout = () => {
        const checkoutId =
            this.data?.id ?? localStorage.getItem(LocalStorageKey.AUTH_SUCCESS_REDIRECT_URL)?.split('/')[2];

        return apolloClient
            .mutate({
                mutation: CUSTOMER_CHECKOUT_ATTACH,
                variables: { id: checkoutId, language: this.locale },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutCustomerAttach.checkout;
                this.data = { ...this.data, ...checkoutData };
            })
            .catch(e => console.error(e));
    };

    disconnectUserFromCheckout = () => {
        const checkoutId =
            this.data?.id ?? localStorage.getItem(LocalStorageKey.AUTH_SUCCESS_REDIRECT_URL)?.split('/')[2];

        return apolloClient
            .mutate({
                mutation: CUSTOMER_CHECKOUT_DETACH,
                variables: { id: checkoutId, language: this.locale },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutCustomerDetach.checkout;
                this.data = { ...this.data, ...checkoutData };
            })
            .catch(e => console.error(e));
    };

    guestShippingAddress = (data: any) => {
        this.addressLoading = true;
        return apolloClient
            .mutate({
                mutation: CHECKOUT_SHIPPING_ADDRESS_UPDATE,
                variables: { id: this.data?.id, shippingAddress: data, language: this.locale },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutShippingAddressUpdate.checkout;
                this.data = { ...this.data, ...checkoutData };
                this.addressModalState = AddressModalState.CLOSED;
                this.addressModalInitialData = undefined;
                this.addressLoading = false;
            })
            .catch(e => {
                console.error(e);
                this.addressLoading = false;
            });
    };

    guestBillingAddress = (data: any) => {
        this.addressLoading = true;

        return apolloClient
            .mutate({
                mutation: CHECKOUT_BILLING_ADDRESS_UPDATE,
                variables: { id: this.data?.id, billingAddress: data, language: this.locale },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutBillingAddressUpdate.checkout;
                this.data = { ...this.data, ...checkoutData };
                this.addressModalState = AddressModalState.CLOSED;
                this.addressModalInitialData = undefined;
                this.addressLoading = false;
            })
            .catch(e => {
                console.error(e);
                this.addressLoading = false;
            });
    };

    setShippingAddress = (data: AddressType) => {
        const addressInput = mapAddressTypeToAddressInputType(data);

        return apolloClient
            .mutate({
                mutation: CHECKOUT_SHIPPING_ADDRESS_UPDATE,
                variables: { id: this.data?.id, shippingAddress: addressInput, language: this.locale },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutShippingAddressUpdate.checkout;
                this.data = { ...this.data, ...checkoutData };
            })
            .catch(e => console.error(e));
    };

    setBillingAddress = (data: AddressType) => {
        const addressInput = mapAddressTypeToAddressInputType(data);

        return apolloClient
            .mutate({
                mutation: CHECKOUT_BILLING_ADDRESS_UPDATE,
                variables: { id: this.data?.id, billingAddress: addressInput, language: this.locale },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutBillingAddressUpdate.checkout;
                this.data = { ...this.data, ...checkoutData };
            })
            .catch(e => console.error(e));
    };

    setDeliveryMethod = (deliveryMethodId: string) => {
        return apolloClient
            .mutate({
                mutation: CHECKOUT_DELIVERY_METHOD_UPDATE,
                variables: { id: this.data?.id, deliveryMethodId, language: this.locale },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutDeliveryMethodUpdate.checkout;
                this.data = { ...this.data, ...checkoutData };
            })
            .catch(e => console.error(e));
    };

    updateMail = (email: string) => {
        const checkoutId =
            this.data?.id ?? localStorage.getItem(LocalStorageKey.AUTH_SUCCESS_REDIRECT_URL)?.split('/')[2];

        return apolloClient
            .mutate({
                mutation: CHECKOUT_UPDATE_MAIL,
                variables: { id: checkoutId, email, language: this.locale },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutEmailUpdate.checkout;
                this.data = { ...this.data, ...checkoutData };
            })
            .catch(e => console.error(e));
    };

    updatePhone = (phone: string) => {
        const checkoutId =
            this.data?.id ?? localStorage.getItem(LocalStorageKey.AUTH_SUCCESS_REDIRECT_URL)?.split('/')[2];
        this.phoneValidationFailed = false;

        return apolloClient
            .mutate({
                mutation: CHECKOUT_UPDATE_PHONE,
                variables: { id: checkoutId, phone, language: this.locale },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutPhoneUpdate.checkout;
                this.data = { ...this.data, ...checkoutData };
            })
            .catch(e => {
                this.phoneValidationFailed = true;
                console.error(e);
            });
    };

    accountAddressCreate = (address: AddressInputType) => {
        return apolloClient.mutate({
            mutation: ACCOUNT_ADDRESS_CREATE,
            variables: { input: { ...address, streetAddress2: address.streetAddress1 } },
        });
    };

    accountAddressUpdate = (id: string, address: AddressInputType) => {
        return apolloClient.mutate({
            mutation: ACCOUNT_ADDRESS_UPDATE,
            variables: { id, input: { ...address, streetAddress2: address.streetAddress1 } },
        });
    };

    addPromoCode = (promoCode: string, isExpert?: boolean) => {
        let expertCheckoutToken;

        if (isExpert) {
            expertCheckoutToken = localStorage.getItem(LocalStorageKey.EXPERT_CHECKOUT_TOKEN);
        }

        return apolloClient
            .mutate({
                mutation: CHECKOUT_ADD_PROMO_CODE,
                variables: { id: this.data?.id, promoCode, language: this.locale, expertToken: expertCheckoutToken },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutAddPromoCode.checkout;
                this.data = { ...this.data, ...checkoutData };
            })
            .catch(e => console.error(e));
    };

    removePromoCode = (promoCode: string) => {
        return apolloClient
            .mutate({
                mutation: CHECKOUT_REMOVE_PROMO_CODE,
                variables: { id: this.data?.id, promoCode, language: this.locale },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutRemovePromoCode.checkout;
                this.data = { ...this.data, ...checkoutData };
            })
            .catch(e => console.error(e));
    };

    createPayment = (paymentID: string, isOffer = false, unpaid = false) => {
        let chargeAmount =
            this.data?.totalPrice.gross?.amount &&
            this.data?.totalPrice.gross?.amount * (this.prepaymentPercentage / 100);

        if (paymentID === 'mirumee.payments.dummy' && !isOffer) {
            chargeAmount = this.data?.totalPrice?.gross?.amount;
        }
        if (unpaid || isOffer) {
            chargeAmount = 0;
        }
        const paymentInput = {
            gateway: paymentID,
            amount: chargeAmount,
            token: this.data?.token,
        };

        // need this mutation for stripe integration, Saleor docs
        // it makes more sense if we wan't to add more payment providers (ex. Paypal...)
        // now it's hardcoded with STRIPE
        return apolloClient
            .mutate({
                mutation: PAYMENT_CREATE,
                variables: { id: this.data?.id, paymentInput, isOffer },
            })
            .catch(e => console.error(e));
    };

    updatePaymentIntent = () => {
        return apolloClient
            .mutate({
                mutation: PAYMENT_INTENT_UPDATE,
                variables: {
                    amount: this.totalPrice && this.totalPrice * (this.prepaymentPercentage / 100),
                    id: this.data?.id,
                },
            })
            .catch(e => console.error(e));
    };

    checkoutComplete = (id?: string, isOffer = false) => {
        // ID is needed for getting order information on PaymentStatus page
        const localId = id || this.data?.id;

        return apolloClient
            .mutate({
                mutation: PAYMENT_COMPLETE,
                variables: { id: localId, isOffer },
            })
            .then(resp => {
                this.orderData = resp.data.checkoutComplete;
                if (resp.data.checkoutComplete.order) {
                    this.sendDataToAnalytics(resp.data.checkoutComplete);
                    localStorage.removeItem(LocalStorageKey.CHECKOUT_ID);
                }
                return resp.data.checkoutComplete;
            })
            .catch(e => console.error(e));
    };

    financeCreate = (id: string, financeInput: any) => {
        return apolloClient
            .mutate({
                mutation: FINANCE_CREATE,
                variables: { id, input: financeInput },
            })
            .then(resp => {
                if (resp.data?.bobFinanceCreate.redirectUrl) {
                    window.location.href = resp.data.bobFinanceCreate.redirectUrl;
                }
            })
            .catch(e => console.error(e));
    };

    sendDataToAnalytics = (data: any) => {
        const eventName = data.order.isOffer ? 'Offer' : 'Purchase ecommerce';
        window.dataLayer.push({
            event: eventName,
            transaction_id: data.order.id,
            value: data.order.total?.gross?.amount,
            currency: data.order.total?.gross?.currency,
        });
        this.sendDataToCustomerIo(eventName, data.order.id);
    };

    fetchProductData = (channel = process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL) => {
        const id = this.data?.metadata.find(d => d.key === 'templateId')?.value;
        if (!id) return;

        return apolloClient
            .query({
                query: FETCH_PRODUCT_DATA,
                variables: { id, channel, language: this.locale },
            })
            .then(resp => {
                this.productData = resp.data.product;
            })
            .catch(e => console.error(e));
    };

    guestAddressModalOnSubmit = async (data?: AddressInputType) => {
        try {
            if (!data) return;
            switch (this.addressModalState) {
                case AddressModalState.NEW_SHIPPING:
                case AddressModalState.EDIT_SHIPPING:
                    this.guestShippingAddress(data);
                    if (this.isBillingAddressSameAsShipping) {
                        this.guestBillingAddress(data);
                    }
                    break;
                case AddressModalState.NEW_BILLING:
                case AddressModalState.EDIT_BILLING:
                    this.guestBillingAddress(data);
                    break;
            }
            // this.addressModalState = AddressModalState.CLOSED;
            // this.addressModalInitialData = undefined;
        } catch (error) {
            console.error(error);
        }
    };

    addressModalOnSubmit = async (data?: AddressInputType) => {
        this.addressLoading = true;
        try {
            if (!data) return;
            switch (this.addressModalState) {
                case AddressModalState.NEW_SHIPPING:
                case AddressModalState.NEW_BILLING:
                    const res = await this.accountAddressCreate(data);
                    const address = res.data.accountAddressCreate.address;
                    if (!address) return;
                    this.data?.user?.addresses.push(address);
                    break;
                case AddressModalState.EDIT_SHIPPING:
                case AddressModalState.EDIT_BILLING:
                    const addressId = this.addressModalInitialData?.id;
                    if (!addressId) throw new Error('No address ID');
                    await this.accountAddressUpdate(addressId, data);
                    // Refresh all user addresses
                    await this.getCheckout(this.data?.id);
                    break;
                default:
                    break;
            }

            this.addressModalState = AddressModalState.CLOSED;
            this.addressModalInitialData = undefined;
        } catch (error) {
            console.error(error);
        } finally {
            this.addressLoading = false;
        }
    };

    uploadCheckoutFile = (base64Image: string, fileName: string) => {
        this.fileUploadingProgress = true;
        return apolloClient
            .mutate({
                mutation: CHECKOUT_UPLOAD_FILE,
                variables: { base64File: base64Image, checkout: this.data?.id, fileName: fileName },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutFileAdd.checkout;
                if (this.data) {
                    this.data.files = [...checkoutData.files];
                }
                this.fileUploadingProgress = false;
            })
            .catch(e => {
                console.error(e);
                this.fileUploadingProgress = false;
            });
    };

    deleteCheckoutFile = (fileId: string) => {
        return apolloClient
            .mutate({
                mutation: CHECKOUT_DELETE_FILE,
                variables: { checkout: this.data?.id, file: fileId },
            })
            .then(resp => {
                const checkoutData = resp.data.checkoutFileDelete.checkout;
                if (this.data) {
                    this.data.files = [...checkoutData.files];
                }
            })
            .catch(e => {
                console.error(e);
            });
    };

    async getExpertCheckoutDropdownFields() {
        const expertCheckoutToken = localStorage.getItem(LocalStorageKey.EXPERT_CHECKOUT_TOKEN);
        const { data } = await apolloClient.query({
            query: CHECKOUT_EXPERT_DROPDOWN_FIELDS,
            variables: { checkoutId: this.data?.id, token: expertCheckoutToken },
        });

        const obj: any = {};
        for (const a of data.checkoutExpertDropdownFields) {
            obj[a.name] = a.values;
        }
        return obj;
    }

    fetchServiceData = () => {
        return apolloClient
            .query({
                query: SERVICE_UPGRADE,
                variables: {
                    channel: process.env.REACT_APP_SALEOR_DEFAULT_CHANNEL,
                    language: this.locale,
                },
            })
            .then(async data => {
                this.serviceUpgradeData = data.data.categories.edges;
                for (const upsellItem of data.data.categories.edges) {
                    // find if default upsell item exists (if price is 0)
                    const defaultItem = upsellItem.node.products?.edges?.find(
                        (item: any) => item.node.defaultVariant.pricing.price.gross.amount === 0,
                    );

                    // check if there is already selected upsell item
                    // add default upsell item to checkout
                    if (
                        defaultItem &&
                        !this.data?.lines?.find(
                            (item: any) => item.variant.product.category?.name === upsellItem.node.name,
                        )?.variant?.name
                    ) {
                        if (!this.data?.id) return;
                        await this.checkoutLinesAddDelete(
                            this.data.id,
                            defaultItem.node.defaultVariant.id,
                            defaultItem.node.name,
                        );
                    }
                }
            })
            .catch(e => console.error(e));
    };

    resetCheckoutStore = () => {
        this.screenshot = '';
        this.data = undefined;
    };
    resetSteps = () => {
        this.checkoutStepProgress = CheckoutStep.CONTACT;
        this.expandedStep = 0;
    };

    clearTokens = () => {
        const access = localStorage.getItem('accessToken');
        const refresh = localStorage.getItem('refreshToken');
        if (access) localStorage.removeItem('accessToken');
        if (refresh) localStorage.removeItem('refreshToken');
    };

    sendDataToCustomerIo = (event: string, actionItem?: string) => {
        const customerEmail = this.data?.email || localStorage.getItem(LocalStorageKey.EMAIL) || 'anon';
        const anonCustomer = customerEmail === 'anon';
        return apolloClient
            .mutate({
                mutation: CUSTOMER_IO,
                variables: {
                    event,
                    customerId: customerEmail,
                    actionItem,
                    anonymous: anonCustomer,
                },
            })
            .then(_ => {
                return;
            })
            .catch(e => console.error(e));
    };

    updateConfiguration = (email?: string, configurationID?: string) => {
        const referralEmail = this.data?.email || email || '';
        const id = configurationID || this.data?.metadata.find(d => d.key === 'configurationID')?.value;

        return apolloClient
            .mutate({
                mutation: CONFIGURATION_UPDATE,
                variables: {
                    id,
                    input: { referralEmail },
                },
            })
            .then(_ => {
                return;
            })
            .catch(e => console.error(e));
    };

    getExpertCheckoutVouchers = async () => {
        const expertCheckoutToken = localStorage.getItem(LocalStorageKey.EXPERT_CHECKOUT_TOKEN);

        return apolloClient
            .query({
                query: EXPERT_VOUCHERS,
                variables: { checkoutId: this.data?.id, token: expertCheckoutToken },
            })
            .then(resp => resp.data)
            .catch(e => console.error(e));
        // return await apolloClient.query({
        //     query: EXPERT_VOUCHERS,
        //     variables: {  },
        // });
    };
}

export default createContext(new CheckoutStore());
