import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../Routes';
import { Button, Loader } from '../../shared/components/ui';
import { RichText } from '../../shared/components/ui/RIchText/RichText';
import { LocalStorageKey } from '../../shared/helpers/localStorage';
import { gtmEvents } from '../../shared/types/gtmEvents';
import CheckoutStore from '../../stores/Checkout.store';
import { CheckoutHero } from '../Checkout/_components/CheckoutHero/CheckoutHero';

import { UpsellItem } from './components/UpsellItem/UpsellItem';
import styles from './ServiceUpgrade.module.scss';

type ServiceUpgradeProps = {};

export const ServiceUpgrade: FC<ServiceUpgradeProps> = observer(() => {
    const checkoutStore = useContext(CheckoutStore);
    const checkoutID = localStorage.getItem(LocalStorageKey.CHECKOUT_ID);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (checkoutID) {
            checkoutStore.getCheckout(checkoutID);
            checkoutStore.fetchServiceData();
        }

        // redirect user to homepage if there is no checkout
        if (!checkoutID) {
            navigate(AppRoutes.home);
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (checkoutStore.serviceUpgradeData?.length === 0) {
            if (checkoutID) {
                navigate(AppRoutes.checkout.replace(':id', checkoutID), { replace: true });
            }
        }
    }, [checkoutStore.serviceUpgradeData]); // eslint-disable-line

    const handleSelect = async (productId: string, productName: string, variantName: string) => {
        // if the same product is clicked multiple times
        const existingProductData = checkoutStore.data?.lines?.find((item: any) => item.variant?.name === productId);

        if (!checkoutID) return;

        setIsLoading(true);

        if (existingProductData) {
            // find if there is default upsell item and select it if user removes deselected upsell item
            const defaultItem = checkoutStore.serviceUpgradeData
                .find((data: any) => data.node.name === productName)
                ?.node.products.edges?.find((item: any) => item.node.defaultVariant.pricing.price.gross?.amount === 0);

            // remove existing upsell item
            await checkoutStore.checkoutLineDelete(existingProductData.variant.name);
            if (defaultItem) {
                // add default upsell item to checkout
                await checkoutStore.checkoutLinesAddDelete(
                    checkoutID,
                    defaultItem?.node?.defaultVariant?.id,
                    defaultItem?.node?.name,
                );
            }

            setIsLoading(false);
            return;
        }

        try {
            await checkoutStore.checkoutLinesAddDelete(checkoutID, productId, productName);
            setIsLoading(false);

            window.dataLayer.push({
                event: gtmEvents.UPSELL_ITEM_SELECT,
                category: productName,
                item: variantName,
            });

            await checkoutStore.sendDataToCustomerIo(gtmEvents.UPSELL_ITEM_SELECT, variantName);
        } catch (e) {
            console.error(e);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    // const handleNone = async (productName: string) => {
    //     if (!checkoutID) return;
    //
    //     const existingProductData = checkoutStore.data?.lines?.find(
    //         (item: any) => item.variant.product.category?.name === productName,
    //     );
    //
    //     if (existingProductData) {
    //         setIsLoading(true);
    //         try {
    //             await checkoutStore.checkoutLineDelete(existingProductData.variant.name);
    //             setIsLoading(false);
    //
    //             window.dataLayer.push({
    //                 event: gtmEvents.UPSELL_ITEM_SELECT,
    //                 category: productName,
    //                 item: 'None',
    //             });
    //         } catch (e) {
    //             console.error(e);
    //             setIsLoading(false);
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     }
    // };

    const onNext = () => {
        if (!checkoutID) return;

        const SID = checkoutStore.data?.metadata.find(d => d.key === 'sid')?.value;
        navigate(`${AppRoutes.configurator}/${SID}`);
        navigate(AppRoutes.checkout.replace(':id', checkoutID));
    };

    if (!checkoutStore.data) {
        return (
            <div className={styles.loader_container}>
                <Loader />
            </div>
        );
    }

    return (
        <>
            {isLoading && (
                <div className={styles.waiting}>
                    <Loader />
                </div>
            )}
            <div className={styles.service_upgrade}>
                {checkoutStore?.data && (
                    <CheckoutHero data={checkoutStore?.data} className={styles.service_upgrade_hero} showPrice />
                )}

                <section className={styles.services}>
                    {checkoutStore.serviceUpgradeData !== 0 &&
                        checkoutStore.serviceUpgradeData?.map((data: any) => {
                            return (
                                <section key={data.node.id} className={styles.service_section}>
                                    <h2 className={styles.title_section}>
                                        {data.node.translation?.name || data.node.name}
                                    </h2>
                                    <RichText
                                        jsonStringData={data.node.translation?.description || data.node.description}
                                        className={styles.service_description}
                                    />
                                    <div className={styles.services_items}>
                                        {data.node.products?.edges?.map((item: any) => {
                                            let defaultItem =
                                                item.node.defaultVariant.pricing.price.gross.amount === 0 &&
                                                !checkoutStore.data?.lines?.find(
                                                    (item: any) =>
                                                        item.variant.product.category?.name === data.node.name,
                                                )?.variant?.name;

                                            return (
                                                <UpsellItem
                                                    key={item.node.id}
                                                    name={item.node.translation?.name || item.node.name}
                                                    id={item.node.defaultVariant.id}
                                                    description={
                                                        item.node.translation?.description || item.node.description
                                                    }
                                                    price={item.node.defaultVariant.pricing.price.gross}
                                                    onSelect={id => handleSelect(id, data.node.name, item.node.name)}
                                                    discount={item.node.defaultVariant.pricing.discount}
                                                    active={
                                                        !!checkoutStore.data?.lines?.find(
                                                            (o: any) => o.variant.name === item.node.defaultVariant.id,
                                                        )?.variant?.name || defaultItem
                                                    }
                                                />
                                            );
                                        })}
                                        {/*<UpsellItem*/}
                                        {/*    onSelect={() => handleNone(data.node.name)}*/}
                                        {/*    isNone*/}
                                        {/*    active={*/}
                                        {/*        !checkoutStore.data?.lines?.find(*/}
                                        {/*            (item: any) =>*/}
                                        {/*                item.variant.product.category?.name === data.node.name,*/}
                                        {/*        )?.variant?.name*/}
                                        {/*    }*/}
                                        {/*/>*/}
                                    </div>
                                </section>
                            );
                        })}
                    <footer className={styles.footer}>
                        <Button type="primary" className={styles.button} onClick={() => onNext()}>
                            {t('next')}
                        </Button>
                    </footer>
                </section>
            </div>
        </>
    );
});
