import clsx from 'clsx';
import React, { FC } from 'react';

import { Icon } from '../../../../shared/components/ui';

import styles from './CarouselArrow.module.scss';

type CarouselArrowProps = {
    onClick: () => void;
    className?: string;
    isDisabled: boolean;
    arrowDirection: 'left' | 'right';
};

export const CarouselArrow: FC<CarouselArrowProps> = ({ onClick, className, isDisabled, arrowDirection }) => {
    return (
        <div className={clsx(styles.carousel_arrow, className, isDisabled && styles.disabled)} onClick={onClick}>
            <Icon name={`arrow_${arrowDirection}`} />
        </div>
    );
};
