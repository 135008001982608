/* eslint-disable react-hooks/exhaustive-deps */
import type { History, Blocker, Transition } from 'history';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { UNSAFE_NavigationContext } from 'react-router-dom';

export function useNavigationChangeInterceptor(when = true): [boolean, () => void, () => void] {
    const navigate = useNavigate();
    const location = useLocation();

    const [navigationChanged, setNavigationChanged] = useState(false);
    const [lastLocation, setLastLocation] = useState<any>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const cancelNavigation = useCallback(() => {
        setNavigationChanged(false);
    }, []);

    // Handle blocking when user click on another route prompt will be shown
    const handleBlockedNavigation = useCallback(
        (nextLocation: any) => {
            // We are checking next location and current location if they are equal or not
            if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
                setNavigationChanged(true);
                setLastLocation(nextLocation);
                return false;
            }
            return true;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [confirmedNavigation, location],
    );

    const confirmNavigation = useCallback(() => {
        setNavigationChanged(false);
        setConfirmedNavigation(true);
    }, []);

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.location.pathname);

            // Clean-up state on confirmed navigation
            setConfirmedNavigation(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmedNavigation, lastLocation]);

    useBlocker(handleBlockedNavigation, when);

    return [navigationChanged, confirmNavigation, cancelNavigation];
}

function useBlocker(blocker: Blocker, when = true): void {
    const navigator = React.useContext(UNSAFE_NavigationContext).navigator as History;

    React.useEffect(() => {
        if (!when) return;

        const unblock = navigator.block((tx: Transition) => {
            const autoUnblockingTx = {
                ...tx,
                retry() {
                    unblock();
                    tx.retry();
                },
            };

            blocker(autoUnblockingTx);
        });

        return unblock;
    }, [navigator, blocker, when]);
}
