import { Matrix4, Object3D } from 'three';

export function getMatrix4(transformMatrix: any) {
    if (typeof transformMatrix === 'string') {
        if (transformMatrix[transformMatrix.length - 1] === ';')
            transformMatrix = transformMatrix.substring(0, transformMatrix.length - 2);
        transformMatrix = transformMatrix.replaceAll(`\\`, '');
        transformMatrix = transformMatrix.replaceAll(`"`, '');
        transformMatrix = transformMatrix.split(';').map((item: any) => +item);
    }
    if (transformMatrix && transformMatrix.length === 16) {
        const matrix = new Matrix4();
        // const positionMatrix = new Matrix4();

        // prettier-ignore
        // Switched the rotation axis Y with Z
        matrix.set(
            transformMatrix[0], -transformMatrix[8], transformMatrix[4], transformMatrix[3],
            -transformMatrix[2], transformMatrix[10], transformMatrix[6], transformMatrix[11],
            transformMatrix[1], transformMatrix[9], transformMatrix[5], -transformMatrix[7],
            transformMatrix[12], transformMatrix[13], transformMatrix[14], transformMatrix[15],
        );

        // positionMatrix.copyPosition(matrix);

        // if (includeRotation) {
        //     return matrix;
        // }
        return matrix;
    }

    return getDefaultMatrix4();
}

export function getDefaultMatrix4(): Matrix4 {
    const matrix = new Matrix4();
    // prettier-ignore
    matrix.set(
        1, 0, 0, 0, 
        0, 1, 0, 0, 
        0, 0, 1, 0, 
        0, 0, 0, 1
        );

    return matrix;
}

export function getAllMeshes(parent: any, visibleOnly: boolean = false): Object3D[] {
    let meshes: any[] = [];

    if (parent.children && parent.children.length > 0) {
        for (let child of parent.children) {
            if (child.isMesh) {
                if (!visibleOnly || (visibleOnly && child.visible)) {
                    meshes.push(child);
                }
            }
            if (child.children && child.children.length > 0) {
                let childMeshes: Array<any> = getAllMeshes(child, visibleOnly);
                meshes.push(...childMeshes);
            }
        }
    }
    return meshes;
}

export function getMatrix4InSlot(slots: any[], id: string, modelId = null): Matrix4 {
    if (!slots) return getDefaultMatrix4();
    const slotList = slots.filter((s: any) => s.productType?.id === id);

    let slot;

    if (slotList.length === 0) return getDefaultMatrix4();

    if (slotList.length > 1) {
        slot = slotList.find(o => o.modelReferences && o.modelReferences.find((mr: any) => mr.id === modelId));

        if (!slot) {
            slot = slotList[0];
        }
    } else {
        slot = slotList[0];
    }

    if (!slot) {
        return getDefaultMatrix4();
    } else {
        let transformMatrix = slot.transformMatrix;
        return getMatrix4(transformMatrix);
    }
}

export function newGetMatrixWorldById(id: string, productPartModels: any[], partModelId = null): Matrix4 {
    const matrixList = [];
    let parent = productPartModels.find(item => item.id === id && !item.isGetMatrix);

    if (parent) {
        parent.isGetMatrix = true;
    }
    let parentId = parent ? parent.parentId : null;
    let productId = parent?.productType?.id;
    while (parent && parent.parentId) {
        let modelId = parent.modelId;
        // eslint-disable-next-line no-loop-func
        parent = productPartModels.find(item => item.id === parentId && item.visible);

        // eslint-disable-next-line no-loop-func
        const parentsArray = productPartModels.filter(item => item.id === parentId && item.visible);
        // If there are more parents find the actual parent by matching the model id with the parent
        // that has that model id in it's modelReferences inside one of it's slots
        if (partModelId && parentsArray.length > 1) {
            for (const item of parentsArray) {
                if (item.slots.length > 0) {
                    for (const slot of item.slots) {
                        if (!slot.modelReferences) continue;
                        const existsInReferences = slot.modelReferences.find((s: any) => s.id === partModelId);
                        if (existsInReferences) parent = item;
                    }
                }
            }
        }
        if (!parent) {
            // eslint-disable-next-line no-loop-func
            parent = productPartModels.find((item: any) => item.id === parentId);
        }
        const matrix = getMatrix4InSlot(parent.slots, productId, modelId);
        matrixList.push(matrix);
        parentId = parent.parentId;
        id = parent.id;
        productId = parent.productType?.id;
    }

    const matrix = getMatrix4InSlot(parent?.slots, productId, parent?.modelId);
    matrixList.push(matrix);

    // let matrixWorld = matrixList[0].clone();
    // for (let i = 1; i < matrixList.length; i++) {
    //     matrixWorld.multiply(matrixList[i].clone());
    // }
    const finalMatrix = new Matrix4();
    const reversed = matrixList.slice().reverse();
    reversed.forEach(mat => {
        finalMatrix.multiply(mat);
    });

    return finalMatrix;
}
