import ControlsImg from 'assets/icons/controls.svg';
import clsx from 'clsx';
import React from 'react';
import { Icon } from 'shared/components/ui';

import { gtmEvents } from '../../../../shared/types/gtmEvents';

import styles from './ConfiguratorControls.module.scss';

interface ConfigurationControlsProps {
    setView: (view: string) => void;
}

export const ConfiguratorControls: React.FC<ConfigurationControlsProps> = ({ setView }) => {
    return (
        <section className={styles.controls}>
            <div
                className={clsx(styles.control, styles.reset)}
                onClick={() => {
                    setView('default');
                    window.dataLayer.push({
                        event: gtmEvents.BIKE_CAMERA_RESET,
                    });
                }}
            >
                <Icon name="reset" />
            </div>
            <div
                className={clsx(styles.control, styles.front)}
                onClick={() => {
                    setView('back');
                    window.dataLayer.push({
                        event: gtmEvents.BIKE_CAMERA_BACK,
                    });
                }}
            >
                <img src={ControlsImg} alt="Control front" />
            </div>
            <div
                className={clsx(styles.control, styles.left)}
                onClick={() => {
                    setView('right');
                    window.dataLayer.push({
                        event: gtmEvents.BIKE_CAMERA_RIGHT,
                    });
                }}
            >
                <img src={ControlsImg} alt="Control right" />
            </div>
            <div
                className={clsx(styles.control, styles.right)}
                onClick={() => {
                    setView('left');
                    window.dataLayer.push({
                        event: gtmEvents.BIKE_CAMERA_LEFT,
                    });
                }}
            >
                <img src={ControlsImg} alt="Control left" />
            </div>
            <div
                className={clsx(styles.control, styles.back)}
                onClick={() => {
                    setView('front');
                    window.dataLayer.push({
                        event: gtmEvents.BIKE_CAMERA_FRONT,
                    });
                }}
            >
                <img src={ControlsImg} alt="Control back" />
            </div>
        </section>
    );
};
