import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '../../../../shared/components/ui';
import { groupSelectedParts, localizePrice } from '../../../../shared/providers/utils';
import ConfiguratorStore from '../../../../stores/Configurator.store';

import styles from './SummaryDrawer.module.scss';

type SummaryDrawerProps = {
    open: boolean;
    onClose: () => void;
};

export const SummaryDrawer: FC<SummaryDrawerProps> = observer(({ open, onClose }) => {
    const configuratorStore = useContext(ConfiguratorStore);
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.summary_drawer, open && styles.opened)}>
            <div className={styles.title_wrapper}>
                <h5>{t('checkoutHeroTitle')}</h5>
                <div className={clsx(styles.hide_summary)} onClick={() => onClose()}>
                    <h6>{t('hideSummary')}</h6>
                    <Icon name="arrow_down" className={styles.icon} />
                </div>
            </div>

            <div className={styles.selected_parts}>
                <div className={styles.part_section}>
                    <h6 className={styles.variant}>{configuratorStore.selectedBike?.name}</h6>
                </div>
                {groupSelectedParts(configuratorStore.selectedParts).map((part: any) => {
                    return (
                        <div key={part.group} className={styles.part_section}>
                            <h6 className={styles.group}>{t(part.group)}</h6>
                            {part.parts?.map((item: any) => {
                                return (
                                    <div key={item.id} className={styles.part}>
                                        <h6 className={styles.variant}>{item.shortName}</h6>
                                        <h6 className={styles.price}>CHF {localizePrice(item.price)}</h6>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
});
