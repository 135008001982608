import { Color, ShaderMaterial, Vector3 } from 'three';

export const IDs = {
    planeId: 'planeId',
    lineId: 'lineId',
    sphereId: 'sphereId',
    dirLight1Id: 'dirLight1Id',
    dirLight2Id: 'dirLight2Id',
    directionalLightId: 'directionalLightId',
    directionalLightLeftId: 'directionalLightLeftId',
    directionalLightRightId: 'directionalLightRightId',
    l1: 'l1',
    l2: 'l2',
    l3: 'l3',
    bikeLight: 'bikeLight',
};

export const LIGHTS = {
    color: 0xffffff,
    intensity: 1,
    distance: 700,
    decay: 2,
};

export const MATERIAL_METALNESS = 0.1;
export const MATERIAL_ROUGHNESS = 1;
export const LIGHT_COLOR = 0xffffff;

export const THREEx = {
    VolumetricSpotLightMaterial: function () {
        var vertexShader = [
            'varying vec3 vNormal;',
            'varying vec3 vWorldPosition;',

            'void main(){',
            '// compute intensity',
            'vNormal		= normalize( normalMatrix * normal );',

            'vec4 worldPosition	= modelMatrix * vec4( position, 1.0 );',
            'vWorldPosition		= worldPosition.xyz;',

            '// set gl_Position',
            'gl_Position	= projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
            '}',
        ].join('\n');
        var fragmentShader = [
            'varying vec3		vNormal;',
            'varying vec3		vWorldPosition;',

            'uniform vec3		lightColor;',

            'uniform vec3		spotPosition;',

            'uniform float		attenuation;',
            'uniform float		anglePower;',

            'void main(){',
            'float intensity;',
            'intensity	= distance(vWorldPosition, spotPosition)/attenuation;',
            'intensity	= 1.0 - clamp(intensity, 0.0, 1.0);',
            'vec3 normal	= vec3(vNormal.x, vNormal.y, abs(vNormal.z));',
            'float angleIntensity	= pow( dot(normal, vec3(0.0, 0.0, 1.0)), anglePower );',
            'intensity	= intensity * angleIntensity;',
            'gl_FragColor	= vec4( lightColor, intensity);',
            '}',
        ].join('\n');

        var material = new ShaderMaterial({
            uniforms: {
                attenuation: {
                    value: 160.0,
                },
                anglePower: {
                    value: 10,
                },
                spotPosition: {
                    value: new Vector3(0, 0, 0),
                },
                lightColor: {
                    value: new Color('cyan'),
                },
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
            transparent: true,
            depthWrite: false,
        });
        return material;
    },
};
