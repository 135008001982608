import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { AppRoutes } from 'Routes';
import { Button, Icon } from 'shared/components/ui';
import { CheckoutType } from 'shared/types/checkout';

import styles from './FinanceFail.module.scss';

type FinanceFailProps = {
    data: CheckoutType | undefined;
    order: any;
};

export const FinanceFail: React.FC<FinanceFailProps> = ({ order }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const goHome = () => {
        navigate(AppRoutes.home, { replace: true });
    };

    return (
        <div className={styles.financeFail}>
            <Icon className={styles.icon} name="error" />
            <h1>{t('somethingWentWrong')}</h1>
            <p>{t('orderStatusIncovenience')}</p>
            <p dangerouslySetInnerHTML={{ __html: t('orderStatusContactUs') }} />
            {order && order.number && (
                <div className={styles.reference}>
                    {t('yourOrderNumber')}
                    <div className={styles.orderNumber}>{order.number}</div>
                </div>
            )}
            <Button className={styles.button} onClick={() => goHome()}>
                {t('orderStatusGoToHomeButton')}
            </Button>
        </div>
    );
};
