import clsx from 'clsx';
import React, { forwardRef } from 'react';

import styles from './Checkbox.module.scss';

interface Props extends React.HTMLAttributes<HTMLInputElement> {
    label?: string;
    checked?: boolean;
    disabled?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
    ({ id, label, checked = false, className, onChange, disabled, ...props }, ref) => {
        return (
            <div className={clsx(styles.checkboxWrapper, className)}>
                <input
                    ref={ref}
                    id={id}
                    checked={checked}
                    className={clsx(checked && styles.checked)}
                    onChange={onChange}
                    {...props}
                    type="checkbox"
                    disabled={disabled}
                />
                {label && <label htmlFor={id}>{label}</label>}
            </div>
        );
    },
);
