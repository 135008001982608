import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import './assets/scss/index.scss';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { apolloClient } from 'shared/lib/apollo';

import App from './App';
import reportWebVitals from './reportWebVitals';

// Initialize sentry if DSN is provided
let SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

if (SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.25,
    });
}

if (process.env.REACT_APP_GOOGLE_GTM) {
    const tagManagerArgs = {
        gtmId: 'GTM-TBB9KLV',
    };

    TagManager.initialize(tagManagerArgs);
}

// react 18 approach of rendering
const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <ApolloProvider client={apolloClient}>
            <App />
        </ApolloProvider>
    </React.StrictMode>,
);

reportWebVitals();
