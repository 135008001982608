import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button, Input, Loader, Modal } from '../../../../shared/components/ui';
import { LocalStorageKey } from '../../../../shared/helpers/localStorage';
import { EMAIL_REGEX } from '../../../../shared/helpers/validators';
import { areArraysDifferent } from '../../../../shared/providers/utils';
import { gtmEvents } from '../../../../shared/types/gtmEvents';
import CheckoutStore from '../../../../stores/Checkout.store';
import ConfiguratorStore from '../../../../stores/Configurator.store';
import { useUpdateCheckoutMetadata } from '../../hooks/useUpdateCheckoutMetadata';

import styles from './ExitIntentModal.module.scss';

enum EXIT_INTENT_STEPS {
    FIRST = 1,
    SECOND = 2,
    THIRD = 3,
}

interface EmailFormType {
    name: string;
    email: string;
    message: string;
}

type ExitIntentModalProps = {
    isOpen: boolean;
    onClose: () => void;
};

export const ExitIntentModal: FC<ExitIntentModalProps> = observer(({ isOpen, onClose }) => {
    const [step, setStep] = useState(EXIT_INTENT_STEPS.FIRST);
    const [isLoading, setIsLoading] = useState(false);
    const [bikeSid, setBikeSid] = useState('');
    const configuratorStore = useContext(ConfiguratorStore);
    const checkoutStore = useContext(CheckoutStore);
    const { t } = useTranslation();

    const [updateCheckoutMetadata] = useUpdateCheckoutMetadata();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmailFormType>();

    const handleSaveConfiguration = async () => {
        setStep(EXIT_INTENT_STEPS.SECOND);

        const isDifferent = areArraysDifferent(configuratorStore.initialParts, configuratorStore.selectedParts);

        // only generate new sid if some parts are changed
        if (!checkoutStore?.data || !isDifferent) {
            setIsLoading(true);

            try {
                await checkoutStore.createCheckout(
                    configuratorStore.selectedParts,
                    configuratorStore.selectedColors,
                    configuratorStore.selectedBikeVariant,
                );

                const id = checkoutStore.data?.id;

                if (!id) {
                    return console.error('No checkout ID.');
                }

                await checkoutStore.saveScreenshot(id);
                await configuratorStore.saveConfiguration(true);
                await updateCheckoutMetadata();

                localStorage.setItem(LocalStorageKey.CHECKOUT_ID, id);

                const SID = checkoutStore.data?.metadata.find(d => d.key === 'sid')?.value;
                if (SID) {
                    setBikeSid(SID);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        } else {
            const SID = checkoutStore.data?.metadata.find(d => d.key === 'sid')?.value;
            if (SID) {
                setBikeSid(SID);
            }
        }

        window.dataLayer.push({
            event: gtmEvents.EXIT_INTENT_SAVE_CONFIG,
        });

        await checkoutStore.sendDataToCustomerIo(gtmEvents.EXIT_INTENT_SAVE_CONFIG);
    };

    const onSubmit = async (data: EmailFormType) => {
        await configuratorStore.sendConfigurationToEmail(configuratorStore.configurationID, [data.email]);
        await checkoutStore.updateMail(data.email);

        setStep(EXIT_INTENT_STEPS.THIRD);

        window.dataLayer.push({
            event: gtmEvents.EXIT_INTENT_SEND_MAIL,
            email: data.email,
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={444}
            className={styles.exit_intent}
            clickOutsideToClose={false}
        >
            {step === EXIT_INTENT_STEPS.FIRST && (
                <div className={styles.first_step}>
                    <h3 className={styles.title}>{t('exitIntent.firstModalTitle')}</h3>
                    <h6 className={styles.subtitle}>{t('exitIntent.firstModalSubtitle')}</h6>
                    <Button type="primary" className={styles.button} onClick={() => handleSaveConfiguration()}>
                        {t('exitIntent.firstModalButton')}
                    </Button>
                </div>
            )}
            {step === EXIT_INTENT_STEPS.SECOND && (
                <div className={styles.second_step}>
                    {isLoading ? (
                        <div className={styles.loader_wrapper}>
                            <Loader />
                        </div>
                    ) : (
                        <>
                            <div className={styles.title_wrapper}>
                                <h3>{t('exitIntent.secondModalTitle')}</h3>
                                <h6>{t('exitIntent.secondModalSubtitle')}</h6>
                            </div>

                            <div className={styles.code_wrapper}>
                                <div className={styles.code_text}>{bikeSid ?? configuratorStore.shareableID}</div>
                                <div className={styles.link_text}>
                                    {t('exitIntent.secondModalCodeText')}: {process.env.REACT_APP_PUBLIC_URL}
                                    /share/<span>{bikeSid ?? configuratorStore.shareableID}</span>
                                </div>
                            </div>
                            <h6>{t('exitIntent.secondModalDescription')}</h6>
                            <form className={styles.email_wrapper} onSubmit={handleSubmit(onSubmit)}>
                                <h5 className={styles.email_label}>{t('exitIntent.secondModalEmailTitle')}</h5>
                                <Input
                                    defaultValue={checkoutStore?.data?.email}
                                    placeholder="alex.wonderlist@example.com"
                                    {...register('email', {
                                        required: t('fieldRequired'),
                                        pattern: {
                                            value: EMAIL_REGEX,
                                            message: t('emailIncorrect'),
                                        },
                                    })}
                                    error={errors.email?.message}
                                />
                                <Button type="primary" onClick={handleSubmit(onSubmit)}>
                                    {t('exitIntent.secondModalButton')}
                                </Button>
                            </form>
                        </>
                    )}
                </div>
            )}
            {step === EXIT_INTENT_STEPS.THIRD && (
                <div className={styles.third_step}>
                    <div className={styles.title_wrapper}>
                        <h3>{t('exitIntent.thirdModalTitle')}</h3>
                        <h6>{t('exitIntent.thirdModalSubtitle')}</h6>
                    </div>

                    <Button
                        type="primary"
                        onClick={() => {
                            onClose();
                            setStep(EXIT_INTENT_STEPS.FIRST);
                        }}
                    >
                        {t('okay')}
                    </Button>
                </div>
            )}
        </Modal>
    );
});
