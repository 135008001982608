import React, { createContext, useEffect, useState } from 'react';

type DeviceDetectorTypes = 'mobile' | 'small-tablet' | 'tablet' | 'desktop';

type DeviceDetectorContextType = {
    device: DeviceDetectorTypes;
};

export const DeviceDetectorContext = createContext<DeviceDetectorContextType>({
    device: 'mobile',
});

type DeviceDetectorProviderProps = {
    device: DeviceDetectorTypes;
    children: React.ReactNode;
};

export const DeviceDetectorProvider: React.FC<DeviceDetectorProviderProps> = ({ device: screen, children }) => {
    const [device, setDevice] = useState(screen);

    const handleResize = () => {
        if (document.body.clientWidth) {
            if (document.body.clientWidth >= 1024) {
                setDevice('desktop');
            }

            if (document.body.clientWidth <= 1024) {
                setDevice('tablet');
            }

            if (document.body.clientWidth <= 768) {
                setDevice('small-tablet');
            }

            if (document.body.clientWidth <= 499) {
                setDevice('mobile');
            }
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <DeviceDetectorContext.Provider value={{ device }}>{children}</DeviceDetectorContext.Provider>;
};
