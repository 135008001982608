import Blocks from 'editorjs-blocks-react-renderer';
import React from 'react';
import { parseEditorJSData } from 'shared/providers/utils';

import styles from './FullDescription.module.scss';

type FullDescriptionProps = {
    jsonStringData: string;
};

export const FullDescription: React.FC<FullDescriptionProps> = ({ jsonStringData }) => {
    const data = parseEditorJSData(jsonStringData);

    if (!data) return null;

    return (
        <div className={styles.fullDescription}>
            <Blocks data={data} />
        </div>
    );
};
